import React, { useRef, useState } from 'react';
import { css, styled } from 'styles';
import { Info } from 'icons';
import { rem } from 'polished';
import { Formik, FormikProps } from 'formik';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import {
  BodyText,
  Error,
  TextInput,
  Tooltip,
} from '@unmind/design-system-components-web';
import PrimaryButton from '../../../Shared/PrimaryButton';
import {
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import { GroupFileField } from '../useSubdomainInfo';
import { medium } from '../../../utils';
import { CustomIdentifier, UserDetails } from '../SignUp';
import { tracking } from '../../../App/Tracking';
import i18n from '../../../i18n/config';
import { SignUpWithSSOButton } from '../SignUpWithSSOButton';
import { StyledForm } from './CommonFormStyledComponents';

export const ResponseMessageTypes = {
  'validate-custom-identifier-not-valid': i18n.t(
    'logged_out:sign_up.forms.employee_id.errors.unrecognised_id',
  ),
  fallback: i18n.t('shared:errors.messages.failed'),
} as const;

const ContinueButton = styled(PrimaryButton)`
  align-self: bottom;
  margin: auto 0 ${rem(16)} 0;
  width: 100%;
  margin-top: ${rem(16)};

  ${medium(css`
    margin-top: ${rem(24)};
  `)}
`;

const TooltipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: ${rem(6)};
`;

export const LabelText = styled(BodyText).attrs(({ theme }) => ({
  color: theme.colors.text.primary,
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize16,
  ],
  forwardedAs: 'label',
}))`
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(Info).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
}))`
  display: flex;
  width: ${rem(18)};
  height: ${rem(18)};
  margin-left: ${rem(2)};
`;

export const TooltipLabel = styled.div`
  max-width: ${rem(270)};
`;

export interface EmployeeIdFormValues {
  [key: string]: string;
}

export interface EmployeeIdFormProps {
  groupFileFields: GroupFileField[];
  subdomain?: string;
  onSubmit(details: UserDetails): void;
  isInitialValid?: boolean;
  initialValues?: EmployeeIdFormValues;
  ssoProviderName?: string | null;
  shouldShowSSOButton?: boolean;
}

export function mapCustomIdentifiers(
  groupFileFields: GroupFileField[],
  values: { [key: string]: string },
): CustomIdentifier[] {
  return groupFileFields.map(fileField => ({
    fieldKey: fileField.fileMappingField || '',
    fieldValue: fileField.fileMappingField
      ? values[fileField.fileMappingField]
      : '',
  }));
}

export const VALIDATE_IDENTIFIERS_QUERY = gql`
  query validateCustomIdentifier($input: ValidateCustomIdentifierInput!) {
    validateCustomIdentifier(input: $input) {
      ... on ValidateCustomIdentifierSuccess {
        isValid
      }
      ... on ValidateCustomIdentifierNotFound {
        isValid
        message
      }
    }
  }
`;

const EmployeeIdForm = ({
  isInitialValid = false,
  initialValues = {},
  ssoProviderName,
  shouldShowSSOButton = false,
  ...props
}: EmployeeIdFormProps) => {
  const { groupFileFields, subdomain, onSubmit } = props;
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');
  const formikRef = useRef<Formik<EmployeeIdFormValues>>(null);
  const [error, setError] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const handleValidateIdentifiersFailure = (
    message: keyof typeof ResponseMessageTypes,
  ) => {
    const errorMessage = ResponseMessageTypes[message]
      ? ResponseMessageTypes[message]
      : translate('sign_up.forms.employee_id.errors.unrecognised_id');

    setError(errorMessage);

    tracking.track('employee-authentication', {
      subdomain,
      verificationType: 'employee-id',
      verificationValid: false,
    });
  };

  const handleValidateIdentifiersSuccess = () => {
    setError('');

    tracking.track('employee-authentication', {
      subdomain,
      verificationType: 'employee-id',
      verificationValid: true,
    });

    const identifiers = formikRef?.current?.state
      .values as EmployeeIdFormValues;

    const mappedIdentifiers = mapCustomIdentifiers(
      groupFileFields,
      identifiers,
    );

    onSubmit({
      customIdentifiers: mappedIdentifiers,
    });
  };
  const [validateIdentifiers, { loading }] = useLazyQuery(
    VALIDATE_IDENTIFIERS_QUERY,
    {
      onCompleted: data => {
        const {
          validateCustomIdentifier: { isValid, message },
        } = data;

        if (isValid) {
          handleValidateIdentifiersSuccess();
        } else {
          handleValidateIdentifiersFailure(message);
        }
      },
      onError: () => {
        setError(ResponseMessageTypes.fallback);
      },
    },
  );

  groupFileFields.forEach(({ fileMappingField }) => {
    if (fileMappingField) {
      initialValues[fileMappingField] = initialValues
        ? initialValues[fileMappingField]
        : '';
    }
  });

  const validateInputs = (values: EmployeeIdFormValues) => {
    const mappedIdentifiers = mapCustomIdentifiers(groupFileFields, values);

    validateIdentifiers({
      variables: {
        input: {
          customIdentifiers: mappedIdentifiers,
          subdomain,
        },
      },
    });
  };

  // There are currently no orgs in prod using more than one groupFileField, so we're safe to use the first one for the title
  const firstCustomIdHeader = groupFileFields[0].fileHeader;

  const shouldUseDefaultFormHeading = () =>
    !firstCustomIdHeader || firstCustomIdHeader === 'Employee ID';

  const getFormHeading = () => {
    if (shouldUseDefaultFormHeading()) {
      return translate('sign_up.forms.employee_id.heading');
    } else {
      return `${translate(
        'sign_up.forms.employee_id.custom_heading',
      )} ${firstCustomIdHeader}`;
    }
  };

  return (
    <LoggedOutScreenWrapper dataTestId="signup-id-form">
      <LoggedOutScreenTitle data-testid="form-title">
        {getFormHeading()}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="form-subtitle">
        {translate('sign_up.forms.employee_id.subtitle')}
      </LoggedOutScreenSubtitle>
      <Formik
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        onSubmit={validateInputs}
        ref={formikRef}
        validate={values => {
          const errors: { [key: string]: string } = {};

          groupFileFields.forEach(({ fileMappingField }) => {
            if (fileMappingField && !values[fileMappingField]) {
              errors[fileMappingField] = '';
            }
          });

          return errors;
        }}
      >
        {({
          isValid,
          errors,
          setFieldValue,
          values,
        }: FormikProps<EmployeeIdFormValues>) => (
          <StyledForm>
            {groupFileFields.map(field =>
              field.fileMappingField && field.fileHeader ? (
                <>
                  {shouldUseDefaultFormHeading() && (
                    <TooltipContainer>
                      <Tooltip
                        tooltipContents={translate(
                          'sign_up.forms.employee_id.tool_tip_text',
                        )}
                        placement="top"
                        overflowBoundaryRef={containerRef.current || undefined}
                        accessibilityId="disabled-employee-id-tooltip"
                        data-testid="custom-id-label-tooltip"
                        triggerElement={
                          <LabelText>
                            {firstCustomIdHeader}
                            <InfoIcon />
                          </LabelText>
                        }
                      >
                        <InfoIcon />
                      </Tooltip>
                    </TooltipContainer>
                  )}
                  <TextInput
                    id={field.fileHeader}
                    additionalText={{
                      label:
                        !shouldUseDefaultFormHeading() && field.fileHeader
                          ? field.fileHeader
                          : '',
                    }}
                    onChange={e => {
                      setFieldValue(field.fileMappingField, e.target.value);
                    }}
                    key={field.fileHeader}
                    data-testid="custom-id-input"
                    name={field.fileMappingField}
                    placeholder={translate(
                      'sign_up.forms.employee_id.custom_id_field.placeholder',
                    )}
                    value={values[field.fileMappingField]}
                    aria-label={field.fileHeader}
                    errorText={translate(
                      'sign_up.forms.employee_id.errors.field_empty',
                      {
                        file_header: field.fileHeader,
                      },
                    )}
                    hasError={errors[field.fileMappingField] === ''}
                  />
                </>
              ) : null,
            )}
            {error && <Error errorText={error} showIcon />}
            <ContinueButton
              data-testid="continue-button"
              type="submit"
              label={translate('sign_up.forms.employee_id.submit_button.label')}
              disabled={!isValid}
              loading={loading}
            />
          </StyledForm>
        )}
      </Formik>
      {shouldShowSSOButton && (
        <SignUpWithSSOButton ssoProviderName={ssoProviderName} />
      )}
    </LoggedOutScreenWrapper>
  );
};

export default EmployeeIdForm;
