import { gql, useQuery } from '@apollo/client';
import { getOrganisationRegistrationMethod } from './__generated__/getOrganisationRegistrationMethod';

export const ORGANISATION_REGISTRATION_METHOD = gql`
  query getOrganisationRegistrationMethod {
    organisation {
      registrationMethod
    }
  }
`;

export const useOrganisationRegistrationMethod = () =>
  useQuery<getOrganisationRegistrationMethod>(
    ORGANISATION_REGISTRATION_METHOD,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );
