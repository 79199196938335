import React, { ReactNode } from 'react';
import { isMSTeams } from 'utils/MSTeams';
import RoutePath from 'App/RoutePath';
import BackButton from 'Shared/Button/BackButton';
import { useSubdomainInfo } from 'LoggedOut/SignUp/useSubdomainInfo';
import getSubdomainFromUrl, {
  isClientSubdomain,
} from 'utils/getSubdomainFromUrl';
import {
  LoggedOutScreenContent,
  LoggedOutScreenContainer,
  BackButtonContainer,
  LoggedOutScreenWrapperBlock,
  GroupNameHeader,
} from './LoggedOutScreenWrapper.styled';
import { LoggedOutScreenFooter } from './LoggedOutScreenFooter';

interface LoggedOutScreenWrapperProps {
  children: ReactNode;
  customFooter?: ReactNode;
  showBackButton?: boolean;
  backButtonOnClick?(): void;
  loading?: boolean;
  dataTestId?: string;
}

export const LoggedOutScreenWrapper = ({
  children,
  showBackButton = true,
  backButtonOnClick,
  dataTestId,
  customFooter,
}: LoggedOutScreenWrapperProps) => {
  const subdomain = getSubdomainFromUrl();
  const clientSubdomain = isClientSubdomain(subdomain);
  const { groupName, group } = useSubdomainInfo({
    subdomain,
    skip: !clientSubdomain,
  }) || {
    group: null,
  };

  const backButton = (
    <BackButton
      linkToDefault={RoutePath.StartSplitScreen}
      additionalOnClick={backButtonOnClick}
    />
  );

  return (
    <LoggedOutScreenContainer data-testid={dataTestId}>
      {
        <LoggedOutScreenContent>
          <GroupNameHeader>
            {clientSubdomain && groupName}
            {showBackButton && isMSTeams() && (
              <BackButtonContainer>{backButton}</BackButtonContainer>
            )}
          </GroupNameHeader>
          <LoggedOutScreenWrapperBlock>{children}</LoggedOutScreenWrapperBlock>
          <LoggedOutScreenFooter
            authType={group?.authType}
            customFooter={customFooter}
          />
        </LoggedOutScreenContent>
      }
    </LoggedOutScreenContainer>
  );
};
