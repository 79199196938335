import { BodyText } from '@unmind/design-system-components-web';
import { tracking } from 'App/Tracking';
import { Phone } from 'icons';
import { rem } from 'polished';
import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getEapServices_helpEAPServices as EapService } from 'Help/hooks/__generated__/getEapServices';

const Button = styled.a`
  display: flex;
  background: ${({ theme }) => theme.button.primary.default.backgroundColor};
  gap: ${rem(8)};
  padding: ${rem(12)} ${rem(20)};
  border-radius: ${rem(100)};
  border: none;
  width: fit-content;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) =>
      theme.button.primary.default.hover.backgroundColor};
  }
`;

const PhoneIcon = styled(Phone).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.inverse,
  height: 16,
  width: 16,
  ['aria-hidden']: true,
}))`
  align-self: center;
`;

const ButtonText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize14,
    theme.typography.fontSizes.fontSize16,
  ],
  weight: theme.typography.fontWeights.medium,
  color: theme.colors.text.inverse,
}))``;

interface ContactButtonProps {
  activeService: EapService;
  contactNumber?: string | false;
  countryCode: string;
  onClick?(e: React.MouseEvent): void;
}

type ButtonProps = {
  as?: React.ElementType;
} & ComponentPropsWithoutRef<'button'> &
  ComponentPropsWithoutRef<'a'>;

const ContactButton = ({
  activeService,
  contactNumber = false,
  countryCode,
  onClick,
}: ContactButtonProps) => {
  const { t: translate } = useTranslation('help', {
    keyPrefix: 'eap_contact_number',
  });

  // Set up different props depending on whether a contact number is available
  let buttonProps: ButtonProps = { as: 'button' };
  let trackingProps: { contactNumber?: string } = {};

  if (contactNumber) {
    buttonProps = {
      href: `tel:${contactNumber}`,
      target: '_blank',
      'aria-label': translate('a11y_label', {
        contactNumber,
      }),
    };
    trackingProps = {
      contactNumber,
    };
  }

  return (
    <Button
      onClick={(e: React.MouseEvent) => {
        tracking.track(
          `help_service_contact_${
            contactNumber ? 'number' : 'service'
          }_clicked`,
          {
            serviceId: activeService.id,
            serviceName: activeService.serviceName,
            countryCode,
            ...trackingProps,
          },
        );
        if (!contactNumber && onClick) onClick(e);
      }}
      {...buttonProps}
    >
      <PhoneIcon />
      <ButtonText>
        {contactNumber ? contactNumber : translate('cta')}
      </ButtonText>
    </Button>
  );
};

export default ContactButton;
