import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { useSubdomainInfo } from '../../LoggedOut/SignUp/useSubdomainInfo';
import { LocalGeneralError } from '../../Shared/AlertView';
import { LoggedOutScreenWrapper } from '../LoggedOutScreenWrapper';
import useUniversalLogin from '../UniversalLogin/useUniversalLogin';
import LoadingIndicator from '../../Shared/LoadingIndicator';

export type LoginViaIDPInitiatedSSOProps = RouteComponentProps;

function LoginViaIDPInitiatedSSO({}: LoginViaIDPInitiatedSSOProps) {
  const subdomain = getSubdomainFromUrl();

  const {
    authProviderOrganisationId,
    loading: subdomainInfoLoading,
    error: subdomainInfoError,
  } = useSubdomainInfo({
    subdomain,
  });

  const { loginWithUniversalLogin } = useUniversalLogin();

  useEffect(() => {
    if (authProviderOrganisationId) {
      void loginWithUniversalLogin({
        authProviderOrganisationId,
      });
    }
  }, [authProviderOrganisationId, loginWithUniversalLogin]);

  if (subdomainInfoLoading) return <LoadingIndicator />;

  return (
    <>
      <LoggedOutScreenWrapper>
        {subdomainInfoError ? <LocalGeneralError /> : <LoadingIndicator />}
      </LoggedOutScreenWrapper>
    </>
  );
}

export default LoginViaIDPInitiatedSSO;
