import React, { useState, useCallback } from 'react';
import { Alert } from '@unmind/design-system-components-web';
import { useTranslation } from 'react-i18next';
import Modal from '../Shared/Modal';
import LoadingIndicator from '../Shared/LoadingIndicator';
import { useGetCountryContact } from './hooks/useGetCountryContact';
import { useUserCurrentLocation } from './hooks/useGetUserCurrentLocation';
import EapPrivacyPolicy from './EapPrivacyPolicy';
import HelpLocationSelect from './HelpLocationSelect';
import HelpHeader from './HelpHeader';
import HelpResources from './HelpResources';
import {
  AlertContainer,
  HelpContainer,
  HelpLocationSelectContainer,
  PageContainer,
  RetryOnErrorButton,
} from './styles';
import HelpServicesTabs from './HelpServicesTabs';
import { useGetEapServices } from './hooks/useGetEapServices';

const ServiceAlert = ({
  hasError,
  onRetry,
}: {
  onRetry(): void;
  hasError: boolean;
}) => {
  const { t: translate } = useTranslation('help');

  if (hasError) {
    return (
      <AlertContainer>
        <Alert
          variant="error"
          title={translate('help_service_tabs.error_state.title')}
          body={translate('help_service_tabs.error_state.body')}
          size="medium"
          orientation="vertical"
        >
          <RetryOnErrorButton
            label={translate(
              'help_service_tabs.error_state.refetch_button_label',
            )}
            onClick={onRetry}
          />
        </Alert>
      </AlertContainer>
    );
  }

  return (
    <AlertContainer>
      <Alert
        title={translate('help_service_tabs.no_services.title')}
        body={translate('help_service_tabs.no_services.body')}
        size="medium"
        variant="warning"
        orientation="vertical"
      />
    </AlertContainer>
  );
};

export const HelpLanding = () => {
  const [showModal, setShowModal] = useState(false);
  const {
    data,
    loading: isLoadingUserLocation,
    refetch: refetchUserLocation,
    error: userLocationError,
  } = useUserCurrentLocation();

  const {
    data: countryContactData,
    loading: isLoadingCountryContact,
    refetch: refetchCountryContact,
    error: countryContactError,
  } = useGetCountryContact();

  const {
    data: eapServicesData,
    loading: isLoadingEapServices,
    error: eapServicesError,
    refetch: refetchEapServices,
  } = useGetEapServices();

  const LocationModalContent = useCallback(
    () => (
      <HelpLocationSelect
        refetchUserLocation={refetchUserLocation}
        refetchCountryContact={refetchCountryContact}
        refetchEapServices={refetchEapServices}
        inModal={true}
        onClose={() => setShowModal(false)}
      />
    ),
    [refetchCountryContact, refetchUserLocation, refetchEapServices],
  );

  if (
    isLoadingUserLocation ||
    isLoadingCountryContact ||
    isLoadingEapServices
  ) {
    return <LoadingIndicator />;
  }

  const eapServices = eapServicesData?.helpEAPServices || [];

  const hasApiErrors = Boolean(
    eapServicesError || userLocationError || countryContactError,
  );
  const showServiceAlert = hasApiErrors || !eapServices.length;

  const userCurrentLocation = data?.user?.selectedCurrentLocation?.value;
  const displayName =
    countryContactData?.helpCountryContact?.eapProvider?.displayName;
  const privacyLink =
    countryContactData?.helpCountryContact?.eapProvider?.privacyPolicyUrl;
  const contactDetails = countryContactData?.helpCountryContact?.contactDetails;

  const displayPrivacyPolicy = displayName && privacyLink;

  const handleCloseModal = () => setShowModal(false);

  return (
    <PageContainer>
      {!userCurrentLocation ? (
        <HelpLocationSelectContainer>
          <HelpLocationSelect
            refetchUserLocation={refetchUserLocation}
            refetchCountryContact={refetchCountryContact}
            refetchEapServices={refetchEapServices}
          />
        </HelpLocationSelectContainer>
      ) : (
        <>
          <HelpContainer>
            <HelpHeader
              countryCode={userCurrentLocation}
              eapDisplayName={displayName}
              onClickCountryPicker={() => setShowModal(true)}
            ></HelpHeader>
            {showServiceAlert ? (
              <ServiceAlert
                hasError={hasApiErrors}
                onRetry={refetchEapServices}
              />
            ) : (
              <HelpServicesTabs
                contactDetails={contactDetails}
                eapServices={eapServices}
                countryCode={userCurrentLocation}
              />
            )}

            <HelpResources countryCode={userCurrentLocation} />
          </HelpContainer>

          {!showServiceAlert && displayPrivacyPolicy && (
            <EapPrivacyPolicy
              displayName={displayName}
              privacyLink={privacyLink}
              countryCode={userCurrentLocation}
            />
          )}

          {showModal && (
            <Modal
              open={showModal}
              onCloseCallback={handleCloseModal}
              closeHeader={true}
              modalContent={LocationModalContent}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default HelpLanding;
