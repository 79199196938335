import { gql, useQuery } from '@apollo/client';
import { userFeedbackQuery } from './__generated__/userFeedbackQuery';

export const USER_FEEDBACK_QUERY = gql`
  query userFeedbackQuery {
    user {
      id
      locationData {
        name
      }
      organisation {
        subDomain
      }
      userType {
        name
      }
    }
  }
`;

export const useUserFeedbackQuery = () => {
  const { data, loading, error } = useQuery<userFeedbackQuery>(
    USER_FEEDBACK_QUERY,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );

  return { data, loading, error };
};
