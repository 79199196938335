export const possibleTypes = {
  SeriesSegment: ['SeriesSegmentText', 'SeriesSegmentLegacy'],
  ContentNode: ['ContentFolder', 'ContentItem'],
  ValidateCustomIdentifierOutput: [
    'ValidateCustomIdentifierSuccess',
    'ValidateCustomIdentifierNotFound',
  ],
  ValidateWorkplaceEmailOutput: [
    'ValidateWorkplaceEmailSuccess',
    'ValidateWorkplaceEmailNotFound',
  ],
  MutationPayload: ['LogEventPayload', 'SaveMHFTResponsePayload'],
};
