import { Formik } from 'formik';
import React from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { LockShield } from 'icons';
import { MultipleCheckboxes } from 'LoggedOut/Assets/Checkbox/MultipleCheckboxes';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { small } from 'utils';
import {
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import { UserDetails } from '../SignUp';
import { TsAndCsStatement } from '../TsAndCsStatement';
import { useEEAConsentCheckboxOnSignUp } from '../hooks/useEEAConsentCheckboxOnSignUp';
import { StyledForm, StyledSubmitButton } from './CommonFormStyledComponents';

const PrivacyPointList = styled.div`
  margin-top: ${rem(24)};
  margin-bottom: ${rem(12)};
`;

const PrivacyInformationHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: ${rem(20)};
  font-weight: 600;
  margin-top: ${rem(16)};
  margin-bottom: ${rem(12)};
`;

const PrivacyInformationContainer = styled.div`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
`;

const PrivacyInformationText = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  text-size: ${rem(16)};
  margin-bottom: ${rem(24)};
`;

const LockShieldIcon = styled(LockShield).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  secondaryColor: theme.colors.text.primary,
  height: '20px',
  width: '20px',
}))`
  flex-shrink: 0;
  margin-right: ${rem(16)};
`;

const StickyFooter = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.card};
  border-top-left-radius: ${rem(12)};
  border-top-right-radius: ${rem(12)};
  padding: ${rem(12)};

  ${small(css`
    background-color: ${({ theme }) => theme.colors.background.primary};
  `)};
`;

export interface PrivacyConsentFormProps {
  onSubmit(details: UserDetails): void;
  error?: string;
  initialValues?: UserDetails;
  loading?: boolean;
  isInvitedUser?: boolean;
}

const PrivacyConsentForm = ({
  onSubmit,
  loading,
  initialValues = {
    explicitPrivacyConsentGiven: false,
    marketingOptIn: false,
    explicitEEADataStorageConsentGiven: false,
  },
  isInvitedUser,
}: PrivacyConsentFormProps) => {
  const subdomain = getSubdomainFromUrl();
  const {
    loading: loadingPrivacyConsentStep,
    showEEAExplicitDataStorageConsent,
  } = useEEAConsentCheckboxOnSignUp(subdomain);

  const { t: translate } = useTranslation([
    'logged_out',
    'shared',
    'password_validation',
  ]);

  const eeaCheckbox = [
    {
      name: 'explicitEEADataStorageConsentGiven',
      label: translate('logged_out:sign_up.storing_data_eea_checkbox.label'),
    },
  ];

  const consentCheckbox = [
    {
      name: 'explicitPrivacyConsentGiven',
      label: translate('logged_out:sign_up.health_data_consent_checkbox.label'),
    },
  ];

  const marketingConsentCheckbox = [
    {
      name: 'marketingOptIn',
      label: translate('logged_out:sign_up.marketing_checkbox.label'),
    },
  ];

  const checkboxes = [
    ...consentCheckbox,
    ...(showEEAExplicitDataStorageConsent ? eeaCheckbox : []),
    ...marketingConsentCheckbox,
  ];

  const legendId = 'privacy-consent-legend';

  return (
    <LoggedOutScreenWrapper
      customFooter={
        <StickyFooter>
          <TsAndCsStatement />
        </StickyFooter>
      }
      dataTestId="privacy-consent-page"
      loading={loadingPrivacyConsentStep}
    >
      <LoggedOutScreenTitle data-testid="form-title" id={legendId}>
        {translate('logged_out:sign_up.forms.privacy_consent.heading')}
      </LoggedOutScreenTitle>
      <PrivacyInformationContainer>
        {!isInvitedUser && (
          <PrivacyInformationHeader>
            <LockShieldIcon />
            {translate(
              'logged_out:explicit_privacy_consent_needed.declaration',
            )}
          </PrivacyInformationHeader>
        )}
        <PrivacyPointList>
          <PrivacyInformationText>
            {translate('logged_out:sign_up.forms.privacy_consent.point_1')}
          </PrivacyInformationText>
          {!isInvitedUser && (
            <PrivacyInformationText>
              {translate('logged_out:sign_up.forms.privacy_consent.point_2')}
            </PrivacyInformationText>
          )}
        </PrivacyPointList>
      </PrivacyInformationContainer>
      <Formik
        validate={values => {
          if (
            !values.explicitPrivacyConsentGiven ||
            (showEEAExplicitDataStorageConsent &&
              !values.explicitEEADataStorageConsentGiven)
          ) {
            return {
              explicitPrivacyConsentGiven: false,
              explicitEEADataStorageConsentGiven: false,
            };
          }

          return {};
        }}
        initialValues={initialValues}
        isInitialValid={initialValues.explicitPrivacyConsentGiven}
        onSubmit={userDetails => {
          onSubmit(userDetails);
        }}
      >
        {({ isValid }) => (
          <StyledForm>
            <MultipleCheckboxes
              ariaLabelledby={legendId}
              checkboxes={checkboxes}
            />
            <StyledSubmitButton
              data-testid="submit-button"
              type="submit"
              loading={loading}
              label={translate(
                'logged_out:sign_up.forms.privacy_consent.submit_button.label',
              )}
              disabled={!isValid}
            />
          </StyledForm>
        )}
      </Formik>
    </LoggedOutScreenWrapper>
  );
};

export default PrivacyConsentForm;
