import { rem } from 'polished';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { large } from 'utils';
import logo from '../Shared/Assets/unmind_logo_with_name.svg';

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.img`
  position: relative;
  top: ${rem(2)};
  width: ${rem(88)};
  height: ${rem(20)};
  margin-right: ${rem(32)};

  ${large(css`
    margin-right: ${rem(76)};
  `)}
`;

export const UnmindLogo = ({
  headerLogoRef,
}: {
  headerLogoRef?: RefObject<HTMLAnchorElement>;
}) => {
  const { t: translate } = useTranslation('shared');

  return (
    <StyledLink to={'/home'} ref={headerLogoRef}>
      <Logo
        src={logo}
        alt={translate('navigation.unauthenticated_header_logo.alt_text')}
      />
    </StyledLink>
  );
};
