import { gql, useMutation } from '@apollo/client';
import { ResetPassword } from './__generated__/ResetPassword';

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $passwordToken: String!
    $password: String!
    $groupId: String!
  ) {
    resetPassword(
      passwordToken: $passwordToken
      password: $password
      groupId: $groupId
    )
  }
`;

export const useResetPassword = () => {
  const [resetPasswordMutation, { error, data }] = useMutation<ResetPassword>(
    RESET_PASSWORD_MUTATION,
  );

  return { resetPassword: resetPasswordMutation, error, data };
};
