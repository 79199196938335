import { Form as FormikForm } from 'formik';
import { rem } from 'polished';
import { css, styled } from 'styles';
import { createFontStyle } from '../../../Shared/Typography/Base';
import Label from '../../../Shared/Form/Label';
import PrimaryButton from '../../../Shared/PrimaryButton';
import { medium, small } from '../../../utils';

export const StyledForm = styled(FormikForm)`
  // Target all browsers except ie
  @supports not (-ms-high-contrast: none) {
    display: flex;
    flex-direction: column;
    flex: 1;

    ${medium(css`
      display: block;
    `)}
  }
`;

export const StyledFormFieldLabel = styled(Label)`
  display: inline-flex;
  margin-bottom: ${rem(8)};
  ${({ theme }) =>
    createFontStyle({
      size: theme.typography.fontSizes.fontSize18,
      weight: theme.typography.fontWeights.regular,
    })};
`;

export const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${rem(16)};
  align-self: bottom;
  width: 100%;

  ${small(css`
    margin-top: ${rem('24px')};
  `)}
`;
