import React from 'react';
import { IconProps } from '../IconProps';
const SvgNavHomeActive = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.517.902a.714.714 0 01.965 0l8.108 7.433c.443.405.695.978.695 1.58v7.228c0 1.183-.96 2.143-2.143 2.143h-5.714v-5a1.429 1.429 0 00-2.857 0v5H2.857a2.143 2.143 0 01-2.143-2.143V9.914c0-.6.252-1.174.695-1.58L9.517.903z"
      fill={primaryColor}
    />
  </svg>
);
export default SvgNavHomeActive;
