import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { GetLineManagerEntitlements } from './__generated__/GetLineManagerEntitlements';

export const LINE_MANAGER_ENTITLEMENTS_QUERY = gql`
  query GetLineManagerEntitlements {
    organisationEntitlements {
      lineManagerFeatures
    }
  }
`;

export const useLineManagerEntitlements = () =>
  useQuery<GetLineManagerEntitlements>(LINE_MANAGER_ENTITLEMENTS_QUERY, {
    fetchPolicy: 'network-only',
  });
