import { rem } from 'polished';
import { css, styled } from 'styles';
import { Lock, Help } from 'icons';
import { Link } from 'react-router-dom';
import { small, medium, large } from '../../utils';
import { BodyText } from '../../Shared/Typography';

export const createTextContentWidth = () => css`
  ${small(css`
    max-width: ${({ theme }) => (7 / theme.grid.columns.tablet) * 100}%;
  `)}

  ${medium(css`
    max-width: 100%;
  `)}

  ${large(css`
    max-width: ${({ theme }) => (10 / theme.grid.columns.desktop) * 100}%;
  `)}
`;

export const GroupNameHeader = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize26,
  ],
}))`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${rem(12)};
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  margin-top: ${rem(16)};
  justify-content: center;

  & > *:not(:first-child) {
    margin-left: ${rem(8)};
  }
`;

export const HelpLinks = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  margin-left: ${rem(8)};
  display: flex;
  align-items: center;
  padding: ${rem(8)};

  & > svg {
    margin-right: ${rem(6)};
  }
`;

export const LockIcon = styled(Lock).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  width: 16,
  height: 16,
}))``;

export const HelpIcon = styled(Help).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.primary,
  width: 16,
  height: 16,
}))``;

export const LinkText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const LoggedOutScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoggedOutScreenContent = styled.div`
  width: 100%;
  margin-top: ${rem(48)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(24)};
  padding-bottom: 0;
  height: 90vh;
  justify-content: space-between;

  ${small(css`
    margin-top: ${rem(64)};
  `)}

  ${medium(css`
    width: ${rem(600)};
  `)};
`;

export const LoggedOutScreenWrapperBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${rem(24)};
  width: 100%;
  margin-top: ${rem(12)};
  margin-bottom: ${rem(24)};
  padding: ${rem(24)};
  background-color: ${({ theme }) => theme.colors.background.input};

  ${medium(css`
    padding: ${rem(32)};
  `)}
`;

export const LoggedOutScreenTitle = styled(BodyText).attrs(({ theme }) => ({
  level: 1,
  sizes: [
    theme.typography.fontSizes.fontSize24,
    theme.typography.fontSizes.fontSize28,
  ],
  color: theme.colors.text.primary,
  weight: theme.typography.fontWeights.semiBold,
  accessibilityAutoFocus: true,
}))<{ centred?: boolean }>`
  flex-shrink: 0;
  width: fit-content;
  max-width: 100%;
  align-self: flex-start;
  padding-bottom: ${rem(12)};
`;

export const LoggedOutScreenSubtitle = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize16,
    theme.typography.fontSizes.fontSize20,
  ],
  weight: theme.typography.fontWeights.regular,
}))`
  flex-shrink: 0;
  width: 100%;
  align-self: flex-start;
  padding-bottom: ${rem(24)};
`;

export const LoggedOutScreenWrapperFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  width: ${rem(300)};
  flex-direction: column;
  align-items: center;

  & > * {
    width: 100%;
  }
`;
