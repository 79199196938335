import React from 'react';
import { IconProps } from '../IconProps';
const SvgNavTrackActive = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M0 12.36c0-.691.56-1.25 1.25-1.25h2.763c.69 0 1.25.559 1.25 1.25v6.388c0 .69-.56 1.25-1.25 1.25H1.25c-.69 0-1.25-.56-1.25-1.25V12.36zM7.367 1.25c0-.69.56-1.25 1.25-1.25h2.763c.69 0 1.25.56 1.25 1.25v17.5c0 .69-.56 1.25-1.25 1.25H8.617c-.69 0-1.25-.56-1.25-1.25V1.25zM14.738 7.914c0-.69.56-1.25 1.25-1.25h2.763c.69 0 1.25.56 1.25 1.25v10.833c0 .69-.56 1.25-1.25 1.25h-2.763c-.69 0-1.25-.56-1.25-1.25V7.914z"
      fill={primaryColor}
    />
  </svg>
);
export default SvgNavTrackActive;
