import { Button } from '@unmind/design-system-components-web';
import { Clear } from 'icons';
import { rem } from 'polished';
import { styled } from 'styles';

export const Form = styled.form<{ isChecked: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${rem(16)};

  // Checkbox styles
  label:hover span {
    background-color: ${({ theme, isChecked }) =>
      isChecked
        ? theme.colors.brand.primary
        : theme.colors.background.primary} !important;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${rem(8)};
`;

export const CloseIcon = styled(Clear).attrs(({ theme }) => ({
  primaryColor: theme.colors.background.inverse,
}))`
  width: ${rem(32)};
  height: ${rem(32)};
  cursor: pointer;
`;

export const TextArea = styled.textarea`
  width: 100%;
  outline: 0;
  background-color: ${({ theme }) => theme.colors.background.input};
  border: solid 1px ${({ theme }) => theme.colors.background.skeleton};
  border-radius: ${rem(8)};
  color: ${({ theme }) => theme.colors.text.secondary};
  resize: none;
  padding: ${rem(16)};
  min-height: ${rem(86)};
  height: ${rem(86)};
  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

export const SubmitButton = styled(Button)`
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.button.primary.disabled.backgroundColor
      : theme.button.primary.default.backgroundColor};

  > span {
    font-size: ${({ theme }) =>
      rem(theme.typography.fontSizes.fontSize14)} !important;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;
