import { Namespace } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { shortsContainerQuery } from 'Services/Shorts/__generated__/shortsContainerQuery';

import { useQuery } from '@apollo/client';
import { ContentTile, Loader } from '@unmind/design-system-components-web';
import { SHORTS_CONTAINER_QUERY } from 'Services/Shorts/shorts.services';
import RoutePath from '../../../App/RoutePath';
import SectionError from '../../../Shared/SectionError/SectionError';
import * as S from './styles';

export const OnboardingContent = () => {
  const { t: translate } = useTranslation<Namespace<'talk' | 'shorts'>>([
    'talk',
    'shorts',
  ]);

  const {
    data: shorts,
    loading,
    error,
    refetch,
  } = useQuery<shortsContainerQuery>(SHORTS_CONTAINER_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    variables: {
      slug: 'talk-home',
    },
  });

  if (error)
    return (
      <div>
        <S.Title>{translate('talk:what_to_expect.title')}</S.Title>
        <SectionError onRetry={async () => refetch({ slug: 'talk-home' })} />
      </div>
    );

  /**
   * Return
   */
  return (
    <S.Container>
      {loading ? (
        <Loader />
      ) : (
        <S.Inner>
          <S.Title>{translate('talk:what_to_expect.title')}</S.Title>
          {shorts?.getShortsByContainerSlug?.map(short => {
            const contentItem = {
              itemType: 'video' as const,
              title: short.title ?? '',
              thumbnailUrl: `${short.cloudinaryThumbnail?.path}?${shorts?.getAssetToken?.assetToken}`,
              tags: [
                translate('shorts:short_card.content_type.video'),
                translate('shorts:short_card.duration_text', {
                  duration: short.estimatedMins?.toString(),
                }),
              ],
            };

            return (
              <ContentTile
                item={contentItem}
                to={`${RoutePath.Shorts}/${short.slug}`}
                variant="horizontal"
                key={short.id}
              />
            );
          })}
        </S.Inner>
      )}
    </S.Container>
  );
};
