import { useMutation } from '@apollo/client';
import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { logException } from '../logging';
import { TrackingConfig } from '../Tracking';
import {
  getSentAtTimestamp,
  ServersideTrackingEvent,
  updateInteractionSession,
} from '../Tracking/serverside';
import { LOG_EVENT_MUTATION } from '../Tracking/serverside/withLogEvent';
import { LastLocationContext } from './LastLocationProvider';

/**
 * A hook which configures page load analytics events. This should be called in
 * a single top-level component.
 */
const usePageTracking = (
  pageName: string,
  trackingConfig?: TrackingConfig,
): void => {
  const location = useLocation();
  const lastLocation = useContext(LastLocationContext);

  const [logEvent] = useMutation(LOG_EVENT_MUTATION);
  const logServerside = async (
    eventName: ServersideTrackingEvent,
    eventProperties?: Record<string, unknown>,
  ) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties,
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  useEffect(() => {
    const eventProperties = {
      name: pageName,
      referrer: lastLocation === '' ? document.referrer : lastLocation,
      title: document.title,
      path: window.location.pathname,
      url: window.location.href.split('?')[0],
    };

    // This was previously tracking with pageName only, and then mapped to pageName_page_viewed in Segment
    amplitude.track(`${pageName}_page_viewed`, eventProperties);

    if (trackingConfig) {
      const { alwaysUpdateInteractionSession, trackServerside } =
        trackingConfig;

      if (alwaysUpdateInteractionSession) {
        updateInteractionSession();
      }
      if (trackServerside) {
        void logServerside('NAVIGATED_TO_PAGE', {
          pageName,
          clientSentAtUtcTimestamp: getSentAtTimestamp(),
        });
      }
    }
  }, [location.pathname]);
};

export default usePageTracking;
