import React from 'react';
import { IconProps } from '../IconProps';
const SvgNavTrack = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.013 12.36H1.25v6.388h2.763V12.36zM1.25 11.11c-.69 0-1.25.559-1.25 1.25v6.388c0 .69.56 1.25 1.25 1.25h2.763c.69 0 1.25-.56 1.25-1.25V12.36c0-.69-.56-1.25-1.25-1.25H1.25zM11.38 1.25H8.617v17.5h2.763V1.25zM8.617 0c-.69 0-1.25.56-1.25 1.25v17.5c0 .69.56 1.25 1.25 1.25h2.763c.69 0 1.25-.56 1.25-1.25V1.25c0-.69-.56-1.25-1.25-1.25H8.617zM18.751 7.914h-2.763v10.833h2.763V7.914zm-2.763-1.25c-.69 0-1.25.56-1.25 1.25v10.833c0 .69.56 1.25 1.25 1.25h2.763c.69 0 1.25-.56 1.25-1.25V7.914c0-.69-.56-1.25-1.25-1.25h-2.763z"
      fill={primaryColor}
    />
  </svg>
);
export default SvgNavTrack;
