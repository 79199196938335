import { ApolloError, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import {
  useSubDomainInfo_subDomain,
  useSubDomainInfo as useSubDomainInfoResponse,
  useSubDomainInfo_subDomain_departments,
  useSubDomainInfo_subDomain_domains,
  useSubDomainInfo_subDomain_groupFileFields,
  useSubDomainInfo_subDomain_locations,
} from './__generated__/useSubDomainInfo';

export type Subdomain = useSubDomainInfo_subDomain_domains;

export type Location = useSubDomainInfo_subDomain_locations;

export type Department = useSubDomainInfo_subDomain_departments;

export type GroupFileField = useSubDomainInfo_subDomain_groupFileFields;

export type SubdomainInfo = useSubDomainInfo_subDomain;

interface UseSubdomainReturn extends useSubDomainInfo_subDomain {
  error?: Error | ApolloError;
  loading: boolean;
}

export interface UseSubdomainInfoProps {
  subdomain: string;
  skip?: boolean;
}

export const SUBDOMAIN_QUERY = gql`
  query useSubDomainInfo($subdomain: String!) {
    subDomain(subdomain: $subdomain) {
      groupName
      groupId
      subDomain
      authProviderOrganisationId
      domains {
        suffix
      }
      locations {
        id
        name
      }
      departments {
        id
        name
      }
      groupFileFields {
        fileMappingField
        fileHeader
      }
      group {
        authType
      }
      ssoProviderName
    }
  }
`;

export function useSubdomainInfo({
  subdomain,
  skip = false,
}: UseSubdomainInfoProps): Partial<UseSubdomainReturn> {
  const { data, error, loading } = useQuery<useSubDomainInfoResponse>(
    SUBDOMAIN_QUERY,
    {
      variables: {
        subdomain: subdomain,
      },
      skip,
    },
  );

  if (data) {
    if (data.subDomain && data.subDomain.length > 0 && !!data.subDomain[0]) {
      return {
        ...data.subDomain[0],
        loading,
      };
    }

    if (data.subDomain && data.subDomain.length === 0) {
      return {
        groupName: subdomain,
        loading: false,
        error: new Error(`No subdomain found for ${subdomain}`),
      };
    }
  }

  return {
    groupName: subdomain,
    loading,
    error,
  };
}
