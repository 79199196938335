import { useAuth0 } from '@auth0/auth0-react';
import { Breakpoints } from '@unmind/design-system-theme';
import { rem } from 'polished';
import React, { ReactNode, RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import { compose } from 'recompose';
import { css, styled } from 'styles';
import { isMSTeams } from 'utils/MSTeams';
import withUser, { WithUserChildProps } from '../Account/withUser';
import { WithLogoutProps, withLogout } from '../App/Auth';
import useLogoutWithUniversalLogin from '../App/Auth/useLogoutWithUniversalLogin';
import RoutePath from '../App/RoutePath';
import useActiveUserTracking from '../App/Tracking/useActiveUserTracking';
import { useTitle } from '../Home/PersonalisedToday/Header/useTitle';
import { SkipToMainContent } from '../Shared/Accessibility';
import Container from '../Shared/Container';
// eslint-disable-next-line @typescript-eslint/tslint/config
import BackToTop from '../Shared/BackToTop';
import InfoBanner from '../Shared/InfoBanner';
import { HeadingText } from '../Shared/Typography';
import { UserType, extraSmall, small } from '../utils';
import {
  LARGE_HEADER_HEIGHT,
  LARGE_HEIGHT,
  SMALL_HEADER_HEIGHT,
  SMALL_HEIGHT,
  SMALL_TEAMS_HEADER_HEIGHT,
} from '../utils/headerHeight';
import useViewportWidth from '../utils/useViewportWidth';
import AccountMenu from './AccountMenu';
import MainMenu from './MainMenu';
import HelpLink from './HelpLink';
import { UnmindLogo } from './UnmindLogo';

export const HiddenOnMobile = styled.div`
  display: none;

  ${extraSmall(css`
    display: flex;
    flex: 1;
  `)}
`;

const HiddenOnWeb = styled.div`
  display: flex;

  ${extraSmall(css`
    display: none;
  `)}
`;

const Header = styled('header')<{ disablePositionFixed: boolean }>`
  /* Allow overriding position: fixed for Storybook */
  position: ${({ disablePositionFixed }) =>
    disablePositionFixed ? 'static' : 'fixed'};
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.header};
`;

const PageTitle = styled(HeadingText).attrs(({ theme }) => ({
  level: 1,
  sizes: [theme.typography.fontSizes.fontSize24],
  weight: theme.typography.fontWeights.medium,
}))`
  text-align: left;
  width: 100%;
  margin: ${rem(12)} ${rem(16)} ${rem(12)} 0;
`;

export const HeaderInner = styled.div<{ displayedInMSTeams: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border.secondary}`};
  height: ${({ displayedInMSTeams }) =>
    displayedInMSTeams
      ? rem(SMALL_TEAMS_HEADER_HEIGHT)
      : rem(SMALL_HEADER_HEIGHT)};

  ${small(css`
    height: ${rem(LARGE_HEADER_HEIGHT)};
  `)}
`;

const StyledContainer = styled(Container)`
  display: flex;
`;

const NavItemWrapper = styled.div<{
  alignment: 'left' | 'center' | 'right';
  displayedInMSTeams?: boolean;
}>`
  flex: ${({ displayedInMSTeams }) => (displayedInMSTeams ? 2 : 1)};
  width: 100%;
  display: flex;
  justify-content: ${({ alignment }) =>
    alignment === 'left'
      ? 'flex-start'
      : alignment === 'center'
      ? 'center'
      : 'flex-end'};
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  flex: 1;
`;

const HeaderMainMenuWrapper = styled.div`
  align-items: stretch;
  flex: 1;
  display: flex;
`;

const IE11OnlyWrapper = styled.div`
  display: none;

  // Target ie browsers
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    display: block;
  }
`;

const HeaderMainMenu = () => {
  const viewportWidth = useViewportWidth();
  // If the viewport is bigger than a mobile device or if the web-app is in MS Teams regardless of viewport, render the nav menu in the header
  // This is because MS Teams' normal nav-bar is on the bottom and can't clash with ours
  if (viewportWidth >= Breakpoints.XS || isMSTeams()) {
    return (
      <HeaderMainMenuWrapper>
        <MainMenu isInFooter={false} />
      </HeaderMainMenuWrapper>
    );
  }

  return null;
};

interface HeaderTitleProps {
  firstName: string | undefined;
}

const HeaderTitle = ({ firstName }: HeaderTitleProps) => {
  const location = useLocation();
  const pathname = location.pathname;
  const { t: translate } = useTranslation<
    Namespace<'explore' | 'track' | 'talk'>
  >(['explore', 'track', 'talk']);
  const title = useTitle(firstName);

  const renderSwitch = () => {
    switch (pathname) {
      case '/talk ' || '/talk/browse':
        return translate('talk:title');
      case '/explore':
        return translate('explore:title');
      case '/home':
        return title;
      case '/track':
        return translate('track:title');
      default:
        return null;
    }
  };

  return <PageTitle>{renderSwitch()}</PageTitle>;
};

interface AuthenticatedHeaderProps {
  permissions: string[] | undefined;
  userType?: UserType;
  firstName: string | undefined;
  disablePositionFixed?: boolean;
  onLogout(): void;
  onNavigate(path: string): void;
  headerLogoRef: RefObject<HTMLAnchorElement>;
}

export const AuthenticatedHeader = ({
  permissions,
  userType,
  firstName,
  disablePositionFixed = false,
  onLogout,
  onNavigate,
  headerLogoRef,
}: AuthenticatedHeaderProps) => (
  <Header id="app-header" disablePositionFixed={disablePositionFixed}>
    <IE11OnlyWrapper>
      <InfoBanner
        text="We will no longer be supporting Internet Explorer 11 from 15th June.
      Please upgrade to a newer browser to ensure a great Unmind experience."
      />
    </IE11OnlyWrapper>
    <HeaderInner displayedInMSTeams={isMSTeams()}>
      <StyledContainer>
        <HiddenOnMobile>
          <NavItemWrapper alignment="left">
            <UnmindLogo headerLogoRef={headerLogoRef} />
          </NavItemWrapper>
        </HiddenOnMobile>
        {
          // Ensure not to render the title on the MS Teams mobile app as there is no space in the header nav-bar
          !isMSTeams() && (
            <HiddenOnWeb>
              <HeaderTitle firstName={firstName} />
            </HiddenOnWeb>
          )
        }
        <NavItemWrapper alignment="center" displayedInMSTeams={isMSTeams()}>
          <HeaderContent>
            <HeaderMainMenu />
          </HeaderContent>
        </NavItemWrapper>
        <NavItemWrapper alignment="right">
          <HeaderContent>
            <HelpLink path={RoutePath.Help} />
            <AccountMenu
              permissions={permissions}
              userType={userType}
              onLogout={onLogout}
              onNavigate={onNavigate}
            />
          </HeaderContent>
        </NavItemWrapper>
      </StyledContainer>
    </HeaderInner>
  </Header>
);

export const AuthenticatedContent = styled.main`
  display: block;
  height: 100%;
  width: 100%;
  padding-top: ${rem(SMALL_HEADER_HEIGHT)};
  padding-bottom: ${rem(SMALL_HEADER_HEIGHT)};

  ${extraSmall(css`
    padding-bottom: 0;
  `)}

  ${small(css`
    padding-top: ${rem(LARGE_HEADER_HEIGHT)};
  `)}
`;

// This is required as setting `height: 100%` doesn't work
// for childen of an element (such `AuthenticatedContent`) that
// has `min-height: 100%`.
// For smallest phone screens 100% is necessary to see full screen
export const FullHeightContent = styled.div`
  width: 100%;
  height: 100%;

  ${extraSmall(css`
    height: calc(100vh - ${rem(SMALL_HEIGHT)});
  `)}

  ${small(css`
    height: calc(100vh - ${rem(LARGE_HEIGHT)});
  `)}
`;

const AppFooter = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-top: ${({ theme }) =>
    `${rem(1)} solid ${theme.colors.border.secondary}`};
  z-index: ${({ theme }) => theme.zIndex.overModal};
  height: ${rem(SMALL_HEIGHT)};
`;

interface AuthenticatedFooterProps {
  headerLogoRef: RefObject<HTMLAnchorElement>;
}

export const AuthenticatedFooter = ({
  headerLogoRef,
}: AuthenticatedFooterProps) => {
  const viewportWidth = useViewportWidth();

  // If the viewport is a small mobile device and the web-app is not in MS Teams, render the nav menu in the footer
  if (viewportWidth < Breakpoints.XS && !isMSTeams()) {
    return (
      <AppFooter id="app-footer">
        <MainMenu isInFooter={true} />
      </AppFooter>
    );
  }

  return <BackToTop onClickTargetRef={headerLogoRef} />;
};

interface AuthenticatedPageProps
  extends RouteComponentProps,
    WithUserChildProps,
    WithLogoutProps {
  children: ReactNode;
}

export const MAIN_CONTENT_ID = 'maincontent';

const AuthenticatedPage = ({
  history,
  permissions,
  userType,
  firstName,
  logout,
  children,
}: AuthenticatedPageProps) => {
  const headerLogoRef = useRef<HTMLAnchorElement>(null);
  const onNavigate = (path: string) => {
    history.push(path);
  };

  const { isAuthenticated } = useAuth0();
  const { logoutWithUniversalLogin } = useLogoutWithUniversalLogin();

  useActiveUserTracking();

  return (
    <>
      <SkipToMainContent />
      <AuthenticatedHeader
        permissions={permissions}
        userType={userType}
        firstName={firstName}
        onNavigate={onNavigate}
        onLogout={async () => {
          if (isAuthenticated) {
            await logoutWithUniversalLogin();
          } else {
            await logout();

            if (isMSTeams()) {
              history.replace(RoutePath.StartSplitScreen);
            } else {
              history.replace(RoutePath.Login);
            }
          }
        }}
        headerLogoRef={headerLogoRef}
      />
      <AuthenticatedContent id={MAIN_CONTENT_ID}>
        {children}
      </AuthenticatedContent>
      <AuthenticatedFooter headerLogoRef={headerLogoRef} />
    </>
  );
};

export default compose<AuthenticatedPageProps, Record<string, unknown>>(
  withUser,
  withLogout,
  withRouter,
)(AuthenticatedPage);
