import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import PrivacyConsentForm from 'LoggedOut/SignUp/Forms/PrivacyConsentForm';
import { UserDetails } from 'LoggedOut/SignUp/SignUp';
import RoutePath from '../../../App/RoutePath';
import { AlertBox } from '../../../Shared/Form';
import { updateExplicitPrivacyConsent } from './__generated__/updateExplicitPrivacyConsent';

const UPDATE_EXPLICIT_PRIVACY_CONSENT = gql`
  mutation updateExplicitPrivacyConsent($userInput: UpdateUserInput!) {
    updateUser(userInput: $userInput)
  }
`;

const ExplicitPrivacyConsentNeeded = () => {
  const [formError, setFormError] = useState<boolean>();
  const { t: translate } = useTranslation(['logged_out']);

  const [updateExplicitPrivacyConsentMutation] =
    useMutation<updateExplicitPrivacyConsent>(UPDATE_EXPLICIT_PRIVACY_CONSENT, {
      onCompleted: returnedData => {
        // update user returns a boolean indicating success or not
        if (!!returnedData.updateUser) {
          window.location.assign(
            `${window.location.origin}${RoutePath.SSOLoginSuccess}`,
          );
        }
      },
      onError: () => {
        setFormError(true);
      },
    });

  const onSubmit = async (userDetails: UserDetails) =>
    updateExplicitPrivacyConsentMutation({
      variables: {
        userInput: {
          explicitPrivacyConsentGiven: userDetails.explicitPrivacyConsentGiven,
          explicitEEADataStorageConsentGiven:
            userDetails.explicitEEADataStorageConsentGiven,
          marketingOptIn: userDetails.marketingOptIn,
        },
      },
    });

  return (
    <>
      <PrivacyConsentForm onSubmit={onSubmit} />
      {formError ? (
        <AlertBox
          alertType="failed"
          // for now, just add a generic error message
          message={translate('logged_out:login.errors.default_login_error')}
        />
      ) : null}
    </>
  );
};

export default ExplicitPrivacyConsentNeeded;
