import { rem } from 'polished';
import React, { useRef, useState } from 'react';
import { css, styled } from 'styles';
import { isMSTeams } from 'utils/MSTeams';
import { isEmbeddedTeamsMobileFrame } from 'utils/MSTeams/MSTeamsHelpers';
import { small } from 'utils';
import { tracking } from 'App/Tracking';
import getIgnoreFromPath, { getLocationInstance } from './getIgnoreFromPath';
import getPlatform from './getPlatform';
import { DownloadMobileAppOverlay } from './DownloadMobileAppOverlay';

export const MobilePreviewImage = styled.img.attrs({
  alt: '',
})`
  width: 298.05px;
  height: 645px;
  margin-bottom: 24px;
  display: none;

  ${small(css`
    display: block;
  `)}
`;

export const Link = styled.a`
  text-decoration: none;
  margin-bottom: ${rem(5)};
  &:not(:first-child) {
    margin-left: ${rem(16)};
  }
`;

export const MOBILE_DOWNLOAD_DISMISSED_KEY = 'store-overlay-dismissed';

export const ConditionalMobileDownloadOverlay = ({
  showOverlayOverride,
}: {
  showOverlayOverride?: boolean;
}) => {
  let overlayShown = false;
  try {
    overlayShown =
      localStorage.getItem(MOBILE_DOWNLOAD_DISMISSED_KEY) !== null
        ? true
        : false;
  } catch (e) {
    console.error(e);
  }

  const [dismissed, setDismissed] = useState(overlayShown);

  const persistDismiss = () => {
    try {
      setDismissed(true);
      localStorage.setItem(MOBILE_DOWNLOAD_DISMISSED_KEY, 'true');
    } catch (e) {
      console.error(e);
    }
  };

  const onSkip = () => {
    persistDismiss();
    tracking.track('mobile-web-overlay-clicked', { action: 'dismissed' });
  };

  const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

  const platform = getPlatform(window.navigator);

  const isDesktop = platform === 'desktop';

  const isWithinTeams = isEmbeddedTeamsMobileFrame() || isMSTeams();
  const isIgnoredPath = getIgnoreFromPath(getLocationInstance());

  const shouldOverride = showOverlayOverride && !dismissed;

  const showOverlay =
    shouldOverride ||
    (!isDesktop && !dismissed && !isIgnoredPath && !isWithinTeams);

  const focusDownloadLink = () => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.focus();
    }
  };

  focusDownloadLink();

  return showOverlay ? <DownloadMobileAppOverlay onSkip={onSkip} /> : null;
};
