import React, { useState } from 'react';
import { compose } from 'recompose';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Severity } from '@sentry/browser';
import { LoggedOutScreenWrapper } from 'LoggedOut/LoggedOutScreenWrapper/LoggedOutScreenWrapper';
import { logException } from '../../App/logging';
import Loader from '../../Shared/Loader';
import {
  withSubdomainFromUrl,
  ChildProps as SubdomainFromUrlProps,
} from '../withSubdomainFromUrl';
import Route404 from '../../App/Router/Route404';
import RoutePath from '../../App/RoutePath';
import { AuthTypeEnum } from '../../__generated__/globalTypes';
import EnterWorkEmailForm from './Forms/EnterWorkEmailForm';
import EmployeeIdForm from './Forms/EmployeeIdForm';
import NameForm from './Forms/NameForm';
import {
  Department,
  Location,
  GroupFileField,
  useSubdomainInfo,
} from './useSubdomainInfo';
import EnterCustomIDEmailForm from './Forms/EnterCustomIDEmailForm';
import EnterWorkDetailsForm from './Forms/EnterWorkDetailsForm';
import PrivacyConsentForm from './Forms/PrivacyConsentForm';
import EnterPasswordForm from './Forms/EnterPasswordForm';
import useCreateUser from './hooks/useCreateUser';
import SignUpEmailConfirmation from './SignUpEmailConfirmation';
import { useCreateInvitedUser } from './hooks/useCreateInvitedUser';
import { useValidateInvitationToken } from './hooks/useValidateInvitationToken';

export interface SignUpProps
  extends RouteComponentProps,
    SubdomainFromUrlProps {
  skipRedirectForTest?: boolean;
}

export interface CustomIdentifier {
  fieldKey: string;
  fieldValue: string;
}

export interface UserDetails {
  email?: string;
  customIdentifiers?: CustomIdentifier[];
  firstName?: string;
  lastName?: string;
  location?: string;
  department?: string;
  password?: string;
  explicitPrivacyConsentGiven?: boolean;
  explicitEEADataStorageConsentGiven?: boolean;
  explicitHealthDataConsentGiven?: boolean;
  marketingOptIn?: boolean;
  isInvitedUser?: boolean;
  invitationToken?: string;
}

export enum FormType {
  CustomIdentifiers,
  CompanyEmail,
  InvitedUser,
}

export const SIGNUP_BASE_PATH = '/signup';

interface ValidatedRouteProps extends RouteProps {
  userDetails: UserDetails;
  skipRedirectForTest?: boolean;
}

/**
 * ValidatedRoute checks to make sure that they have completed the first stage
 * of the sign up process. In our case, entered their email or the employee ID.
 * As we are using a routing system for each part of the SignUp form, we need to
 * protect against users who attempt to navigate to routes before completing previous
 * steps.
 */
export function ValidatedRoute(props: ValidatedRouteProps) {
  const { userDetails, skipRedirectForTest } = props;

  const { email, customIdentifiers } = userDetails;

  const customIdentifiersHaveBeenCollected = Boolean(
    customIdentifiers && customIdentifiers.length > 0,
  );

  const emailHasBeenCollected = Boolean(email && email.length > 0);

  const canProceedThroughSignupFlow =
    Boolean(skipRedirectForTest) ||
    emailHasBeenCollected ||
    customIdentifiersHaveBeenCollected;

  if (canProceedThroughSignupFlow) {
    return <Route {...props} />;
  }

  return <Redirect to={SIGNUP_BASE_PATH} />;
}

const determineFormType = ({
  isValidInvitedUser,
  customIds,
}: {
  isValidInvitedUser: boolean;
  customIds: GroupFileField[];
}) => {
  if (isValidInvitedUser) {
    return FormType.InvitedUser;
  }

  if (customIds.length) {
    return FormType.CustomIdentifiers;
  }

  return FormType.CompanyEmail;
};

export function SignUp({ subdomain, skipRedirectForTest }: SignUpProps) {
  const {
    error: subdomainError,
    groupFileFields: fetchedGroupFileFields,
    loading: loadingSubdomainInfo,
    groupName: fetchedGroupName,
    departments,
    locations,
    group,
    ssoProviderName,
  } = useSubdomainInfo({
    subdomain,
  });

  const [userDetails, setUserDetails] = useState<UserDetails>({});

  const history = useHistory();
  const { search } = useLocation();

  function updateUserDetails(details: UserDetails) {
    setUserDetails({
      ...userDetails,
      ...details,
    });
  }

  const { tokenIsValid: isValidInvitedUser, hasLovedOneInvitationCode } =
    useValidateInvitationToken((invitationToken: string) =>
      setUserDetails({ ...userDetails, invitationToken }),
    );

  const { createInvitedUser, error: invitedUserError } = useCreateInvitedUser();

  const groupFileFields = !fetchedGroupFileFields ? [] : fetchedGroupFileFields;
  const groupName = fetchedGroupName || '';

  const { authType } = group ?? { authType: null };
  const isUsingSSO = authType === AuthTypeEnum.AUTH0_SSO;

  const filteredLocations = locations
    ? locations.filter((location): location is Location => location !== null)
    : null;

  const filteredDepartments = departments
    ? departments.filter(
        (department): department is Department => department !== null,
      )
    : null;

  const filteredCustomIds = groupFileFields.filter(
    (groupFileField): groupFileField is GroupFileField =>
      !!groupFileField && groupFileField.fileMappingField !== 'email',
  );

  const formType = determineFormType({
    isValidInvitedUser,
    customIds: filteredCustomIds,
  });

  const {
    createUser,
    error: createUserError,
    loading: createUserLoading,
  } = useCreateUser({
    subdomain,
    onCompleted: () => {
      history.push(`${SIGNUP_BASE_PATH}/confirmation${search}`);
    },
  });

  function moveToNextPage(page: string) {
    history.push(`${SIGNUP_BASE_PATH}/${page}${search}`);
  }

  if (subdomainError) {
    logException(subdomainError, {
      level: Severity.Warning,
      tags: {
        subdomain,
      },
    });
  }

  if (loadingSubdomainInfo) {
    return (
      <LoggedOutScreenWrapper data-testid="signup-v2">
        <Loader />
      </LoggedOutScreenWrapper>
    );
  }

  if (isUsingSSO && !hasLovedOneInvitationCode) {
    return <Redirect to={RoutePath.LoginWithSSO} />;
  }

  const shouldShowSSOButton = authType !== AuthTypeEnum.LEGACY_CREDENTIALS;

  return (
    <Switch>
      <Route
        path={SIGNUP_BASE_PATH}
        exact={true}
        render={() => {
          switch (formType) {
            case FormType.InvitedUser:
              return (
                <EnterCustomIDEmailForm
                  onSubmit={(values: UserDetails) => {
                    updateUserDetails(values);
                    moveToNextPage('details');
                  }}
                />
              );
            case FormType.CustomIdentifiers:
              return (
                <EmployeeIdForm
                  isInitialValid={
                    userDetails.customIdentifiers &&
                    userDetails.customIdentifiers.length > 0
                      ? true
                      : false
                  }
                  initialValues={
                    userDetails.customIdentifiers &&
                    userDetails.customIdentifiers.length > 0
                      ? {
                          [userDetails.customIdentifiers[0].fieldKey]:
                            userDetails.customIdentifiers[0].fieldValue,
                        }
                      : undefined
                  }
                  groupFileFields={filteredCustomIds}
                  onSubmit={(values: UserDetails) => {
                    updateUserDetails(values);
                    moveToNextPage('employee-id-email');
                  }}
                  subdomain={subdomain}
                  ssoProviderName={ssoProviderName}
                  shouldShowSSOButton={shouldShowSSOButton}
                />
              );
            default:
              return (
                <EnterWorkEmailForm
                  isInitialValid={userDetails.email ? true : false}
                  initialValues={
                    userDetails.email
                      ? { emailInput: userDetails.email }
                      : undefined
                  }
                  onSubmit={(values: UserDetails) => {
                    updateUserDetails(values);
                    moveToNextPage('details');
                  }}
                  subdomain={subdomain}
                  ssoProviderName={ssoProviderName}
                  shouldShowSSOButton={shouldShowSSOButton}
                />
              );
          }
        }}
      />
      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/employee-id-email`}
        exact={true}
        userDetails={userDetails}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <EnterCustomIDEmailForm
            isInitialValid={userDetails.email ? true : false}
            initialValues={
              userDetails.email ? { emailInput: userDetails.email } : undefined
            }
            onSubmit={(details: UserDetails) => {
              updateUserDetails(details);
              moveToNextPage('details');
            }}
          />
        )}
      />
      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/details`}
        exact={true}
        userDetails={userDetails}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <NameForm
            isInitialValid={
              userDetails.firstName && userDetails.lastName ? true : false
            }
            initialValues={
              userDetails.firstName && userDetails.lastName
                ? {
                    firstName: userDetails.firstName || '',
                    lastName: userDetails.lastName || '',
                  }
                : undefined
            }
            onSubmit={(values: UserDetails) => {
              const hasWorkDetails =
                ((departments && departments.length) ||
                  (locations && locations.length)) &&
                !isValidInvitedUser;
              updateUserDetails(values);
              moveToNextPage(
                hasWorkDetails ? 'work-details' : 'privacy-consent',
              );
            }}
          />
        )}
      />
      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/work-details`}
        exact={true}
        userDetails={userDetails}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <EnterWorkDetailsForm
            isInitialValid={
              userDetails.location || userDetails.department ? true : false
            }
            initialValues={{
              location: userDetails.location || '',
              department: userDetails.department || '',
            }}
            locations={filteredLocations}
            departments={filteredDepartments}
            onSubmit={(values: UserDetails) => {
              updateUserDetails(values);
              moveToNextPage('privacy-consent');
            }}
          />
        )}
      />
      (
      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/privacy-consent`}
        exact={true}
        userDetails={userDetails}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <PrivacyConsentForm
            initialValues={{
              explicitEEADataStorageConsentGiven:
                userDetails.explicitEEADataStorageConsentGiven || false,
              explicitPrivacyConsentGiven:
                userDetails.explicitPrivacyConsentGiven || false,
              marketingOptIn: userDetails.marketingOptIn || false,
            }}
            isInvitedUser={Boolean(userDetails.invitationToken)}
            onSubmit={(values: UserDetails) => {
              updateUserDetails(values);
              moveToNextPage('set-password');
            }}
          />
        )}
      />
      )
      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/set-password`}
        exact={true}
        userDetails={userDetails}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <EnterPasswordForm
            name={groupName}
            loading={createUserLoading}
            error={createUserError || invitedUserError}
            onSubmit={async (password: string) => {
              if (userDetails.invitationToken) {
                await createInvitedUser({
                  firstName: userDetails.firstName || '',
                  lastName: userDetails.lastName || '',
                  email: userDetails.email || '',
                  password: password || '',
                  subdomain,
                  timezone: 'Europe',
                  invitationToken: userDetails.invitationToken,
                  explicitPrivacyConsentGiven:
                    userDetails.explicitPrivacyConsentGiven || false,
                  explicitEEADataStorageConsentGiven:
                    userDetails.explicitEEADataStorageConsentGiven || false,
                  explicitHealthDataConsentGiven: true, // user can only submit if they've given health data consent
                  marketingOptIn: userDetails.marketingOptIn || false,
                });
              } else {
                await createUser({
                  ...userDetails,
                  explicitHealthDataConsentGiven: true, // user can only submit if they've given health data consent
                  subdomain,
                  password,
                });
              }
            }}
            subdomain={subdomain}
          />
        )}
      />
      <ValidatedRoute
        path={`${SIGNUP_BASE_PATH}/confirmation`}
        exact={true}
        userDetails={userDetails}
        skipRedirectForTest={skipRedirectForTest}
        render={() => (
          <SignUpEmailConfirmation
            email={userDetails.email}
            redirectTo={
              formType === FormType.CustomIdentifiers
                ? `${SIGNUP_BASE_PATH}/employee-id-email`
                : RoutePath.SignUp
            }
          />
        )}
      />
      <Route path={`${SIGNUP_BASE_PATH}/*`} component={Route404} />
    </Switch>
  );
}

export default compose<SignUpProps, RouteComponentProps>(withSubdomainFromUrl)(
  SignUp,
);
