import { useQuery } from '@apollo/client';
import { Alert } from '@unmind/design-system-components-web';
import { tracking } from 'App/Tracking';
import { practitionerMatchesQuery } from 'Services/BeGateway/Practitioner/__generated__/practitionerMatchesQuery';
import { practitionerQuery } from 'Services/BeGateway/Practitioner/__generated__/practitionerQuery';
import {
  PRACTITIONER_MATCHES_QUERY,
  PRACTITIONER_QUERY,
} from 'Services/BeGateway/Practitioner/practitioner.services';
import { ScreenReaderContent, useAssignFocus } from 'Shared/Accessibility';
import LoadingIndicator from 'Shared/LoadingIndicator';
import PrimaryButton from 'Shared/PrimaryButton';
import SectionError from 'Shared/SectionError';
import { PractitionerDrawer } from 'Talk/components/PractitionerDrawer';
import { getDefaultDateRange } from 'Talk/components/PractitionerDrawer/helpers/getDefaultDateRange';
import {
  useHasUpcomingBookingOrRequestWithPractitionerOtherThan,
  useLocationData,
} from 'Talk/hooks';
import { BookingType } from 'Talk/types';
import { Namespace } from 'i18next';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { BEGatewayQueryContext, defaultQueryConfig } from 'utils/apollo';
import { useOrganisationEntitlements } from 'Shared/Entitlement';
import RoutePath from '../../../App/RoutePath';
import Modal from '../../../Shared/Modal';
import {
  PractitionerAvatar,
  PractitionerProfileDetails,
} from '../../components';
import { SetLocationForm } from '../../components/SetLocation';
import {
  LocationFormCopy,
  LocationFormTitle,
} from '../../components/SetLocation/styles';
import { UpdateLocationFormWrapper } from '../TalkBrowsePage/styles';
import {
  BookingAlertContainer,
  Content,
  LocationAlertContainer,
  StickyColumn,
} from './styles';

interface Props {
  source?: string;
}

export const TalkPractitionerPage = ({ source }: Readonly<Props>) => {
  const { entitlements } = useOrganisationEntitlements([
    { entitlement: 'aiEnabled' },
  ]);
  const aiMatchingEnabled = entitlements.aiEnabled;
  const history = useHistory();
  const { t } = useTranslation<Namespace<'talk'>>('talk');
  const nameTextRef = useRef<HTMLHeadingElement>(null);
  const {
    userLocationData,
    hasValidLocationData,
    loading: userLocationDataLoading,
  } = useLocationData();
  const { practitionerId } = useParams<{ practitionerId: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const today = useMemo(() => new Date(), []);
  const practitionerQueryVariables = {
    id: practitionerId,
    country: userLocationData?.user?.country?.value,
    state: userLocationData?.user?.state?.value,
    ...getDefaultDateRange(today),
  };

  const {
    hasBookingOrRequest: hasBookingOrRequestWithAnotherPractitioner,
    loading: hasBookingOrRequestWithAnotherPractitionerLoading,
  } = useHasUpcomingBookingOrRequestWithPractitionerOtherThan(practitionerId);

  const {
    data: practitionerData,
    refetch,
    error: practitionerDataError,
    loading: practitionerDataLoading,
  } = useQuery<practitionerQuery>(PRACTITIONER_QUERY, {
    variables: {
      ...practitionerQueryVariables,
    },
    skip: !practitionerId || !hasValidLocationData,
    ...BEGatewayQueryContext,
  });

  const { data: practitionerMatchesData } = useQuery<practitionerMatchesQuery>(
    PRACTITIONER_MATCHES_QUERY,
    {
      skip: !aiMatchingEnabled,
      ...defaultQueryConfig,
      ...BEGatewayQueryContext,
    },
  );

  useAssignFocus(
    nameTextRef,
    Boolean(practitionerData && userLocationData?.user && !isOpen),
  );

  const practitioner = practitionerData?.practitioner;
  const practitionerName = `${practitioner?.firstName} ${practitioner?.lastName}`;
  const practitionerIsMatch = (
    practitionerMatchesData?.practitionerMatches ?? []
  ).some(e => e?.id === practitionerId);
  const isDirectBookingEnabled = Boolean(
    practitioner?.availability?.hasAvailability,
  );
  const bookingType: BookingType = isDirectBookingEnabled
    ? BookingType.DirectBooking
    : BookingType.BookingRequest;

  const bookingButtonLabel = isDirectBookingEnabled
    ? t('practitioner_profile.book_session_button')
    : t('practitioner_profile.request_session_button');

  /**
   * Functions
   */
  const handleOpenDrawer = () => {
    if (!isOpen) {
      tracking.track('talk-practitioner-book-appointment-selected', {
        bookingType,
        practitionerId,
      });
    }

    setIsOpen(!isOpen);
  };

  /**
   * Return
   */
  if (practitionerDataError && !practitionerDataLoading && !practitionerData) {
    return (
      <SectionError
        onRetry={() => {
          void refetch({
            ...practitionerQueryVariables,
          });
        }}
      />
    );
  }

  if (
    userLocationDataLoading ||
    practitionerDataLoading ||
    hasBookingOrRequestWithAnotherPractitionerLoading
  ) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {practitionerData?.practitioner && userLocationData?.user ? (
        <>
          <ScreenReaderContent as="h1" ref={nameTextRef} tabIndex={-1}>
            {practitionerName}
          </ScreenReaderContent>

          {hasBookingOrRequestWithAnotherPractitioner ? (
            <BookingAlertContainer>
              <Alert
                variant="info"
                size="medium"
                title={t('practitioner_profile.booking_disabled_alert.title')}
                body={t('practitioner_profile.booking_disabled_alert.body')}
              />
            </BookingAlertContainer>
          ) : null}

          <Content>
            <StickyColumn>
              <PractitionerAvatar
                practitioner={practitionerData?.practitioner}
              />
              <PrimaryButton
                onClick={handleOpenDrawer}
                label={bookingButtonLabel}
                disabled={hasBookingOrRequestWithAnotherPractitioner}
                data-cy="open-booking-panel-button"
              />
            </StickyColumn>
            <PractitionerProfileDetails
              practitioner={practitionerData?.practitioner}
            />
            <PractitionerDrawer
              practitioner={practitionerData?.practitioner}
              hasBookingOrRequestWithAnotherPractitioner={
                hasBookingOrRequestWithAnotherPractitioner
              }
              userCountryState={userLocationData.user}
              source={source}
              practitionerIsMatch={practitionerIsMatch}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </Content>
        </>
      ) : null}

      <Modal
        open={!hasValidLocationData}
        modalContent={() => (
          <UpdateLocationFormWrapper>
            <LocationAlertContainer>
              <Alert
                variant="warning"
                size="small"
                title={t('practitioner_profile.confirm_location_alert.title')}
                body={t('practitioner_profile.confirm_location_alert.body')}
              />
            </LocationAlertContainer>

            <LocationFormTitle>
              {t('location_modal.update_location_title')}
            </LocationFormTitle>
            <LocationFormCopy>{t('location_modal.body')}</LocationFormCopy>
            <SetLocationForm
              onSubmitSuccess={(country, state) => {
                tracking.track('place-of-residence-updated', {
                  country: country.value,
                  state: state.value,
                  source: 'talk',
                });

                history.push(RoutePath.TalkBrowse);
              }}
            />
          </UpdateLocationFormWrapper>
        )}
      />
    </>
  );
};
