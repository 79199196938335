import gql from 'graphql-tag';
import {
  graphql,
  withApollo,
  WithApolloClient,
} from '@apollo/client/react/hoc';
import { compose } from 'recompose';

import { MutationFunction } from '@apollo/client';
import * as amplitude from '@amplitude/analytics-browser';
import { configureSentryUserScope } from '../logging';
import { WithFeatureFlagUserContextProps } from '../../flags/types';
import withFeatureFlagUserContext from '../../flags/withFeatureFlagUserContext';
import { tracking } from '../Tracking';
import { LogoutReason } from './LogoutReason';
import { AUTHENTICATED_QUERY } from './authenticatedQuery';

interface Response {
  success: boolean;
}

export interface WithLogoutProps {
  logout: MutationFunction;
}

const LOGOUT_MUTATION = gql`
  mutation logout {
    logout
  }
`;

export const withLogout = compose(
  withApollo,
  withFeatureFlagUserContext,
  graphql<
    WithApolloClient<WithLogoutProps & WithFeatureFlagUserContextProps>,
    Response,
    Record<string, unknown>,
    WithLogoutProps
  >(LOGOUT_MUTATION, {
    name: 'logout',
    options: props => ({
      onCompleted: async () => {
        localStorage.removeItem('irisToken');
        localStorage.removeItem('token');
        amplitude.reset();
        configureSentryUserScope(null);
        // If the client isn't initialised then there's no need to clear the store
        if (props.client) {
          props.client.writeQuery({
            query: AUTHENTICATED_QUERY,
            data: {
              authenticated: false,
            },
          });
          void props.client.cache.reset();
        }
        await props.anonymizeFeatureFlagUser?.();
        tracking.track('logout-successful', {
          logOutReason: LogoutReason.DROPDOWN_CLICKED,
          logOutForced: false,
        });
      },
      refetchQueries: ['subDomain'],
      awaitRefetchQueries: true,
    }),
  }),
);
