import React, { lazy, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCountryContact_helpCountryContact_contactDetails as ContactDetails } from 'Help/hooks/__generated__/getCountryContact';
import { getEapServices_helpEAPServices as EapService } from 'Help/hooks/__generated__/getEapServices';
import { useTrackServerside } from 'App/Tracking/serverside';
import { ContactButton } from './../ContactButton';
const Modal = lazy(async () => import('./../ContactModal'));

interface ContactServiceProps {
  activeService: EapService;
  contactDetails?: ContactDetails[];
  countryCode: string;
}

const ContactService = ({ contactDetails, ...props }: ContactServiceProps) => {
  const { t: translate } = useTranslation('help', {
    keyPrefix: 'eap_contact_number',
  });
  const [showModal, setShowModal] = useState(false);
  const trackServerside = useTrackServerside();

  if (!contactDetails || contactDetails.length === 0) return <></>;

  // If there are multiple contact details, render a modal
  if (contactDetails.length > 1) {
    const trackClick = async () => {
      await trackServerside({
        eventName: 'HELP_EAP_CONTACT_SERVICE_CLICKED',
        eventProperties: {
          serviceId: props.activeService.id,
          serviceName: props.activeService.serviceName,
          countryCode: props.countryCode,
          eapCountryContactIds: contactDetails.map(
            ({ id: contactId }) => contactId,
          ),
        },
      });
    };

    return (
      <>
        <ContactButton
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            void trackClick();
            setShowModal(true);
          }}
          {...props}
        />

        {/* The modal is lazily rendered inside a suspense,
        so we're not setting up it and its listeners if it's not needed */}
        <Suspense fallback={<></>}>
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <>
              <p>{translate('title')}</p>
              <ul>
                {contactDetails.map(contactDetail => (
                  <li key={contactDetail.contactNumber}>
                    <span>{contactDetail.label}</span>
                    <ContactButton
                      contactNumber={contactDetail.contactNumber}
                      {...props}
                    />
                  </li>
                ))}
              </ul>
            </>
          </Modal>
        </Suspense>
      </>
    );
  }

  return (
    <ContactButton contactNumber={contactDetails[0].contactNumber} {...props} />
  );
};

export default ContactService;
