import { getMSTeamsDevice, isMSTeams } from 'utils/MSTeams';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import * as amplitude from '@amplitude/analytics-browser';
import { logException } from '../logging';
import { Events } from './Events';

export enum ParentAppName {
  MSTeamsAppName = 'ms-teams',
  UnmindAppName = 'native-unmind',
}

export enum PlatformType {
  Desktop = 'desktop',
  Web = 'web',
  IOS = 'ios',
  Android = 'android',
  MobileIframe = 'mobile-iframe',
}

export const getPlatformType = (): PlatformType | null => {
  if (!isMSTeams()) {
    return PlatformType.Web;
  }

  const currentDevice = getMSTeamsDevice();

  if (currentDevice === 'unknown') {
    return null;
  }

  if (Object.values(PlatformType).includes(currentDevice)) {
    return currentDevice;
  }

  return null;
};
export interface TrackingUser {
  activationDate: string | null;
  createdAt: string;
  uuid: string | null;
  groupId: string | undefined;
  client: string | undefined;
  clientTier: string | undefined;
  subdomain: string | undefined;
  praiseDirectoryEnabled: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  userType: string | undefined;
  accessType: string | undefined;
  department: string | undefined;
  departmentId: string | undefined;
  location: string | undefined;
  locationId: string | undefined;
  identifiedAsLineManager: boolean;
}

const identifyUser = ({
  userId,
  traits,
  callback,
}: {
  userId: string | null | undefined;
  traits: TrackingUser;
  callback?(): void;
}) => {
  const identify = new amplitude.Identify();

  if (!userId) {
    logException(
      new Error('No user id available when identifying user for tracking'),
    );
  } else {
    amplitude.setUserId(userId);
  }

  Object.keys(traits).forEach(key => {
    identify.set(key, traits[key as keyof TrackingUser] as string);
  });

  amplitude.identify(identify);

  if (callback) {
    callback();
  }
};

interface EventData {
  [key: string]: any;
}

const track = (event: Events, data?: EventData) => {
  amplitude.track(event, {
    ...data,
    parentApp: isMSTeams()
      ? ParentAppName.MSTeamsAppName
      : ParentAppName.UnmindAppName,
    platformType: getPlatformType(),
    subdomain: getSubdomainFromUrl(),
  });
};

export interface Tracking {
  track: typeof track;
  identifyUser: typeof identifyUser;
}

export default {
  track,
  identifyUser,
} as Tracking;
