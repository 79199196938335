import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavCompanionActive = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 21" fill="none" {...props}>
    <g clipPath="url(#NavCompanionActive_svg__clip0_354_2385)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.577 1.594c.256-1.169 1.92-1.176 2.186-.01l.012.054.024.104A3.183 3.183 0 0 0 8.352 4.15c1.22.212 1.22 1.963 0 2.176a3.183 3.183 0 0 0-2.558 2.428l-.03.137c-.267 1.167-1.931 1.16-2.187-.01l-.026-.117a3.164 3.164 0 0 0-2.548-2.44c-1.218-.212-1.218-1.96 0-2.172a3.164 3.164 0 0 0 2.545-2.427l.02-.086.009-.045zm7.801 5.922c.318-1.45 2.382-1.46 2.712-.012l.02.086.041.179a5.772 5.772 0 0 0 4.63 4.367c1.506.262 1.506 2.423 0 2.685a5.772 5.772 0 0 0-4.638 4.403l-.053.23c-.33 1.447-2.394 1.438-2.712-.013l-.043-.196a5.739 5.739 0 0 0-4.622-4.427c-1.503-.261-1.503-2.418 0-2.679a5.739 5.739 0 0 0 4.65-4.55l.015-.073z"
        fill={primaryColor}
      />
    </g>
    <defs>
      <clipPath id="NavCompanionActive_svg__clip0_354_2385">
        <path fill="#fff" transform="translate(0 .624)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNavCompanionActive;
