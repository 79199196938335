import { useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { rem } from 'polished';
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css, styled, useTheme } from 'styles';
import { useLocation } from 'react-router';
import { isMSTeams } from 'utils/MSTeams';
import { Error, TextInput } from '@unmind/design-system-components-web';
import { useRedirectToSubdomain } from 'utils/useRedirectToSubdomain';
import { REMOVE_TEAMS_LOGIN_CONTEXT_PARAM } from 'utils/MSTeams/MSTeamsHelpers';
import RoutePath from '../../../App/RoutePath';
import { tracking } from '../../../App/Tracking';
import { validateFieldNotEmpty } from '../../../Shared/Form/Formik';
import {
  LoggedOutScreenWrapper,
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
} from '../../LoggedOutScreenWrapper';
import { AuthTypeEnum } from '../../../__generated__/globalTypes';
import BodyText from '../../../Shared/Typography/BodyText';
import { medium } from '../../../utils';
import { StyledForm, StyledSubmitButton } from './CommonFormStyledComponents';

const Form = styled(StyledForm)`
  // Target all browsers except ie
  @supports not (-ms-high-contrast: none) {
    ${medium(css`
      flex: 0;
    `)}
  }
`;

const HelpText = styled(BodyText)`
  margin-top: ${rem(18)};
`;

const BoldLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration-line: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;
interface FormFields {
  companyName: string;
}

export const FIND_SUBDOMAIN_QUERY = gql`
  query findOrganisationByAlias($input: OrganisationAliasSearchInput!) {
    findOrganisationByAlias(input: $input) {
      code
      success
      organisation {
        subdomain
      }
      authType
    }
  }
`;

const CompanyNameForm = () => {
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'login.forms.company_name',
  });
  const { t: sharedTranslate } = useTranslation('shared', {
    keyPrefix: 'errors',
  });

  const { typography } = useTheme();
  const formikRef = useRef<Formik<FormFields>>(null);
  const [error, setError] = useState('');
  const location = useLocation();
  const redirectLocation = new URLSearchParams(location.search).get('redirect');
  const isSignUpFlow =
    redirectLocation && redirectLocation.indexOf('signup') > 0;
  const { redirectToSubdomain } = useRedirectToSubdomain();
  const helpLink = isSignUpFlow
    ? `${RoutePath.FindYourOrganisation}?path=signup`
    : RoutePath.FindYourOrganisation;

  if (isMSTeams()) {
    const shouldRemoveSubdomain = new URLSearchParams(location.search).get(
      REMOVE_TEAMS_LOGIN_CONTEXT_PARAM,
    );

    if (shouldRemoveSubdomain) {
      localStorage.removeItem('subdomain');
    }
  }

  const [findSubdomain, { loading }] = useLazyQuery(FIND_SUBDOMAIN_QUERY, {
    onCompleted: data => {
      const {
        findOrganisationByAlias: { organisation, success, authType },
      } = data;

      if (!success) {
        tracking.track('find-organisation-failed', {
          subdomain: formikRef?.current?.state.values.companyName,
        });

        setError(translate('form.errors.org_not_found'));
      } else {
        tracking.track('find-organisation-confirmed', {
          subdomain: organisation.subdomain,
        });

        let redirectPath: RoutePath;
        let queryString: string | undefined;

        // if using SSO, redirect to root page
        if (authType === AuthTypeEnum.AUTH0_SSO) {
          // subdomain root (defaults to signin)
          redirectPath = isMSTeams() ? RoutePath.LoginWithSSO : RoutePath.Login;
        } else {
          // for legacy credentials go to appropriate redirect URL (signup/signin)
          redirectPath = (redirectLocation ||
            RoutePath.LoginWithCredentials) as RoutePath;
        }

        redirectToSubdomain({
          subdomain: organisation.subdomain,
          routePath: redirectPath,
          queryString,
        });
      }
    },
    onError: () => {
      setError(sharedTranslate('messages.something_wrong'));
    },
  });

  const onSubmit = async (values: FormFields) =>
    findSubdomain({
      variables: {
        input: {
          searchTerm: values.companyName,
        },
      },
    });

  const companyNameLabel = translate('form.company_name_field.label');
  const companyNameA11yLabel = translate('form.company_name_field.a11y_label');
  const submitButtonLabel = translate('form.submit_button.label');
  const submitButtonA11yLabel = translate('form.submit_button.label');
  const validationError = translate('form.errors.empty_company_name');

  return (
    <LoggedOutScreenWrapper>
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('heading')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="form-subtitle">
        {translate('subtitle')}
      </LoggedOutScreenSubtitle>
      <Formik
        ref={formikRef}
        isInitialValid={false}
        initialValues={{ companyName: '' }}
        validate={({ companyName }) => {
          const companyNameError = validateFieldNotEmpty(
            companyName,
            validationError,
          );

          return companyNameError ? { companyName: error } : {};
        }}
        onSubmit={onSubmit}
      >
        {({ isValid, setFieldValue, errors, setTouched, touched, values }) => (
          <Form data-testid="company-name-input-form">
            <TextInput
              data-testid="company-name-input"
              name="companyName"
              aria-label={companyNameA11yLabel}
              type="text"
              id="companyName"
              value={values.companyName}
              additionalText={{ label: companyNameLabel }}
              onChange={e => {
                setError('');
                setTouched({ companyName: false });
                setFieldValue('companyName', e.target.value);
              }}
              onBlur={() => {
                setTouched({ companyName: true });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTouched({ companyName: true });
                }
              }}
              errorText={
                errors.companyName && touched.companyName
                  ? errors.companyName
                  : undefined
              }
            />

            <StyledSubmitButton
              data-testid="submit-button"
              type="submit"
              disabled={!isValid}
              loading={loading}
              label={submitButtonLabel}
              aria-label={submitButtonA11yLabel}
            />

            {Boolean(error) && <Error errorText={error} showIcon />}
          </Form>
        )}
      </Formik>
      <HelpText sizes={[typography.fontSizes.fontSize16]}>
        {Trans({
          t: translate,
          i18nKey: 'footer_help_text',
          defaults:
            "Can't find your company? <help_link>Let us help</help_link>",
          components: {
            help_link: (
              <BoldLink
                data-testid="find-company-help-link"
                to={helpLink}
                onClick={() => {
                  tracking.track('search-organisation');
                }}
              />
            ),
          },
        })}
      </HelpText>
    </LoggedOutScreenWrapper>
  );
};

export default CompanyNameForm;
