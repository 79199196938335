import React, { useState } from 'react';
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { styled } from 'styles';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Severity } from '@sentry/browser';
import { logException } from '../../App/logging';
import Loader from '../../Shared/Loader';
import Route404 from '../../App/Router/Route404';
import { LocalGeneralError } from '../../Shared/AlertView';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import { Department, Location, useSubdomainInfo } from './useSubdomainInfo';
import EnterWorkDetailsForm from './Forms/EnterWorkDetailsForm';
import PrivacyConsentForm from './Forms/PrivacyConsentForm';
import EnterCustomIDEmailForm from './Forms/EnterCustomIDEmailForm';
import {
  ssoAdditionalUserDetails,
  ssoAdditionalUserDetailsVariables,
} from './__generated__/ssoAdditionalUserDetails';

export interface SSOSignUpProps extends RouteComponentProps {
  skipRedirectForTest?: boolean;
}

export interface SSOUserDetails {
  email?: string;
  location?: string;
  department?: string;
  explicitPrivacyConsentGiven?: boolean;
  marketingOptIn?: boolean;
}

interface AuthDetails {
  auth0UserId?: string;
  auth0State?: string;
}

export const SSO_ADDITIONAL_DETAILS_MUTATION = gql`
  mutation ssoAdditionalUserDetails(
    $auth0UserId: String!
    $auth0State: String!
    $email: String!
    $location: String
    $department: String
    $marketingOptIn: Boolean!
    $explicitPrivacyConsent: Boolean!
  ) {
    ssoAdditionalUserDetails(
      auth0UserId: $auth0UserId
      auth0State: $auth0State
      email: $email
      location: $location
      department: $department
      marketingOptIn: $marketingOptIn
      explicitPrivacyConsent: $explicitPrivacyConsent
    )
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SSO_SIGNUP_BASE_PATH = '/sso-signup';
export const authQueryParams = (authDetails: AuthDetails) =>
  `?auth0_user_id=${authDetails.auth0UserId}&state=${authDetails.auth0State}`;

interface ValidatedRouteProps extends RouteProps {
  authDetails: AuthDetails;
  userDetails: SSOUserDetails;
  skipRedirectForTest?: boolean;
}

/**
 * ValidatedRoute checks to make sure that they have completed the first stage
 * of the sign up process. In our case, entered their email or the employee ID.
 * As we are using a routing system for each part of the SignUp form, we need to
 * protect against users who attempt to navigate to routes before completing previous
 * steps.
 */
export function ValidatedRoute(props: ValidatedRouteProps) {
  const { authDetails, userDetails, skipRedirectForTest } = props;

  const { email } = userDetails;

  const emailHasBeenCollected = Boolean(email && email.length > 0);

  const canProceedThroughSignupFlow =
    Boolean(skipRedirectForTest) || emailHasBeenCollected;

  if (canProceedThroughSignupFlow) {
    return <Route {...props} />;
  }

  return (
    <Redirect to={`${SSO_SIGNUP_BASE_PATH}/${authQueryParams(authDetails)}`} />
  );
}

function isCollectingWorkDetails(
  locations?: (Location | null)[] | null,
  departments?: (Department | null)[] | null,
) {
  return !!(
    (locations && locations.length > 0) ||
    (departments && departments.length > 0)
  );
}

export function SSOSignUp(props: SSOSignUpProps) {
  const subdomain = getSubdomainFromUrl();

  const {
    error: subdomainError,
    loading: loadingSubdomainInfo,
    departments,
    locations,
  } = useSubdomainInfo({
    subdomain,
  });

  const [userDetails, setUserDetails] = useState<SSOUserDetails>({});
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(location.search);
  const auth0State = urlSearchParams.get('state') || '';
  const auth0UserId = urlSearchParams.get('auth0_user_id') || '';
  const [authDetails] = useState<AuthDetails>({ auth0State, auth0UserId });

  const [
    getAdditionalDetails,
    { loading: loadingDetailsToken, error: errorDetailsToken },
  ] = useMutation<ssoAdditionalUserDetails, ssoAdditionalUserDetailsVariables>(
    SSO_ADDITIONAL_DETAILS_MUTATION,
    {
      onCompleted: data => {
        const auth0RedirectToken = data.ssoAdditionalUserDetails;
        const resumeAuthFlowTokenParam = `?state=${auth0State}&auth0_redirect_token=${auth0RedirectToken}`;
        const resumeAuthFlowUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue${resumeAuthFlowTokenParam}`;
        window.location.assign(resumeAuthFlowUrl);
      },
    },
  );

  const collectingWorkDetails = isCollectingWorkDetails(locations, departments);

  const isMissingParams = !auth0State || !auth0UserId;
  if (isMissingParams || errorDetailsToken) {
    return <LocalGeneralError />;
  }

  const filteredLocations = locations
    ? locations.filter((location): location is Location => location !== null)
    : null;

  const filteredDepartments = departments
    ? departments.filter(
        (department): department is Department => department !== null,
      )
    : null;

  function updateUserDetails(details: SSOUserDetails) {
    setUserDetails({
      ...userDetails,
      ...details,
    });
  }

  function moveToNextPage(page: string) {
    history.push(
      `${SSO_SIGNUP_BASE_PATH}/${page}/${authQueryParams(authDetails)}`,
    );
  }

  if (subdomainError) {
    logException(subdomainError, {
      level: Severity.Warning,
      tags: {
        subdomain,
      },
    });
  }

  if (loadingSubdomainInfo) {
    return (
      <Wrapper data-testid="signup-v2">
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Switch>
      <Route
        path={SSO_SIGNUP_BASE_PATH}
        exact={true}
        render={() => (
          <EnterCustomIDEmailForm
            isInitialValid={userDetails.email ? true : false}
            initialValues={{
              emailInput: userDetails.email || '',
              marketingOptIn: userDetails.marketingOptIn || false,
            }}
            isSSOFlow={true}
            onSubmit={async (details: SSOUserDetails) => {
              const nextPage = collectingWorkDetails
                ? 'work-details'
                : 'privacy-consent';

              if (nextPage) {
                updateUserDetails(details);
                moveToNextPage(nextPage);
              } else {
                const localDetails = {
                  ...userDetails,
                  ...details,
                };
                await getAdditionalDetails({
                  variables: {
                    auth0UserId,
                    auth0State,
                    email: localDetails.email || '',
                    location: localDetails.location,
                    department: localDetails.department,
                    marketingOptIn: localDetails.marketingOptIn || false,
                    explicitPrivacyConsent:
                      localDetails.explicitPrivacyConsentGiven || false,
                  },
                });
              }
            }}
          />
        )}
      />

      <ValidatedRoute
        path={`${SSO_SIGNUP_BASE_PATH}/work-details`}
        exact={true}
        authDetails={authDetails}
        userDetails={userDetails}
        skipRedirectForTest={props.skipRedirectForTest}
        render={() => (
          <EnterWorkDetailsForm
            isInitialValid={
              userDetails.location || userDetails.department ? true : false
            }
            initialValues={{
              location: userDetails.location || '',
              department: userDetails.department || '',
            }}
            locations={filteredLocations}
            departments={filteredDepartments}
            onSubmit={async (details: SSOUserDetails) => {
              updateUserDetails(details);
              moveToNextPage('privacy-consent');
            }}
          />
        )}
      />

      <ValidatedRoute
        path={`${SSO_SIGNUP_BASE_PATH}/privacy-consent`}
        exact={true}
        authDetails={authDetails}
        userDetails={userDetails}
        skipRedirectForTest={props.skipRedirectForTest}
        render={() => (
          <PrivacyConsentForm
            initialValues={{
              explicitPrivacyConsentGiven: false,
              marketingOptIn: userDetails.marketingOptIn || false,
            }}
            onSubmit={async (details: SSOUserDetails) => {
              const localDetails = {
                ...userDetails,
                ...details,
              };
              await getAdditionalDetails({
                variables: {
                  auth0UserId,
                  auth0State,
                  email: localDetails.email || '',
                  location: localDetails.location,
                  department: localDetails.department,
                  marketingOptIn: localDetails.marketingOptIn || false,
                  explicitPrivacyConsent:
                    localDetails.explicitPrivacyConsentGiven || false,
                },
              });
            }}
            loading={loadingDetailsToken}
          />
        )}
      />
      <Route path={`${SSO_SIGNUP_BASE_PATH}/*`} component={Route404} />
    </Switch>
  );
}

export default SSOSignUp;
