import { Formik } from 'formik';
import React from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
import { BodyText, SingleSelect } from '@unmind/design-system-components-web';
import PrimaryButton from '../../../Shared/PrimaryButton';
import {
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import { small, medium } from '../../../utils';
import { UserDetails } from '../SignUp';
import { Department, Location } from '../useSubdomainInfo';
import { filterNonNull } from '../../../typescript/helpers';
import { workDetailsFormValidation } from '../workDetailsFormValidation';
import { StyledForm } from './CommonFormStyledComponents';

const SubmitButton = styled(PrimaryButton)`
  margin: auto 0 ${rem('16px')} 0;
  width: 100%;

  ${medium(css`
    margin-top: ${rem(24)};
  `)}
`;

type FormValues = {
  location: string;
  department: string;
};

interface EnterWorkDetailsProps {
  locations?: Location[] | null;
  departments?: Department[] | null;
  onSubmit(details: UserDetails): void;
  isInitialValid?: boolean;
  initialValues?: FormValues;
}

const FieldWrapper = styled.div`
  margin-bottom: ${rem('16px')};

  ${small(css`
    margin-bottom: ${rem('32px')};
  `)}

  ${medium(css`
    margin-bottom: ${rem('24px')};
  `)}
`;

const Smallprint = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize12],
  forwardedAs: 'p',
}))`
  margin-bottom: ${rem(20)};
`;

const EnterWorkDetailsForm = ({
  locations,
  departments,
  onSubmit,
  isInitialValid = false,
  initialValues = {
    location: '',
    department: '',
  },
}: EnterWorkDetailsProps) => {
  const { t: translate } = useTranslation('logged_out');

  const filteredLocations = locations ? locations.filter(filterNonNull) : [];
  const filteredDepartments = departments
    ? departments.filter(filterNonNull)
    : [];

  return (
    <LoggedOutScreenWrapper dataTestId="work-details-page">
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('sign_up.forms.work_details.heading')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="form-subtitle">
        {translate('sign_up.forms.work_details.subtitle')}
      </LoggedOutScreenSubtitle>
      <Formik
        initialValues={initialValues}
        isInitialValid={isInitialValid}
        onSubmit={async values => {
          onSubmit({
            location: locations ? values.location : undefined,
            department: departments ? values.department : undefined,
          });
        }}
        validate={values =>
          workDetailsFormValidation({
            ...values,
            translate,
            filteredLocations,
            filteredDepartments,
          })
        }
      >
        {({ isValid, setFieldValue, values }) => (
          <StyledForm>
            {filteredLocations.length > 0 ? (
              <FieldWrapper>
                <SingleSelect
                  data-testid="location-select"
                  placeholder={translate(
                    'sign_up.forms.work_details.work_location_field.placeholder',
                  )}
                  aria-label={translate(
                    'sign_up.forms.work_details.work_location_field.label',
                  )}
                  selectedValue={values.location || initialValues.location}
                  onChange={value => setFieldValue('location', value)}
                  options={filteredLocations.map(location => ({
                    value: location.id || '',
                    label: location.name || '',
                  }))}
                />
              </FieldWrapper>
            ) : null}
            {filteredDepartments.length > 0 ? (
              <FieldWrapper>
                <SingleSelect
                  data-testid="department-select"
                  placeholder={translate(
                    'sign_up.forms.work_details.department_field.placeholder',
                  )}
                  onChange={value => setFieldValue('department', value)}
                  aria-label={translate(
                    'sign_up.forms.work_details.department_field.a11y_label',
                  )}
                  selectedValue={values.department || initialValues.department}
                  options={filteredDepartments.map(department => ({
                    value: department.id || '',
                    label: department.name || '',
                  }))}
                />
              </FieldWrapper>
            ) : null}
            <Smallprint>
              {translate('sign_up.forms.work_details.body')}
            </Smallprint>
            <SubmitButton
              data-testid="submit-button"
              type="submit"
              label={translate(
                'sign_up.forms.work_details.submit_button.label',
              )}
              disabled={!isValid}
            />
          </StyledForm>
        )}
      </Formik>
    </LoggedOutScreenWrapper>
  );
};

export default EnterWorkDetailsForm;
