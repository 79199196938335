import {
  LoggedOutScreenTitle,
  LoggedOutScreenSubtitle,
} from 'LoggedOut/LoggedOutScreenWrapper';
import PrimaryButton from 'Shared/PrimaryButton';
import { QuoteQuestionAndExclamationMark } from 'icons';
import { rem } from 'polished';
import styled from 'styled-components';

export const InvalidInviteTokenSVG = styled(
  QuoteQuestionAndExclamationMark,
).attrs({
  width: rem(163),
  height: rem(139),
})`
  margin: ${rem(35)} 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled(LoggedOutScreenTitle)`
  align-self: center;
`;

export const Description = styled(LoggedOutScreenSubtitle)`
  text-align: center;
`;

export const Button = styled(PrimaryButton)`
  width: 100%;
`;
