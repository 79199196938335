import { Field, Formik } from 'formik';
import React from 'react';
import { styled } from 'styles';
import { useTranslation } from 'react-i18next';
import { TextInput } from '@unmind/design-system-components-web';
import * as Yup from 'yup';
import {
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import { UserDetails } from '../SignUp';
import { StyledForm, StyledSubmitButton } from './CommonFormStyledComponents';

const FirstNameInputWrapper = styled.div`
  margin-bottom: 16px;
`;

export interface NameFormProps {
  onSubmit(details: UserDetails): void;
  isInitialValid?: boolean;
  initialValues?: { firstName: string; lastName: string };
}

const NameForm = ({
  isInitialValid = false,
  onSubmit,
  initialValues = { firstName: '', lastName: '' },
}: NameFormProps) => {
  const { t: translate } = useTranslation(['logged_out', 'shared']);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
  });

  return (
    <LoggedOutScreenWrapper dataTestId="details-page">
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('logged_out:sign_up.forms.name_form.heading')}
      </LoggedOutScreenTitle>
      <Formik
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={values => {
          onSubmit({
            firstName: values.firstName,
            lastName: values.lastName,
          });
        }}
        validationSchema={validationSchema}
      >
        {({ isValid, touched, errors }) => (
          <StyledForm>
            <FirstNameInputWrapper>
              <Field name="firstName">
                {({ field }: any) => (
                  <TextInput
                    {...field}
                    id="firstName"
                    data-testid="first-name-input"
                    placeholder={translate(
                      'logged_out:sign_up.forms.name_form.first_name_field.label',
                    )}
                    aria-label={translate(
                      'logged_out:sign_up.forms.name_form.first_name_field.label',
                    )}
                    additionalText={{
                      label: translate(
                        'logged_out:sign_up.forms.name_form.first_name_field.label',
                      ),
                    }}
                    errorText={translate(
                      'shared:forms.validate_fields.first_name.text',
                    )}
                    hasError={touched.firstName && errors.firstName}
                  />
                )}
              </Field>
            </FirstNameInputWrapper>
            <Field name="lastName">
              {({ field }: any) => (
                <TextInput
                  {...field}
                  id="lastName"
                  data-testid="last-name-input"
                  placeholder={translate(
                    'logged_out:sign_up.forms.name_form.last_name_field.label',
                  )}
                  aria-label={translate(
                    'logged_out:sign_up.forms.name_form.last_name_field.label',
                  )}
                  additionalText={{
                    label: translate(
                      'logged_out:sign_up.forms.name_form.last_name_field.label',
                    ),
                  }}
                  errorText={translate(
                    'shared:forms.validate_fields.last_name.text',
                  )}
                  hasError={touched.lastName && errors.lastName}
                />
              )}
            </Field>
            <StyledSubmitButton
              data-testid="submit-button"
              type="submit"
              label={translate(
                'logged_out:sign_up.forms.name_form.submit_button.label',
              )}
              disabled={!isValid}
            />
          </StyledForm>
        )}
      </Formik>
    </LoggedOutScreenWrapper>
  );
};

export default NameForm;
