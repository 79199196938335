import { rem } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { styled } from 'styles';
import { PrimaryButton } from '../../../Shared/PrimaryButton/PrimaryButton';
import { ButtonSize } from '../../../Shared/Button';
import RoutePath from '../../RoutePath';
import Wrapper404 from './Wrapper404';

interface ReRoute {
  label: string;
  routePath: RoutePath;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: ${rem(20)};
`;

const GoToButton = styled(PrimaryButton)`
  margin: 0 auto;
`;

const Route404 = (props: RouteComponentProps) => {
  const { t: translate } = useTranslation('shared');
  const { pathname } = props.location;
  const path = pathname.split('/');

  const getReRoute = (): ReRoute => {
    switch (path[1]) {
      case 'series':
        return {
          label: translate('404_screen.reroute_button.series_variant.text'),
          routePath: RoutePath.Series,
        };
      default:
        return {
          label: translate('404_screen.reroute_button.home_variant.text'),
          routePath: RoutePath.Home,
        };
    }
  };

  const reRoute = getReRoute();

  const navigateToReRoute = () => {
    props.history.push(reRoute.routePath);
  };

  return (
    <Wrapper>
      <Wrapper404 title={translate('404_screen.title')}>
        <ButtonWrapper>
          <GoToButton
            label={reRoute.label}
            size={ButtonSize.medium}
            onClick={navigateToReRoute}
          />
        </ButtonWrapper>
      </Wrapper404>
    </Wrapper>
  );
};

export default withRouter(Route404);
