import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styles';
import { TextInput, Error } from '@unmind/design-system-components-web';
import { StorageKey, useStorage } from 'utils/useStorage';
import { setStoredValue } from 'utils/storageHelpers';
import {
  validateEmail,
  validateEmailOnlyForm,
} from '../../../Shared/Form/Formik';
import { medium } from '../../../utils';
import { StyledForm, StyledSubmitButton } from './CommonFormStyledComponents';

const Form = styled(StyledForm)`
  // Target all browsers except ie
  @supports not (-ms-high-contrast: none) {
    ${medium(css`
      flex: 0;
    `)}
  }
`;

export interface FormFields {
  emailInput: string;
}

export interface EmailInputFormProps {
  inputLabel?: string;
  submitLabel?: string;
  isInitialValid?: boolean;
  initialValues?: FormFields;
  setError?(error: string): void;
  error?: string;
  loading?: boolean;
  formikRef?: React.RefObject<Formik<FormFields>>;
  onSubmit(values: FormFields): void;
}

const EmailInputForm = ({
  inputLabel,
  submitLabel,
  isInitialValid = false,
  initialValues = { emailInput: '' },
  ...props
}: EmailInputFormProps) => {
  const { error, setError, loading } = props;
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'sign_up.forms.email_input',
  });
  const emailCookie = useStorage(StorageKey.EMAIL);

  if (emailCookie) {
    initialValues.emailInput = emailCookie;
    isInitialValid = !validateEmailOnlyForm(initialValues).emailInput;
  }

  const emailInputLabel = inputLabel ?? translate('email_field.label');
  const submitButtonLabel = submitLabel ?? translate('submit_button.label');

  return (
    <Formik
      ref={props.formikRef}
      isInitialValid={isInitialValid}
      initialValues={initialValues}
      validate={validateEmailOnlyForm}
      onSubmit={values => {
        setStoredValue({
          key: StorageKey.EMAIL,
          value: values.emailInput,
        });
        props.onSubmit(values);
      }}
      enableReinitialize
    >
      {({ isValid, setFieldValue, errors, setTouched, touched, values }) => (
        <Form data-testid="email-input-form">
          <TextInput
            data-testid="email-input"
            name="emailInput"
            aria-label={emailInputLabel}
            type="email"
            id="emailInput"
            value={values.emailInput}
            additionalText={{ label: emailInputLabel }}
            onChange={e => {
              if (setError) setError('');
              setTouched({ emailInput: false });
              setFieldValue('emailInput', e.target.value);
            }}
            onBlur={() => {
              setTouched({ emailInput: true });

              if (!validateEmail(values.emailInput)) {
                setStoredValue({
                  key: StorageKey.EMAIL,
                  value: values.emailInput,
                });
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTouched({ emailInput: true });
              }
            }}
            hasError={Boolean(errors.emailInput && touched.emailInput)}
            errorText={errors.emailInput}
          />
          <StyledSubmitButton
            data-testid="submit-button"
            type="submit"
            disabled={!isValid}
            loading={loading}
            label={submitButtonLabel}
            aria-label={submitButtonLabel}
          />

          {Boolean(error) && <Error errorText={error} showIcon />}
        </Form>
      )}
    </Formik>
  );
};

export default EmailInputForm;
