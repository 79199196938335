import { gql, useQuery } from '@apollo/client';
import { getCountryContact } from './__generated__/getCountryContact';

export const GET_COUNTRY_CONTACT = gql`
  query getCountryContact {
    helpCountryContact {
      contactNumber
      eapProvider {
        privacyPolicyUrl
        displayName
      }
      contactDetails {
        contactNumber
        id
        label
      }
    }
  }
`;

export const useGetCountryContact = () => {
  const { data, loading, error, refetch } = useQuery<getCountryContact>(
    GET_COUNTRY_CONTACT,
    {
      errorPolicy: 'all',
      returnPartialData: false,
    },
  );

  return { data, loading, error, refetch };
};
