import gql from 'graphql-tag';
import { useState } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { tracking } from '../../../App/Tracking';
import { CustomIdentifier, UserDetails } from '../SignUp';

export const CREATE_USER_MUTATION = gql`
  mutation UseCreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $subdomain: String!
    $location: String
    $customIdentifiers: [UserIdentifierInput]
    $department: String
    $dateOfBirth: Date
    $explicitPrivacyConsentGiven: Boolean
    $explicitEEADataStorageConsentGiven: Boolean
    $explicitHealthDataConsentGiven: Boolean
    $marketingOptIn: Boolean
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      subdomain: $subdomain
      customIdentifiers: $customIdentifiers
      location: $location
      department: $department
      dateOfBirth: $dateOfBirth
      explicitPrivacyConsentGiven: $explicitPrivacyConsentGiven
      explicitEEADataStorageConsentGiven: $explicitEEADataStorageConsentGiven
      explicitHealthDataConsentGiven: $explicitHealthDataConsentGiven
      marketingOptIn: $marketingOptIn
    )
  }
`;

export interface CreateUserVariables extends UserDetails {
  subdomain: string;
  location?: string;
  department?: string;
  customIdentifiers?: CustomIdentifier[];
  dateOfBirth?: string;
  marketingOptIn?: boolean;
}

interface UseCreateUserProps {
  onCompleted?(data?: { createUser: string }): void;
  onError?(error?: ApolloError): void;
  subdomain: string;
}

const trackRetriedAttempts = (hasAttempted: boolean, subdomain: string) => {
  if (hasAttempted) {
    tracking.track('signup-retried', {
      subdomain,
    });
  }
};

const useCreateUser = ({
  onCompleted,
  onError,
  subdomain,
}: UseCreateUserProps) => {
  const [error, setError] = useState<string>('');
  const { t: translate } = useTranslation('logged_out');
  const [hasAttempted, setHasAttempted] = useState<boolean>(false);

  const [mutateCreateUser, { loading }] = useMutation(CREATE_USER_MUTATION, {
    onError: err => {
      setError(translate('login.errors.default_login_error'));
      tracking.track('signup-failed', {
        subdomain,
      });

      trackRetriedAttempts(hasAttempted, subdomain);
      setHasAttempted(true);

      if (onError) {
        onError(err);
      }
    },
    onCompleted: data => {
      trackRetriedAttempts(hasAttempted, subdomain);
      setHasAttempted(true);
      tracking.track('signup-successful', {
        subdomain,
      });
      if (onCompleted) {
        onCompleted(data);
      }
    },
  });

  const createUser = async (variables: CreateUserVariables) => {
    await mutateCreateUser({ variables });
  };

  return {
    createUser,
    hasAttempted,
    error,
    loading,
  };
};

export default useCreateUser;
