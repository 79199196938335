import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { getMSTeamsUserContext, isMSTeams } from 'utils/MSTeams';
import { authentication, HostClientType } from '@microsoft/teams-js';
import { Error } from '@unmind/design-system-components-web';
import { small } from '../../utils';
import {
  LoggedOutScreenTitle,
  LoggedOutScreenSubtitle,
  createTextContentWidth,
  LoggedOutScreenWrapper,
} from '../LoggedOutScreenWrapper';
import { useSubdomainInfo } from '../SignUp/useSubdomainInfo';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import useUniversalLogin from '../UniversalLogin/useUniversalLogin';
import PrimaryButton from '../../Shared/PrimaryButton';
import getSubdomainFromUrl from '../../utils/getSubdomainFromUrl';
import RoutePath from '../../App/RoutePath';
import { tracking } from '../../App/Tracking';
import {
  authenticationErrorStorageCode,
  AuthErrorCode,
  ErrorTranslationKey,
  getAuthErrorMessage,
  MSTeamsAuthPopupErrorMessage,
} from './AuthError';

export type LoginViaSSOProps = RouteComponentProps;

const responsiveButtonStyle = () => css`
  ${small(css`
    padding-left: 22px;
  `)}
`;

const ContinueButton = styled(PrimaryButton)`
  margin-top: ${rem(24)};

  ${small(css`
    margin-top: ${rem(40)};
  `)}
`;

const BoldLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration-line: underline;
  display: inline-flex;
  align-items: center;
  font-weight: 500;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const SSOLoginButton = styled(PrimaryButton)`
  background-color: ${({ theme }) =>
    theme.button.primary.default.backgroundColor};
  border-color: ${({ theme }) => theme.button.primary.default.borderColor};
  border-width: 1px;
  padding-left: 36px;

  ${responsiveButtonStyle()};
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlusOneTextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
  ${createTextContentWidth()};
  font-size: ${rem('12px')};
  line-height: 140%;
  font-weight: 400;
  margin-top: ${rem(24)};
`;

function LoginViaSSO({}: LoginViaSSOProps) {
  const subdomain = getSubdomainFromUrl();

  const {
    authProviderOrganisationId,
    loading: subdomainInfoLoading,
    ssoProviderName,
  } = useSubdomainInfo({
    subdomain,
  });

  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  const urlSearchParams = new URLSearchParams(location.search);
  const { errorCode, errorMessage } = getAuthErrorMessage(urlSearchParams);
  const returnTo = urlSearchParams.get('redirect') || undefined;

  const { loginWithUniversalLogin, loginWithPopupWindow } = useUniversalLogin();

  if (subdomainInfoLoading) {
    return <LoadingIndicator />;
  }

  const handleUserAuthenticationInMSTeams = async (): Promise<void> => {
    try {
      const authenticationResult = await authentication.authenticate({
        isExternal: false,
        url: window.location.origin + RoutePath.MSTeamsSSO,
      });

      if (authenticationResult === 'success') {
        window.location.assign(RoutePath.SSOLoginHome);
      }
    } catch (e) {
      const authenticationError = localStorage.getItem(
        authenticationErrorStorageCode,
      );

      localStorage.removeItem(authenticationErrorStorageCode);

      if (authenticationError) {
        const { errorDescription, authErrorParam } =
          JSON.parse(authenticationError);

        if (errorDescription === 'EMAIL_VERIFICATION_REQUIRED') {
          window.location.assign(
            RoutePath.VerifyAccountOwnershipEmailConfirmation,
          );
        } else {
          window.location.assign(`${RoutePath.LoginWithSSO}${authErrorParam}`);
        }

        return;
      }

      if ((e as Error).message === MSTeamsAuthPopupErrorMessage) {
        window.location.assign(
          `${RoutePath.LoginWithSSO}?error_code=${AuthErrorCode.AUTH_CANCELLED_BY_USER}`,
        );

        return;
      }
    }
  };

  const context = getMSTeamsUserContext();
  let isWeb = false;
  if (context) {
    isWeb = context.app.host.clientType === HostClientType.web;
  }

  return (
    <>
      <LoggedOutScreenWrapper>
        <LoggedOutScreenTitle>
          {ssoProviderName
            ? translate('sso.access_unmind_header.sso_provider', {
                sso_provider: ssoProviderName,
              })
            : translate('sso.access_unmind_header.default')}
        </LoggedOutScreenTitle>
        <LoggedOutScreenSubtitle>
          {translate('sso.access_unmind_subtitle')}
        </LoggedOutScreenSubtitle>
        <ContinueButton
          data-testid="sso-sign-in-button"
          label={
            <ButtonTextWrapper>
              {ssoProviderName
                ? translate('sso.continue_button.sso_provider_label', {
                    sso_provider: ssoProviderName,
                  })
                : translate('sso.continue_button.default_label')}
            </ButtonTextWrapper>
          }
          aria-label={translate('sso.continue_button.aria_label')}
          onClick={async () => {
            tracking.track('sso-continue-sso-clicked', { subdomain });

            if (isMSTeams()) {
              // Due to a bug on web we've had to reintroduce the auth0 popup flow for SSO
              // details here: https://unmind.atlassian.net/browse/ENG-12806
              if (isWeb) {
                await loginWithPopupWindow({ authProviderOrganisationId });
              } else {
                await handleUserAuthenticationInMSTeams();
              }
            } else {
              await loginWithUniversalLogin({
                authProviderOrganisationId: authProviderOrganisationId,
                returnTo,
              });
            }
          }}
        />
        <PlusOneTextWrapper data-testid="sso-plus-one-text">
          <Trans
            t={translate}
            i18nKey="sso.plus_one_footer_help_text"
            defaults="Been given guest access to Unmind by a friend or family member? <colour>Sign in with your email address and password</colour>."
            components={{
              colour: (
                <BoldLink
                  to={{ pathname: RoutePath.LoginWithCredentials }}
                  onClick={() => {
                    tracking.track('sso-plus-one-signin-link-clicked', {
                      subdomain,
                    });
                  }}
                />
              ),
            }}
          />
        </PlusOneTextWrapper>
        {errorCode && (
          <Error
            errorText={translate(errorMessage as ErrorTranslationKey)}
            showIcon
          />
        )}
      </LoggedOutScreenWrapper>
    </>
  );
}

export default LoginViaSSO;
