import React from 'react';
import { css, styled } from 'styles';
import { useTranslation } from 'react-i18next';
import { small } from '../utils';
import logoWithName from '../Shared/Assets/unmind_logo_with_name.svg';

const Logo = styled.img`
  flex-shrink: 0;
  z-index: 1;
  position: absolute;
  top: 40px;
  height: 20px;

  ${small(css`
    height: 30px;
  `)}

  // Target IE11 to make sure logo takes up 100% height.
    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    height: 100%;
  }
`;

type UnauthenticatedHeaderLogoProps = {
  shouldDisplay: boolean;
};

const UnauthenticatedHeaderLogo = (props: UnauthenticatedHeaderLogoProps) => {
  const { t: translate } = useTranslation('shared');

  if (!props.shouldDisplay) {
    return <></>;
  }

  return (
    <Logo
      src={logoWithName}
      alt={translate('navigation.unauthenticated_header_logo.alt_text')}
    />
  );
};

export default UnauthenticatedHeaderLogo;
