import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmailOnlyFormFields } from 'Shared/Form/Formik';
import { tracking } from 'App/Tracking';
import RoutePath from 'App/RoutePath';
import { useHistory } from 'react-router';
import getSubdomainFromUrl, {
  isClientSubdomain,
} from '../../../utils/getSubdomainFromUrl';
import {
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import { useSubdomainInfo } from '../useSubdomainInfo';
import EmailInputForm from './EmailInputForm';
import { useRequestPasswordReset } from './useRequestPasswordReset';

const ForgotPasswordForm = () => {
  const formikRef = useRef<Formik<EmailOnlyFormFields>>(null);
  const { t: translate } = useTranslation('logged_out', {
    keyPrefix: 'login.forms.forgot_password',
  });

  const { t: sharedTranslate } = useTranslation('shared', {
    keyPrefix: 'errors',
  });
  const subdomainFromUrl = getSubdomainFromUrl();
  const [error, setError] = useState<string | undefined>();
  const history = useHistory();
  const clientSubdomain = isClientSubdomain(subdomainFromUrl);

  const { groupId } = useSubdomainInfo({
    subdomain: subdomainFromUrl,
    skip: !clientSubdomain,
  });

  const onErrorCallback = (errorType: string) => {
    const errorMessage =
      errorType === 'RateLimitError'
        ? translate('errors.rate_limit_exceeded')
        : sharedTranslate('messages.failed');

    return setError(errorMessage);
  };

  const onSuccessCallback = () => {
    tracking.track('password-reset', { subdomain: subdomainFromUrl });

    history.push(RoutePath.ForgotPasswordEmailConfirmation, {
      email: formikRef.current?.state.values.emailInput,
      pathTo: RoutePath.ForgotPassword,
    });
  };

  const { requestPasswordReset, loading } = useRequestPasswordReset({
    onErrorCallback,
    subdomainless: !clientSubdomain,
    onSuccessCallback,
  });

  const onSubmit = async ({ emailInput: email }: EmailOnlyFormFields) => {
    await requestPasswordReset({
      variables: { email, ...(groupId && { groupId }) },
    });
  };

  const emailLabel = translate('email_label');
  const submitLabel = translate('submit_button.label');

  return (
    <LoggedOutScreenWrapper>
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('title')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="form-subtitle">
        {translate('subtitle')}
      </LoggedOutScreenSubtitle>
      <EmailInputForm
        formikRef={formikRef}
        inputLabel={emailLabel}
        submitLabel={submitLabel}
        onSubmit={onSubmit}
        loading={loading}
        setError={setError}
        error={error}
      ></EmailInputForm>
    </LoggedOutScreenWrapper>
  );
};
export default ForgotPasswordForm;
