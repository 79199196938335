import { BodyText } from '@unmind/design-system-components-web';
import { rem } from 'polished';
import React, { useEffect, useRef } from 'react';
import { FullScreenTakeover } from 'Shared/FullScreenTakeover';
import SecondaryButton from 'Shared/SecondaryButton';
import styled, { css } from 'styled-components';
import { medium, small } from 'utils';
import {
  ANDROID_APP_STORE_BADGE_PREFIX,
  IOS_APP_STORE_BADGE_PREFIX,
} from 'i18n/assetPrefixes';
import { useTranslatedAssets } from 'i18n/useTranslatedAssets';
import { useTranslation } from 'react-i18next';
import { tracking } from 'App/Tracking';
import { getUserLocale } from 'i18n/getUserLocale';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import getPlatform from './getPlatform';
import QRCode from './assets/unmind_qr_code.png';
import MobilePreview from './assets/mobile_preview.png';

export const Link = styled.a`
  text-decoration: none;
  margin-bottom: ${rem(5)};
  &:not(:first-of-type) {
    margin-left: ${rem(16)};
  }
`;
export const MobilePreviewImage = styled.img.attrs({
  alt: '',
})`
  width: 298.05px;
  height: 645px;
  margin-bottom: 24px;
  display: none;

  ${medium(css`
    display: block;
  `)}
`;

const QRCodeImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  display: none;

  ${small(css`
    display: block;
  `)}
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-top: ${rem(1)} solid ${({ theme }) => theme.colors.border.secondary};
`;

const GoogleBadgeWrapper = styled.img`
  width: ${rem(160)};
  height: auto;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
`;

const ContinueButton = styled(SecondaryButton)`
  margin-top: ${rem(30)};
`;

const Title = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize24],
  color: theme.colors.text.primary,
  weight: theme.typography.fontWeights.semiBold,
}))`
  margin-bottom: ${rem(24)};
`;

const DownloadInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${rem(24)};
  width: ${rem(380)};
  margin-right: 0;
  margin-left: ${rem(24)};

  ${small(css`
    margin-right: ${rem(240)};
  `)}
`;

const DownloadLinksContainer = styled.div`
  display: flex;
`;
const AppleBadgeWrapper = styled.img`
  width: ${rem(144)};
  height: auto;
`;
const Text = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
}))`
  margin-bottom: ${rem(40)};
`;

export const StoreLink = ({
  downloadLinkRef,
}: {
  downloadLinkRef: React.RefObject<HTMLAnchorElement>;
}) => {
  const { t: translate } = useTranslation('shared');
  const platform = getPlatform(window.navigator);
  const {
    [ANDROID_APP_STORE_BADGE_PREFIX]: androidStoreBadge,
    [IOS_APP_STORE_BADGE_PREFIX]: iosStoreBadge,
  } = useTranslatedAssets(
    [IOS_APP_STORE_BADGE_PREFIX, ANDROID_APP_STORE_BADGE_PREFIX],
    'svg',
  );

  if (androidStoreBadge === undefined || iosStoreBadge === undefined) {
    return null;
  }

  const trackGoToStore = (store: string) => {
    tracking.track('mobile-web-overlay-clicked', {
      action: 'go-to-store',
      store,
    });
  };

  const PlayStoreButton = () => (
    <Link
      ref={downloadLinkRef}
      href={translate('store_link.google.link')}
      onClick={() => {
        trackGoToStore(platform);
      }}
      aria-label={translate('store_link.google.a11y_label')}
      target="_blank"
    >
      <GoogleBadgeWrapper
        src={androidStoreBadge}
        alt={translate('store_link.google.image_alt')}
      />
    </Link>
  );

  const AppStoreButton = () => (
    <Link
      ref={downloadLinkRef}
      href={translate('store_link.apple.link')}
      onClick={() => {
        trackGoToStore(platform);
      }}
      aria-label={translate('store_link.apple.a11y_label')}
      target="_blank"
    >
      <AppleBadgeWrapper
        src={iosStoreBadge}
        alt={translate('store_link.apple.image_alt')}
      />
    </Link>
  );

  switch (platform) {
    case 'apple': {
      return <AppStoreButton />;
    }
    case 'android': {
      return <PlayStoreButton />;
    }
    case 'desktop': {
      return (
        <DownloadLinksContainer>
          <PlayStoreButton /> <AppStoreButton />
        </DownloadLinksContainer>
      );
    }
    default:
      return null;
  }
};

const ConditionalMobilePreviewImage = () => {
  const isNovaUser = useFeatureFlag(FEATURE_FLAGS.ENABLE_CHATBOT);

  const userLocale = getUserLocale();

  const isEnglishLocale = userLocale.startsWith('en');

  const showMobileImagePreview = isEnglishLocale && isNovaUser;
  if (!showMobileImagePreview) {
    return null;
  }

  return <MobilePreviewImage src={MobilePreview} />;
};

interface DownloadMobileAppOverlayProps {
  onSkip(): void;
}

export const DownloadMobileAppOverlay = ({
  onSkip,
}: DownloadMobileAppOverlayProps) => {
  const { t: translate } = useTranslation('shared');
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    tracking.track('mobile-download-overlay-shown', {
      platform: getPlatform(window.navigator),
    });
  }, []);

  return (
    <FullScreenTakeover shown={true} showCloseButton={false}>
      <Container>
        <DownloadInformationContainer>
          <QRCodeImage src={QRCode} />
          <Title>{translate('mobile_app_download_overlay.title')}</Title>
          <Text>{translate('mobile_app_download_overlay.description')}</Text>
          <StoreLink downloadLinkRef={downloadLinkRef} />
          <ContinueButton
            label={translate('mobile_app_download_overlay.skip_button')}
            onClick={onSkip}
            data-testid="continue-button"
          />
        </DownloadInformationContainer>
        <ConditionalMobilePreviewImage />
      </Container>
    </FullScreenTakeover>
  );
};
