import { Formik } from 'formik';
import React from 'react';
import { styled } from 'styles';
import { useTranslation } from 'react-i18next';
import { SecureTextInput, Error } from '@unmind/design-system-components-web';
import {
  getValidationResult,
  getValidations,
  isPasswordValid,
} from '../../../Shared/ValidatorMatch/PasswordValidator';
import {
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import {
  StyledForm,
  StyledFormFieldLabel,
  StyledSubmitButton,
} from './CommonFormStyledComponents';

const FormWrapper = styled.div`
  flex: 1;
`;

export interface EnterPasswordFormProps {
  onSubmit(password: string): Promise<void>;
  error?: string;
  subdomain?: string;
  loading?: boolean;
  name?: string;
  showCheckboxes?: boolean;
}

const EnterPasswordForm = ({
  onSubmit,
  error,
  loading,
}: EnterPasswordFormProps) => {
  const { t: translate } = useTranslation([
    'logged_out',
    'shared',
    'password_validation',
  ]);

  return (
    <LoggedOutScreenWrapper dataTestId="set-password-page">
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('logged_out:sign_up.forms.password_form.heading')}
      </LoggedOutScreenTitle>
      <Formik
        validate={values => {
          if (!isPasswordValid(values.password)) {
            return {
              password: translate(
                'shared:forms.validate_fields.invalid_password.text',
              ),
            };
          }

          return {};
        }}
        initialValues={{
          password: '',
        }}
        onSubmit={async ({ password }) => {
          await onSubmit(password);
        }}
      >
        {({ isValid, values, setFieldValue }) => {
          const validations = getValidations(
            getValidationResult(values.password),
          );

          return (
            <StyledForm>
              <FormWrapper>
                <StyledFormFieldLabel htmlFor="password">
                  {translate(
                    'logged_out:sign_up.forms.password_form.password_field.label',
                  )}
                </StyledFormFieldLabel>
                <div>
                  <SecureTextInput
                    data-testid="password-input"
                    name="password"
                    placeholder={translate(
                      'logged_out:sign_up.forms.password_form.password_field.label',
                    )}
                    aria-label={translate(
                      'logged_out:sign_up.forms.password_form.password_field.label',
                    )}
                    validityRequirements={validations.map(validation => ({
                      label: validation.label,
                      match: validation.valid,
                    }))}
                    onChange={e => setFieldValue('password', e.target.value)}
                    id={'password'}
                    a11yLabels={{
                      toggleButton: '',
                      contentsVisibleAlert: '',
                      contentsHiddenAlert: '',
                    }}
                  />
                </div>
              </FormWrapper>
              <div>
                <StyledSubmitButton
                  data-testid="submit-button"
                  type="submit"
                  loading={loading}
                  label={translate(
                    'logged_out:sign_up.forms.password_form.submit_button.label',
                  )}
                  disabled={!isValid}
                />
                {error ? <Error errorText={error} showIcon /> : null}
              </div>
            </StyledForm>
          );
        }}
      </Formik>
    </LoggedOutScreenWrapper>
  );
};

export default EnterPasswordForm;
