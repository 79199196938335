import React from 'react';
import { IconProps } from '../IconProps';
const SvgNavTalkCalendar = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg
    fill="none"
    viewBox="1.34 1.34 17.32 17.32"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M3.303 3.973a1.34 1.34 0 00-1.34 1.34v11.384a1.34 1.34 0 001.34 1.339h13.393a1.34 1.34 0 001.34-1.34V5.314a1.34 1.34 0 00-1.34-1.34H3.303zM1.964 8.66h16.071M5.313 1.964v4.018M14.688 1.964v4.018"
      stroke={primaryColor}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgNavTalkCalendar;
