import React from 'react';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from 'LoggedOut/LoggedOutScreenWrapper';
import { EmailAddress } from '../EmailConfirmation/EmailConfirmation.styled';
import {
  withSubdomainFromUrl,
  ChildProps as SubdomainFromUrlProps,
} from '../withSubdomainFromUrl';
import RoutePath from '../../App/RoutePath';
import {
  EmailConfirmationFooter,
  EmailConfirmationHelperText,
} from '../EmailConfirmation/EmailConfirmationShared';

const BoldLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration-line: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;
export interface MagicLinkConfirmationProps
  extends SubdomainFromUrlProps,
    RouteComponentProps {}

export function MagicLoginConfirmation(props: MagicLinkConfirmationProps) {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  const email =
    props.location.state && props.location.state.email
      ? props.location.state.email
      : translate('login.magic_login_confirmation.email_placeholder_text');

  return (
    <LoggedOutScreenWrapper>
      <LoggedOutScreenTitle>
        {translate('login.magic_login_confirmation.heading')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle>
        {Trans({
          t: translate,
          i18nKey: 'login.magic_login_confirmation.subtitle',
          values: { email },
          components: { email_address: <EmailAddress /> },
        })}
      </LoggedOutScreenSubtitle>
      <EmailConfirmationHelperText>
        {Trans({
          t: translate,
          i18nKey: 'login.magic_login_confirmation.helper_text',
          components: {
            confirmation_link: (
              <BoldLink
                to={RoutePath.MagicLogin}
                data-testid="try-again-link"
              />
            ),
          },
        })}
      </EmailConfirmationHelperText>
      <EmailConfirmationFooter userEmailAddress={email} />
    </LoggedOutScreenWrapper>
  );
}

export default compose<MagicLinkConfirmationProps, RouteComponentProps>(
  withSubdomainFromUrl,
)(MagicLoginConfirmation);
