import React, { ReactNode } from 'react';
import { ConditionalMobileDownloadOverlay } from 'App/MobileDownloadOverlay/ConditionalMobileDownloadOverlay';
import { useLocation } from 'react-router';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import LocalAuthStatus from '../App/Auth/LocalAuthStatus';
import AuthenticatedPage from '../Navigation/AuthenticatedPage';
import UnauthenticatedPage from '../Navigation/UnauthenticatedPage';

const PageWithNavigation = ({ children }: { children?: ReactNode }) => {
  const { state: locationState } = useLocation();
  const isFirstLogin =
    history.state?.state?.isFirstLogin || locationState?.isFirstLogin;

  const shouldShowMobileDownloadOverlay =
    useFeatureFlag(
      FEATURE_FLAGS.ENTERPRISE_READINESS_SHOW_MOBILE_DOWNLOAD_ON_FIRST_LOGIN,
    ) && isFirstLogin;

  return (
    <LocalAuthStatus>
      {({ state, isAuthenticated }) => (
        <>
          {state === 'READY' && isAuthenticated && (
            <AuthenticatedPage>{children}</AuthenticatedPage>
          )}
          {!isAuthenticated && (
            <UnauthenticatedPage>{children}</UnauthenticatedPage>
          )}
          <ConditionalMobileDownloadOverlay
            showOverlayOverride={shouldShowMobileDownloadOverlay}
          />
        </>
      )}
    </LocalAuthStatus>
  );
};

export default PageWithNavigation;
