import { Breakpoints } from '@unmind/design-system-theme';
import { LocationDescriptorObject } from 'history';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, mapProps } from 'recompose';
import { useTheme } from 'styles';
import { ManagerReporting } from 'Admin/Dashboard/ManagerReporting';
import ManageUsers from 'Admin/ManageUsers';
import { tracking } from '../../App/Tracking';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import { PageTitle } from '../../Shared/PageTitle';
import Tabs from '../../Shared/Tabs';
import useViewportWidth from '../../utils/useViewportWidth';
import { FilterProvider } from '../Dashboard/FilterState';
import { TAB_HASHES, TabHash } from '../constants';
import { AdminTabType } from '../types';
import WellbeingContent from '../Dashboard/WellbeingContent';
import ActivityContent from '../Dashboard/ActivityContent';
import DateRangeHeading from '../Dashboard/DateRangeHeading';
import { useOrganisationEntitlements } from '../../Shared/Entitlement';
import Route404 from '../../App/Router/Route404';
import { Page, Main, TitleContainer } from './AdminPage.styled';

interface AdminProps {
  urlTabHash: TabHash;
  updateHistory(location: LocationDescriptorObject): void;
}

interface TabType {
  title: string;
  hash: TabHash;
  body: React.ReactElement;
  onClick?(): void;
}

const trackTabEvent = (tabName: string) =>
  tracking.track(`admin-dashboard-tab-clicked`, {
    adminTabName: tabName,
  });

const DASHBOARD_HASH_TO_TYPE: { [key in TabHash]: AdminTabType } = {
  [TAB_HASHES.ACTIVITY]: 'activity',
  [TAB_HASHES.WELLBEING]: 'wellbeing',
  [TAB_HASHES.MANAGER_REPORTING]: 'managerReporting',
  [TAB_HASHES.MANAGE_USERS]: 'manageUsers',
};

const baseTabsTemplate: TabType[] = [
  {
    title: 'Uptake and Activity',
    hash: TAB_HASHES.ACTIVITY,
    body: <ActivityContent />,
    onClick: () => trackTabEvent('activity'),
  },
  {
    title: 'Employee Wellbeing Scores',
    hash: TAB_HASHES.WELLBEING,
    body: <WellbeingContent />,
    onClick: () => trackTabEvent('wellbeing'),
  },
  {
    title: 'Manage Users',
    hash: TAB_HASHES.MANAGE_USERS,
    body: <ManageUsers />,
    onClick: () => trackTabEvent('manage-users'),
  },
];

const Admin = ({ urlTabHash, updateHistory }: AdminProps) => {
  const { colors } = useTheme();
  const viewportWidth = useViewportWidth();

  const { loading: entitlementLoading, entitlements } =
    useOrganisationEntitlements([
      { entitlement: 'standaloneIndex', default: true },
      { entitlement: 'lineManagerFeatures', default: false },
    ]);

  const [activeTab, setActiveTab] = useState<TabHash>(urlTabHash);

  useEffect(() => {
    setActiveTab(urlTabHash);
  }, [urlTabHash]);

  if (entitlementLoading) {
    return <LoadingIndicator />;
  }

  // Client admins with Standalone Index entitlement should not have access to admin dashboard
  // As they should not be able to invite employees to the main app.
  if (entitlements.standaloneIndex) {
    return <Route404 />;
  }

  const tabsTemplate = [
    ...baseTabsTemplate,
    /**
     * Only show manager reporting to organisations
     * with the line manager features entitlement enabled
     */
    ...(entitlements.lineManagerFeatures
      ? [
          {
            title: 'Manager Reporting',
            hash: TAB_HASHES.MANAGER_REPORTING,
            body: <ManagerReporting />,
            onClick: () => trackTabEvent('manager-reporting'),
          },
        ]
      : []),
  ];

  const shouldCenterTabs = viewportWidth < Breakpoints.S;
  const isInsightsTabSelected = activeTab !== TAB_HASHES.MANAGE_USERS;

  const tabsConfig = tabsTemplate.map(({ title, hash, onClick }) => ({
    title,
    hash,
    onClick: () => {
      setActiveTab(hash);
      if (onClick) {
        onClick();
      }
    },
  }));
  const tabsBody = tabsTemplate.map(({ body, hash }) => ({ body, hash }));
  const dateRangeHeading = isInsightsTabSelected ? <DateRangeHeading /> : null;

  return (
    <FilterProvider adminTabType={DASHBOARD_HASH_TO_TYPE[activeTab]}>
      <Page>
        <Main>
          <TitleContainer>
            <PageTitle subheading={dateRangeHeading}>Admin</PageTitle>
          </TitleContainer>
          <Tabs
            activeTab={activeTab}
            tabs={tabsConfig}
            updateHistory={updateHistory}
            align={shouldCenterTabs ? 'center' : 'left'}
            bgColor={colors.background.primary}
          >
            {tabsBody.map(({ body }) => (
              <>{body}</>
            ))}
          </Tabs>
        </Main>
      </Page>
    </FilterProvider>
  );
};

export default compose<AdminProps, Record<string, unknown>>(
  withRouter,
  mapProps(({ location, history }: RouteComponentProps) => ({
    urlTabHash: location.hash || TAB_HASHES.ACTIVITY,
    updateHistory: history.push,
  })),
)(Admin);
