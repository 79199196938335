import { Toast } from '@unmind/design-system-components-web';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { defaultLightTheme, ThemeProvider } from 'styles';
import { Clarity } from 'Talk/components/Clarity';
import { initialiseMSTeamsApp } from 'utils/MSTeams';
import createFeatureFlagProvider from '../flags/createFeatureFlagProvider';
import { updateDbUserLocaleIfNeeded } from '../i18n/updateDbUserLocaleIfNeeded';
import UniversalLoginProvider from '../LoggedOut/UniversalLogin/UniversalLoginProvider';
import AuthorizedApolloProvider from './Auth/AuthorizedApolloProvider';
import LocalAuthStatus from './Auth/LocalAuthStatus';
import RefreshAuthToken from './Auth/RefreshAuthToken';
import Braze from './braze/Braze';
import { openBrazeSession } from './braze/identifyBrazeUser';
import { initialiseBrazeInAppMessaging } from './braze/initialiseBrazeInAppMessaging';
import { DatadogSetup } from './Datadog';
import GlobalStyle from './GlobalStyle';
import { logDevOnlyInfo } from './logging';
import Router from './Router';
import Route404 from './Router/Route404/index';
import {
  initialiseAmplitude,
  tracking,
  trackUtmParamsIfAvailable,
} from './Tracking';

const Authenticating = () => null;

initialiseAmplitude();

tracking.track('Application Opened');

const { pathname, search } = window.location;
trackUtmParamsIfAvailable({ pathname, search });

const FeatureFlagProvider = createFeatureFlagProvider({
  key: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY as string,
});

void initialiseMSTeamsApp();

const App = () => (
  <HelmetProvider>
    <BrowserRouter>
      <FeatureFlagProvider>
        <ThemeProvider theme={defaultLightTheme}>
          <UniversalLoginProvider>
            <AuthorizedApolloProvider>
              <Braze>
                <>
                  <DatadogSetup />
                  <Toast />
                  <LocalAuthStatus>
                    {({ isAuthenticated, isUsingSSO, state }) => {
                      const token = localStorage.getItem('token');
                      if (state === 'READY' && isAuthenticated && token) {
                        /**
                         * This will trigger when a user signs in,
                         * or an authenticated user opens/refreshes the app
                         */
                        logDevOnlyInfo(`isAuthenticated: ${isAuthenticated}`);
                        // Braze InApp messaging must be initialised before openBrazeSession();
                        initialiseBrazeInAppMessaging();
                        openBrazeSession();
                        // void used here so as not to block execution, errors appropriately handled within
                        // eslint-disable-next-line no-void

                        // disable this for SSO, for now:
                        if (!isUsingSSO) {
                          void updateDbUserLocaleIfNeeded();
                        }

                        return <RefreshAuthToken />;
                      }

                      return <>{null}</>;
                    }}
                  </LocalAuthStatus>
                  <GlobalStyle />
                  <Clarity />
                  <Router
                    renderIfAuthenticating={Authenticating}
                    renderIfUnauthorized={Route404}
                  />
                </>
              </Braze>
            </AuthorizedApolloProvider>
          </UniversalLoginProvider>
        </ThemeProvider>
      </FeatureFlagProvider>
    </BrowserRouter>
  </HelmetProvider>
);

export default App;
