import { Formik } from 'formik';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecureTextInput, Error } from '@unmind/design-system-components-web';
import { styled } from 'styles';
import { useSubdomainInfo } from 'LoggedOut/SignUp/useSubdomainInfo';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import RoutePath from 'App/RoutePath';
import { useHistory } from 'react-router';
import {
  getValidationResult,
  getValidations,
  isPasswordValid,
} from '../../Shared/ValidatorMatch/PasswordValidator';
import {
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../LoggedOutScreenWrapper';
import {
  StyledForm,
  StyledSubmitButton,
} from '../SignUp/Forms/CommonFormStyledComponents';
import { useResetPassword } from './useResetPassword';

const FormWrapper = styled.div`
  flex: 1;
`;

type Error = {
  newPassword: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const { t: translate } = useTranslation([
    'logged_out',
    'shared',
    'password_validation',
  ]);
  const { groupId } = useSubdomainInfo({
    subdomain: getSubdomainFromUrl(),
  });
  const history = useHistory();
  const [passwordResetError, setPasswordResetError] = useState<
    string | undefined
  >();
  const params = queryString.parse(location.search);
  const { resetPassword, error, data } = useResetPassword();

  useEffect(() => {
    if (data) {
      if (data?.resetPassword) {
        history.push(RoutePath.Login);
      } else {
        setPasswordResetError(translate('shared:errors.messages.failed'));
      }
    }
  }, [data]);

  const formError = error || passwordResetError;

  return (
    <LoggedOutScreenWrapper dataTestId="reset-password-page">
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('login.forms.reset_password.heading')}
      </LoggedOutScreenTitle>
      <Formik
        initialValues={{ newPassword: '', confirmPassword: '' }}
        validate={values => {
          if (
            isPasswordValid(values.newPassword) &&
            values.newPassword === values.confirmPassword
          ) {
            return {};
          }

          return {
            newPassword: isPasswordValid(values.newPassword)
              ? undefined
              : translate('login.forms.reset_password.errors.invalid_password'),
            confirmPassword:
              values.newPassword === values.confirmPassword
                ? undefined
                : translate(
                    'login.forms.reset_password.errors.passwords_not_matching',
                  ),
          };
        }}
        onSubmit={async values =>
          resetPassword({
            variables: {
              passwordToken: params.passwordToken,
              password: values.newPassword,
              groupId,
            },
          })
        }
        isInitialValid={false}
      >
        {({
          values,
          errors,
          setFieldValue,
          isValid,
          touched,
          setTouched,
          isValidating,
        }) => {
          const validations = getValidations(
            getValidationResult(values.newPassword),
          );

          return (
            <StyledForm>
              <FormWrapper>
                <SecureTextInput
                  data-testid="new-password-input"
                  name="newPassword"
                  placeholder={translate(
                    'login.forms.reset_password.new_password_placeholder',
                  )}
                  validityRequirements={validations.map(validation => ({
                    label: validation.label,
                    match: validation.valid,
                  }))}
                  onChange={e => {
                    setTouched({});
                    setFieldValue('newPassword', e.target.value);
                  }}
                  value={values.newPassword}
                  errorText={
                    errors.newPassword && touched.newPassword
                      ? values.newPassword === ''
                        ? translate(
                            'login.forms.reset_password.errors.password_required',
                          )
                        : translate(
                            'login.forms.reset_password.errors.invalid_password',
                          )
                      : undefined
                  }
                  onBlur={() => {
                    setTouched({ newPassword: true }); // we don't set confirmPassword to false yet so the user gets the chance to enter the confirm password
                  }}
                  id="newPassword"
                  a11yLabels={{
                    toggleButton: '',
                    contentsVisibleAlert: '',
                    contentsHiddenAlert: '',
                  }}
                  additionalText={{
                    label: translate(
                      'login.forms.reset_password.new_password_placeholder',
                    ),
                  }}
                />
                <SecureTextInput
                  data-testid="confirm-password-input"
                  name="confirmPassword"
                  placeholder={translate(
                    'login.forms.reset_password.confirm_password_placeholder',
                  )}
                  onChange={e => {
                    setTouched({});
                    setFieldValue('confirmPassword', e.target.value);
                  }}
                  onBlur={() => {
                    setTouched({ confirmPassword: true, newPassword: true }); // this is so if the passwords match but they're invalid we see that error
                  }}
                  value={values.confirmPassword}
                  id="confirmPassword"
                  a11yLabels={{
                    toggleButton: '',
                    contentsVisibleAlert: '',
                    contentsHiddenAlert: '',
                  }}
                  errorText={
                    errors.confirmPassword && touched.confirmPassword
                      ? translate(
                          'login.forms.reset_password.errors.passwords_not_matching',
                        )
                      : undefined
                  }
                  additionalText={{
                    label: translate(
                      'login.forms.reset_password.confirm_password_placeholder',
                    ),
                  }}
                />
              </FormWrapper>

              {formError && (
                <Error
                  errorText={translate(
                    'login.forms.reset_password.errors.form_error',
                  )}
                  showIcon
                />
              )}
              <StyledSubmitButton
                data-testid="submit-button"
                type="submit"
                loading={false}
                label={translate(
                  'login.forms.reset_password.submit_button.label',
                )}
                disabled={!isValid && !isValidating}
              />
            </StyledForm>
          );
        }}
      </Formik>
    </LoggedOutScreenWrapper>
  );
};

export default ResetPassword;
