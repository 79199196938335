import React from 'react';
import { styled, ThemeInterface } from 'styles';
import { HeadingText } from '../../../Shared/Typography';
import SadFace from './assets/404.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SadFaceIllustration = styled.img.attrs({
  alt: '',
})`
  width: 220px;
  height: auto;
  margin-bottom: 24px;
`;

const PageTitle = styled(HeadingText).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    level: 1,
    sizes: [
      theme.typography.fontSizes.fontSize24,
      theme.typography.fontSizes.fontSize28,
    ],
    align: 'center',
  }),
)`
  justify-content: center;
  align-items: center;
  display: flex;
`;

interface Wrapper404Props {
  title: string;
}

class Wrapper404 extends React.Component<Wrapper404Props> {
  render() {
    const { title, children } = this.props;

    return (
      <PageContainer>
        <SadFaceIllustration src={SadFace} />
        <PageTitle>{title}</PageTitle>
        {children}
      </PageContainer>
    );
  }
}

export default Wrapper404;
