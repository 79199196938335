import { talkEventsQuery_unmindEvents_edges_node as Event } from 'Services/BeGateway/Talk/__generated__/talkEventsQuery';

const getMeetURL = (event: Event) => {
  switch (event.sessionType) {
    case 'zoom':
      return event.zoomMeetingDetails?.url;
    case 'unmind_meet':
      return `${process.env.REACT_APP_UNMIND_MEET_URL}/lobby/m/${event.session?.externalRef}/${event.session?.password}`;
    default:
      return `${process.env.REACT_APP_FRANKIE_MEET_URL}/unmind/s/lobby/${event.session?.externalRef}/${event.session?.password}`;
  }
};

export default getMeetURL;
