import React, { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { styled } from 'styles';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SecureTextInput, Error } from '@unmind/design-system-components-web';
import { tracking } from 'App/Tracking';
import getSubdomainFromUrl from 'utils/getSubdomainFromUrl';
import { rem } from 'polished';
import {
  LoggedOutScreenWrapper,
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
} from '../LoggedOutScreenWrapper';
import { StyledSubmitButton } from '../SignUp/Forms/CommonFormStyledComponents';
import RoutePath from '../../App/RoutePath';
import { LocalGeneralError } from '../../Shared/AlertView';
import { BodyText } from '../../Shared/Typography';
import {
  verifyAccountOwnership,
  verifyAccountOwnershipVariables,
} from './__generated__/verifyAccountOwnership';

const ObfuscatedEmailText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize18],
}))`
  margin-bottom: 30px;
`;

const ForgotPasswordLinkContainer = styled.div`
  margin-top: ${rem(24)};
`;

const ForgotPasswordLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

interface FormFields {
  password: string;
}

const VERIFY_ACCOUNT_OWNERSHIP_MUTATION = gql`
  mutation verifyAccountOwnership(
    $userId: String!
    $customIdentifier: String!
    $auth0UserId: String!
    $auth0State: String!
    $password: String!
  ) {
    verifyAccountOwnership(
      userId: $userId
      customIdentifier: $customIdentifier
      auth0UserId: $auth0UserId
      auth0State: $auth0State
      password: $password
    )
  }
`;

const VerifyAccountOwnership = () => {
  const [error, setError] = useState<string>('');

  const { t: translate } = useTranslation(['logged_out', 'shared']);

  const urlSearchParams = new URLSearchParams(location.search);
  const userId = urlSearchParams.get('unmind_user_id');
  const employeeId = urlSearchParams.get('employee_id');
  const auth0UserId = urlSearchParams.get('auth0_user_id');
  const emailHint = urlSearchParams.get('email_hint');
  const auth0State = urlSearchParams.get('state');

  const [verifyAccountOwnershipMutation, { loading }] =
    useMutation<verifyAccountOwnership>(VERIFY_ACCOUNT_OWNERSHIP_MUTATION, {
      onCompleted: async data => {
        const auth0RedirectToken = data.verifyAccountOwnership;
        const resumeAuthFlowTokenParam = `?state=${auth0State}&auth0_redirect_token=${auth0RedirectToken}`;
        const resumeAuthFlowUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/continue${resumeAuthFlowTokenParam}`;
        window.location.assign(resumeAuthFlowUrl);
      },
      onError: err => {
        if (err.message.toLowerCase().indexOf('invalid credentials') > 0) {
          setError(
            translate(
              'logged_out:verify_account_ownership.errors.password_no_match',
            ),
          );
        } else if (
          err.message.toLowerCase().indexOf('account temporarily disabled') > 0
        ) {
          setError(
            translate(
              'logged_out:verify_account_ownership.errors.account_locked',
            ),
          );
        } else {
          setError(translate('shared:errors.messages.unexpected'));
        }
      },
    });

  const isMissingParams =
    !emailHint || !employeeId || !auth0State || !userId || !auth0UserId;
  if (isMissingParams) {
    return <LocalGeneralError />;
  }

  const onSubmit = async (values: FormFields) => {
    void verifyAccountOwnershipMutation({
      variables: {
        userId,
        auth0State,
        auth0UserId,
        customIdentifier: employeeId,
        password: values.password,
      } as verifyAccountOwnershipVariables,
    });
  };

  return (
    <LoggedOutScreenWrapper>
      <LoggedOutScreenTitle>
        {translate('logged_out:verify_account_ownership.title')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle>
        {translate('logged_out:verify_account_ownership.subtitle')}
      </LoggedOutScreenSubtitle>
      <Formik
        initialValues={{
          password: '',
        }}
        onSubmit={onSubmit}
      >
        {({ isValid, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ObfuscatedEmailText
              aria-label={translate(
                'logged_out:verify_account_ownership.email_field.a11y_label',
              )}
            >
              {emailHint}
            </ObfuscatedEmailText>
            <SecureTextInput
              additionalText={{
                label: translate('login.password_field.label'),
              }}
              id="password"
              name="password"
              data-testid="password-input"
              onChange={e => {
                setFieldValue('password', e.target.value);
              }}
              a11yLabels={{
                contentsVisibleAlert: translate(
                  'login.password_field.contents_visible_alert',
                ),
                contentsHiddenAlert: translate(
                  'login.password_field.contents_hidden_alert',
                ),
                toggleButton: translate('login.password_field.toggle_button'),
              }}
              validityRequirements={[]}
            />
            <ForgotPasswordLinkContainer>
              <ForgotPasswordLink
                to={RoutePath.ForgotPassword}
                onClick={() => {
                  tracking.track('forgot-password-clicked', {
                    subdomain: getSubdomainFromUrl(),
                  });
                }}
                data-testid="forgotten-password-link"
              >
                {translate('login.password_field.forgot_password')}
              </ForgotPasswordLink>
            </ForgotPasswordLinkContainer>
            <StyledSubmitButton
              data-testid="verify-account-button"
              type="submit"
              label={translate(
                'logged_out:verify_account_ownership.continue_button.label',
              )}
              aria-label={translate(
                'logged_out:verify_account_ownership.continue_button.a11y_label',
              )}
              disabled={!isValid}
              loading={loading}
            />
            {error ? <Error showIcon errorText={error} /> : null}
          </form>
        )}
      </Formik>
    </LoggedOutScreenWrapper>
  );
};

export default VerifyAccountOwnership;
