import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import { UserDetails } from '../SignUp';
import EmailInputForm, { FormFields } from './EmailInputForm';

export interface EnterCustomIDEmailFormProps {
  onSubmit(details: UserDetails): void;
  isInitialValid?: boolean;
  initialValues?: { emailInput: string; marketingOptIn?: boolean };
  isSSOFlow?: boolean;
}

const EnterCustomIDEmailForm = ({
  isInitialValid = false,
  initialValues = { emailInput: '', marketingOptIn: false },
  isSSOFlow = false,
  ...props
}: EnterCustomIDEmailFormProps) => {
  const { t: translate } = useTranslation('logged_out');

  return (
    <LoggedOutScreenWrapper dataTestId="employee-id-email-page">
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('sign_up.forms.custom_id_email.heading')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="form-subtitle">
        {isSSOFlow
          ? translate('sign_up.forms.custom_id_email.sso_subtitle')
          : translate('sign_up.forms.custom_id_email.subtitle')}
      </LoggedOutScreenSubtitle>
      <EmailInputForm
        inputLabel={translate(
          'sign_up.forms.custom_id_email.email_field.label',
        )}
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={(values: FormFields) => {
          props.onSubmit({
            email: values.emailInput,
          });
        }}
      />
    </LoggedOutScreenWrapper>
  );
};

export default EnterCustomIDEmailForm;
