import { Breakpoints } from '@unmind/design-system-theme';
import times from 'lodash/times';
import { rem } from 'polished';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { css, keyframes } from 'styled-components';

import { styled, ThemeInterface, useTheme } from 'styles';
import { Chevron } from 'icons';

import {
  Fieldset,
  Radio as RadioButton,
} from '@unmind/design-system-components-web';
import { SeriesImpactQuestionnaire_singleSeries_impactQuestionnaire_questions_responseOptions as ImpactQuestionnaireResponseOptions } from 'Series/SingleSeries/SeriesDay/SeriesSegment/Questionnaire/__generated__/SeriesImpactQuestionnaire';
import { ProgressBar, ProgressSize } from 'Shared/Progress/ProgressBar';
import { StylelessButton } from '../Button';
import { extraSmall, small } from '../../utils';
import useViewportWidth from '../../utils/useViewportWidth';
import BodyText from '../Typography/BodyText';
import { createFontStyle } from '../Typography/Base';

export interface RadioQuestionProps {
  className?: string;
  preQuestionText?: string;
  questionText: string;
  minLabel?: string;
  maxLabel?: string;
  questionNumber: number;
  totalQuestions: number;
  inputs?: number;
  selectedIndex?: number;
  onBackButtonClick(): void;
  onAnswerSelected(selectedIndex: number): void;
  onResponseOptionSelected?(response: string): void;
  responseOptions?: ImpactQuestionnaireResponseOptions[];
}

export const Container = styled.div`
  position: relative;
  border: solid ${rem(1)} rgba(13, 3, 0, 0.2)};
  background-color: ${({ theme }) => theme.colors.sheet.background};
  border-radius: ${rem(20)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: ${rem(20)} ${rem(20)};

  ${small(css`
    padding: ${rem(36)} ${rem(32)} ${rem(64)};
  `)}
`;

export const QuestionTextContainer = styled.div`
  min-height: ${rem(120)};
  margin: 0 ${rem(24)};
`;

export const QuestionText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize18,
    theme.typography.fontSizes.fontSize20,
    theme.typography.fontSizes.fontSize22,
    theme.typography.fontSizes.fontSize24,
  ],
}))`
  :focus {
    outline: 0;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: ${rem(36)} ${rem(16)};
  justify-content: center;

  ${extraSmall(css`
    flex-direction: row;
    align-items: center;
    margin: ${rem(36)} 0 0;
  `)}
`;

export const AnswerLabels = styled.div`
  display: flex;
  flex-direction: row;
  padding-horizontal: 20;
  flex-direction: row;
  justify-content: space-between;
  margin: ${rem(8)} 0;
`;

const ResponseOptionsContainer = styled(Fieldset)`
  display: grid;
  grid-gap: 12px;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr;
`;

export const AnswerLabel = styled(BodyText).attrs(({ theme }) => ({
  sizes: [
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize12,
    theme.typography.fontSizes.fontSize14,
  ],
  weight: theme.typography.fontWeights.medium,
}))`
  flex: 1 100%;
  text-transform: uppercase;
`;

export const KeyFrameRadio = keyframes`
  0% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(${4 / 3});
  }
  100% {
    transform: scale(1);
  }
`;

export const RADIO_ANIMATION_MS = 200;

const RadioSelected = css`
  animation: ${KeyFrameRadio} ${RADIO_ANIMATION_MS}ms ease-in-out;
`;

export const RadioList = styled.ol`
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  margin: 0 ${rem(8)} 0 ${rem(4)};
  padding: 0;
  list-style: none;
  align-items: center;

  ${extraSmall(css`
    margin: 0 ${rem(16)} 0 ${rem(16)};
  `)}

  ${small(css`
    margin: 0 ${rem(24)} 0 ${rem(24)};
  `)}

  & > *:first-child {
    margin-left: ${rem(0)};
  }
`;

export const RadioListItem = styled.li<{ inputs: number }>`
  // Target all browsers except ie
  @supports not (-ms-high-contrast: none) {
    width: ${({ inputs }) => `calc((100%) / ${inputs})`};
    aspect-ratio: 1 / 1;
    margin-left: ${rem(8)};
  }

  label {
    width: 100%;
    height: 100%;
  }

  input {
    margin-left: ${rem(0)};
  }

  ${extraSmall(css`
    margin-left: ${rem(8)};
  `)}

  ${small(css`
    margin-left: ${rem(20)};
  `)}
`;

export const Radio = styled(StylelessButton)<{ checked: boolean }>`
  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.primary : theme.colors.background.input};
  border-radius: 50%;
  border: solid 1px;
  border-color: ${({ theme }) => theme.colors.border.secondary};

  // Target all browsers except ie
  @supports not (-ms-high-contrast: none) {
    width: 100%;
    height: 100%;
  }

  width: ${rem(40)};
  height: ${rem(40)};

  ${extraSmall(css`
    width: ${rem(40)};
    height: ${rem(40)};
  `)}

  ${small(css`
    width: ${rem(52)};
    height: ${rem(52)};
  `)}

  ${({ checked }) => (checked ? RadioSelected : '')}

  :hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BackArrow = styled<{ disabled: boolean } & any>(Chevron).attrs(
  ({ theme }: { theme: ThemeInterface }) => ({
    primaryColor: theme.colors.text.secondary,
    secondaryColor: 'transparent',
  }),
)`
  transform: rotate(180deg);
  height: ${rem('10px')};
  margin-left: ${rem('3px')};
  width: ${rem('10px')};
`;

export const BackButton = styled(StylelessButton)`
  position: absolute;
  bottom: ${rem(16)};
  left: ${rem(10)};
  ${({ theme }) =>
    createFontStyle({
      size: theme.typography.fontSizes.fontSize16,
    })}
  color: ${({ theme }) => theme.colors.text.secondary};

  /* TODO: Fix this so it is not dependent on BottomBar */
  z-index: 2;
`;

export const QuestionCounter = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize16],
  weight: theme.typography.fontWeights.medium,
}))`
  position: absolute;
  bottom: ${rem(18)};
  right: ${rem(16)};
`;

export const ID_QUESTION_PROGRESS_BAR = 'bottomBarInProgress';
export const ID_QUESTION = 'question-text';

export const RadioQuestion = ({
  className,
  inputs = 6,
  maxLabel,
  minLabel,
  onAnswerSelected,
  onBackButtonClick,
  onResponseOptionSelected,
  preQuestionText = '',
  questionNumber,
  questionText,
  selectedIndex,
  totalQuestions,
  responseOptions,
}: RadioQuestionProps) => {
  const { t: translate } = useTranslation('shared');

  const theme = useTheme();
  const questionRef = useRef<HTMLDivElement>(null);
  const handleRadioChange = (index: number) => () => {
    onAnswerSelected(index);

    // request from psychology to focus on the next question title,
    // rather than option to avoid any bias or influence
    // see: ENG-973 & ENG-943
    if (questionRef && questionRef.current) {
      questionRef.current.focus();
    }
  };

  if (totalQuestions === 0) {
    return null;
  }

  // we only display progress for current question once user has selected a radio
  const progress =
    ((questionNumber - (selectedIndex !== undefined ? 0 : 1)) /
      totalQuestions) *
    100;

  const questionFullText = `${
    preQuestionText && preQuestionText
  } ${questionText}`;

  const RadioInputs = () => (
    <RadioList role="radiogroup">
      {times(inputs, i => (
        <RadioListItem
          key={i}
          role="presentation"
          data-testid={`radio-button-list-item-${i}`}
          inputs={inputs}
        >
          <Radio
            role="radio"
            checked={selectedIndex === i}
            aria-checked={selectedIndex === i}
            aria-label={translate('radio_question.radio_button.aria_label', {
              index: i,
              length: inputs - 1,
            })}
            onClick={handleRadioChange(i)}
            data-testid={`radio-button-${i}`}
          />
        </RadioListItem>
      ))}
    </RadioList>
  );

  const QuestionInput = () => {
    const viewportWidth = useViewportWidth();
    const isSmallScreen = viewportWidth < Breakpoints.XS;

    if (responseOptions?.length) {
      return (
        <ResponseOptionsContainer>
          {responseOptions.map((option, i) => (
            <RadioButton
              key={option.id}
              labelText={option.label}
              bordered
              checked={selectedIndex === i}
              aria-checked={selectedIndex === i}
              readOnly
              onClick={() => {
                if (!onResponseOptionSelected) {
                  console.error(
                    'onResponseOptionSelected is missing in Question props',
                  );

                  return;
                }

                onResponseOptionSelected(option.id);
              }}
              data-testid={`radio-button-${i}`}
            />
          ))}
        </ResponseOptionsContainer>
      );
    }

    if (isSmallScreen) {
      return (
        <Question>
          <RadioInputs />
          <AnswerLabels>
            <AnswerLabel style={{ textAlign: 'left' }}>{minLabel}</AnswerLabel>
            <AnswerLabel style={{ textAlign: 'right' }}>{maxLabel}</AnswerLabel>
          </AnswerLabels>
        </Question>
      );
    }

    return (
      <Question>
        <AnswerLabel style={{ textAlign: 'right' }}>{minLabel}</AnswerLabel>
        <RadioInputs />
        <AnswerLabel style={{ textAlign: 'left' }}>{maxLabel}</AnswerLabel>
      </Question>
    );
  };

  return (
    <>
      <ProgressBar
        progress={progress}
        borderColor={theme.colors.border.secondary}
        rounded
        size={ProgressSize.large}
      />
      <Container
        className={className}
        data-testid={`question-${questionNumber}`}
      >
        <Content>
          <QuestionTextContainer>
            <QuestionText
              tabIndex={0}
              ref={questionRef}
              data-testid={ID_QUESTION}
            >
              {questionFullText}
            </QuestionText>
          </QuestionTextContainer>
          <QuestionInput />
          {questionNumber !== 1 && (
            <BackButton data-testid="back-button" onClick={onBackButtonClick}>
              <BackArrow /> {translate('radio_question.back_button.label')}
            </BackButton>
          )}
          <QuestionCounter>
            {translate('radio_question.position', {
              current: questionNumber,
              total: totalQuestions,
            })}
          </QuestionCounter>
        </Content>
      </Container>
    </>
  );
};

export default RadioQuestion;
