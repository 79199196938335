import { tracking } from 'App/Tracking';
import React from 'react';
import { useHistory } from 'react-router-dom';
import SecondaryButton from 'Shared/SecondaryButton';
import { getPrivacyPolicyLink } from 'utils';
import { getHelpCenterLink } from 'utils/getHelpCenterLink';
import RoutePath from 'App/RoutePath';
import { useTranslation } from 'react-i18next';
import { AuthTypeEnum } from '__generated__/globalTypes';
import getSubdomainFromUrl, {
  isClientSubdomain,
} from 'utils/getSubdomainFromUrl';
import {
  LoggedOutScreenWrapperFooter,
  HelpIcon,
  HelpLinks,
  LinksContainer,
  LinkText,
  LockIcon,
} from './LoggedOutScreenWrapper.styled';

export const LoggedOutScreenFooter = ({
  customFooter,
  authType,
}: {
  customFooter?: React.ReactNode;
  authType?: AuthTypeEnum;
}) => {
  const hiddenCreateAccountPaths = [
    RoutePath.SignUp,
    RoutePath.SignInToYourOrganisation,
    RoutePath.InvalidInviteToken,
    RoutePath.InviteesLimitReached,
    RoutePath.AdditionalInformationNeeded,
    RoutePath.VerifyAccountOwnership,
    RoutePath.LoginWithSSO,
    RoutePath.FindYourOrganisationConfirmation,
    RoutePath.ResetPassword,
    RoutePath.ForgotPasswordEmailConfirmation,
  ];

  const hideCreateAccountButton = hiddenCreateAccountPaths.some(path =>
    window.location.pathname.startsWith(path),
  );

  const { t: translate } = useTranslation('logged_out');
  const subdomain = getSubdomainFromUrl();
  const clientSubdomain = isClientSubdomain(subdomain);
  const history = useHistory();

  const signUpScreen = !clientSubdomain
    ? `${RoutePath.SignInToYourOrganisation}?redirect=/signup`
    : authType === AuthTypeEnum.AUTH0_SSO
    ? RoutePath.LoginWithSSO
    : RoutePath.SignUp;

  return (
    <>
      {customFooter || (
        <LoggedOutScreenWrapperFooter>
          {!hideCreateAccountButton && (
            <SecondaryButton
              label={translate('footer.create_account_button.label')}
              onClick={() => {
                history.push(signUpScreen);
                tracking.track('create-account-clicked', { subdomain });
              }}
            />
          )}
          <LinksContainer>
            <HelpLinks href={getHelpCenterLink()} data-testid="help-link">
              <HelpIcon />
              <LinkText>{translate('footer.help.label')}</LinkText>
            </HelpLinks>
            <HelpLinks href={getPrivacyPolicyLink()} data-testid="privacy-link">
              <LockIcon />
              <LinkText>{translate('footer.privacy_policy.label')}</LinkText>
            </HelpLinks>
          </LinksContainer>
        </LoggedOutScreenWrapperFooter>
      )}
    </>
  );
};
