import { useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { UserNeedsAdditionalWorkDetails } from './__generated__/UserNeedsAdditionalWorkDetails';

export const USER_NEEDS_ADDITIONAL_INFORMATION = gql`
  query UserNeedsAdditionalWorkDetails {
    user {
      id
      location {
        value
      }
      department {
        value
      }
      group {
        locations {
          id
        }
        departments {
          id
        }
      }
      authenticationMethod
      explicitPrivacyConsentGiven {
        value
      }
    }
  }
`;

export const SHOW_PRIVACY_CONSENT = gql`
  query ShowPrivacyConsentStepOnSSO {
    showPrivacyConsentStepOnSignup {
      showPrivacyConsentStepOnSignup
    }
  }
`;

type UserNeedsAdditionalWorkDetailsReturn = {
  userNeedsAdditionalWorkDetails: boolean | undefined;
  userNeedsExplicitPrivacyConsent: boolean | undefined;
  loading: boolean;
  error: ApolloError | undefined;
};

export const useUserNeedsAdditionalWorkDetails =
  (): UserNeedsAdditionalWorkDetailsReturn => {
    const [userNeedsAdditionalWorkDetails, setUserNeedsAdditionalWorkDetails] =
      useState<boolean | undefined>(undefined);
    const [
      userNeedsExplicitPrivacyConsent,
      setUserNeedsExplicitPrivacyConsent,
    ] = useState<boolean | undefined>(undefined);
    const {
      data: userData,
      error: userError,
      loading: userLoading,
    } = useQuery<UserNeedsAdditionalWorkDetails>(
      USER_NEEDS_ADDITIONAL_INFORMATION,
      {
        fetchPolicy: 'cache-and-network',
      },
    );

    // Wait for user query to finish
    useEffect(() => {
      if (!userLoading && !userError && userData?.user) {
        const {
          user: {
            group,
            location: userLocation,
            department: userDepartment,
            authenticationMethod,
            explicitPrivacyConsentGiven,
          },
        } = userData;

        // only return additional work detals if user is authenticated using SSO
        if (authenticationMethod !== 'SSO') {
          setUserNeedsAdditionalWorkDetails(false);
          setUserNeedsExplicitPrivacyConsent(false);
        } else if (group) {
          const { locations, departments } = group;

          const consentAlreadyGiven =
            explicitPrivacyConsentGiven &&
            explicitPrivacyConsentGiven.value === 'true';

          setUserNeedsExplicitPrivacyConsent(!consentAlreadyGiven);

          const groupHasLocations =
            (locations?.filter(l => l !== null)?.length ?? 0) > 0;
          const groupHasDepartments =
            (departments?.filter(d => d !== null)?.length ?? 0) > 0;

          const userHasLocation = Boolean(userLocation?.value ?? false);
          const userHasDepartment = Boolean(userDepartment?.value ?? false);

          setUserNeedsAdditionalWorkDetails(
            (groupHasLocations && !userHasLocation) ||
              (groupHasDepartments && !userHasDepartment),
          );
        }
      }
    }, [userLoading, userError, userData]);

    return {
      userNeedsAdditionalWorkDetails,
      userNeedsExplicitPrivacyConsent,
      loading: userLoading || userNeedsAdditionalWorkDetails === undefined,
      error: userError,
    };
  };
