import React from 'react';
import { Redirect } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { styled } from 'styles';
import {
  getMSTeamsDevice,
  isMSTeams,
  resolveMSTeamsDeepLink,
} from 'utils/MSTeams';
import { AUTHENTICATED_QUERY } from 'App/Auth/authenticatedQuery';
import { useQuery } from '@apollo/client';
import { PlatformType } from 'App/Tracking/tracking';
import { RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY } from 'utils/MSTeams/MSTeamsHelpers';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import { isClientSubdomain } from 'utils/getSubdomainFromUrl';
import { withSubdomainFromUrl } from '../../LoggedOut/withSubdomainFromUrl';
import LoadingIndicator from '../../Shared/LoadingIndicator';
import RoutePath from '../RoutePath';
import {
  withValidSubdomain,
  WithValidSubdomainProps,
} from './withValidSubdomain';

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 0;
  height: 50%;
`;

export interface RedirectToSearchProps extends WithValidSubdomainProps {
  subdomain: string;
}

export interface RedirectToSearchComponentProps
  extends RedirectToSearchProps,
    Pick<RouteComponentProps, 'location'> {}

/* eslint-disable complexity */
export const RedirectToSearchComponent = ({
  parentSubdomain,
  subdomain,
  ...props
}: RedirectToSearchComponentProps) => {
  const domain = process.env.REACT_APP_DOMAIN;
  const initialRoute = RoutePath.StartSplitScreen;
  const { data, loading } = useQuery(AUTHENTICATED_QUERY);
  const subdomainlessRoutesAuthRoutesEnabled = useFeatureFlag(
    FEATURE_FLAGS.SUBDOMAINLESS_AUTH_ROUTES_ENABLED,
  );

  // using the withLoadingIndicator will mean we continue on to the redirect,
  // but we don't want to do that until the subdomain has been validated
  if (props.loading) {
    return <StyledLoadingIndicator />;
  }

  if (isMSTeams()) {
    const hasResolvedDeepLink =
      localStorage.getItem(RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY) === 'true';
    // If MS Teams SSO or login, don't re-direct - render their relevant components
    if (
      location.pathname === RoutePath.SSOLoginHome ||
      location.pathname === RoutePath.SSOLoginSuccess ||
      location.pathname === RoutePath.LoginWithSSO ||
      location.pathname === RoutePath.LoginWithCredentials ||
      location.pathname === RoutePath.AdditionalInformationNeeded ||
      location.pathname === RoutePath.ExplicitPrivacyConsentNeeded ||
      location.pathname === RoutePath.MSTeamsSSO ||
      location.pathname === RoutePath.MSTeamsSSOCallback ||
      location.pathname === RoutePath.SignInToYourOrganisation ||
      location.pathname === RoutePath.ExternalPlayer ||
      location.pathname === RoutePath.StartSplitScreen ||
      location.pathname === RoutePath.Login ||
      location.pathname === RoutePath.SignUp ||
      location.pathname === `${RoutePath.SignUp}/details` ||
      location.pathname === RoutePath.SignUpWorkDetails ||
      location.pathname === `${RoutePath.SignUp}/privacy-consent` ||
      location.pathname === `${RoutePath.SignUp}/set-password` ||
      location.pathname === `${RoutePath.SignUp}/confirmation` ||
      hasResolvedDeepLink
    ) {
      return null;
    }

    // If in MS Teams & you have a valid subdomain set, proceed to the home page or redirect page
    // if Unmind was accessed through opening a link to a piece of content
    const device = getMSTeamsDevice();
    const isMobile =
      device !== 'unknown' &&
      [PlatformType.IOS, PlatformType.Android].includes(device);
    const isPopUpWindow = window.self !== window.parent; // TODO: determine if this is an auth initiated popup window rather than any Teams popup window
    if (subdomain && props.isValidSubdomain && (isPopUpWindow || isMobile)) {
      if (data && !loading) {
        const redirectUrl = resolveMSTeamsDeepLink({
          userIsAuthenticated: data.authenticated,
        });

        if (redirectUrl) {
          localStorage.setItem(RESOLVED_DEEP_LINK_LOCAL_STORAGE_KEY, 'true');

          return <Redirect to={redirectUrl} />;
        } else {
          const redirect = `${location.pathname}?${new URLSearchParams(
            location.search,
          ).toString()}`;

          // If there is no deep-link, this means that the link provided is likely a Content recommendation
          // from the AI coach - therefore proceed to that link and don't re-direct home!
          return <Redirect to={redirect} />;
        }
      }

      return <Redirect to={RoutePath.Home} />;
    }

    // If using ngrok locally, not a valid sub-domain and not the Auth0 pop up, re-direct to Search
    if (
      !props.isValidSubdomain &&
      location.hostname.includes('ngrok') &&
      window.self !== window.parent
    ) {
      return <Redirect to={initialRoute} />;
    }
  }

  const clientSubdomain = isClientSubdomain(subdomain);

  const disabledRedirectionWhitelist = [
    RoutePath.SignInToYourOrganisation,
    RoutePath.FindYourOrganisation,
    RoutePath.FindYourOrganisationConfirmation,
    RoutePath.StartSplitScreen,
    RoutePath.AuthMethod,
    RoutePath.ExternalPlayer,
  ];

  const disabledSubdomainlessRoutesWhitelist = [
    RoutePath.MagicLogin,
    RoutePath.MagicLoginConfirmation,
    RoutePath.ForgotPassword,
    RoutePath.ForgotPasswordEmailConfirmation,
    RoutePath.LoginWithCredentials,
  ];

  const combinedWhitelist = subdomainlessRoutesAuthRoutesEnabled
    ? [...disabledRedirectionWhitelist, ...disabledSubdomainlessRoutesWhitelist]
    : disabledRedirectionWhitelist;

  if (
    props.isValidSubdomain &&
    props.location.pathname === RoutePath.SignInToYourOrganisation
  ) {
    return <Redirect to={RoutePath.Login} />;
  }

  if (!clientSubdomain) {
    for (const path of combinedWhitelist) {
      if (path === props.location.pathname) {
        return null;
      }
    }

    return <Redirect to={initialRoute} />;
  }

  if (Boolean(parentSubdomain)) {
    const loginUrl = `http://${parentSubdomain}.${domain}${RoutePath.Login}`;
    window.location.replace(loginUrl);

    return <StyledLoadingIndicator />;
  }

  if (props.error !== undefined) {
    throw Error(
      `There was an error trying to validate the subdomain ${subdomain}: ${props.error}`,
    );
  }

  if (!props.isValidSubdomain) {
    window.location.replace(`http://search.${domain}${initialRoute}`);

    // This prevents the sign in page loading before the window redirects
    return <StyledLoadingIndicator />;
  }

  return null;
};

export default compose<RedirectToSearchComponentProps, Record<string, never>>(
  withSubdomainFromUrl,
  withValidSubdomain,
  withRouter,
)(RedirectToSearchComponent);
