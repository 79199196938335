import { rem } from 'polished';

import { css, styled } from 'styles';

export enum ProgressSize {
  regular,
  large,
}

export interface ProgressBarProps {
  progress: number;
  color?: string;
  size?: ProgressSize;
  rounded?: boolean;
  backgroundColor?: string;
  borderColor?: string;
}

export const ProgressBar = styled.div.attrs(
  ({ progress }: ProgressBarProps) => ({
    role: 'progressbar',
    'aria-valuenow': Math.round(progress),
    'aria-valuemin': 0,
    'aria-valuemax': 100,
  }),
)<ProgressBarProps>`
  width: 100%;
  height: ${({ size }) => (size === ProgressSize.large ? rem(10) : rem(6))};
  position: relative;
  display: ${({ progress }) =>
    progress >= 0 && progress <= 100 ? 'block' : 'none'};
  ${({ rounded = true }) =>
    rounded &&
    css`
      border-radius: ${rem(25)};
    `};

  margin-bottom: ${rem(15)};

  &::before,
  &::after {
    border-radius: inherit;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    border: solid ${rem(1)} rgba(13, 3, 0, 0.36)};
  }

  &::before {
    width: 100%;
    background: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : 'transparent'};
    z-index: 1;
  }

  &::after {
    width: ${({ progress }) => `${progress}%`};
    background: ${({ theme, color = theme.colors.border.success }) => color};
    border-color: ${({ theme, color = theme.colors.border.primary }) => color};

    ${({ progress }) => {
      if (progress === 0) {
        return `
          display: none;
        `;
      }

      if (progress < 100) {
        return `
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        `;
      }
    }}
  }
`;

ProgressBar.defaultProps = {
  size: ProgressSize.regular,
};
