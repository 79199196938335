import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../../LoggedOutScreenWrapper';
import { UserDetails } from '../SignUp';
import { tracking } from '../../../App/Tracking';
import i18n from '../../../i18n/config';
import { SignUpWithSSOButton } from '../SignUpWithSSOButton';
import EmailInputForm from './EmailInputForm';

export const ResponseMessageTypes = {
  'validate-workplace-email-not-recognised': i18n.t(
    'logged_out:sign_up.forms.work_email.errors.unrecognised_email',
  ),
  fallback: i18n.t('shared:errors.messages.failed'),
} as const;

interface WorkEmailFormProps {
  onSubmit(details: UserDetails): void;
  subdomain: string;
  isInitialValid?: boolean;
  initialValues?: { emailInput: string };
  shouldShowSSOButton?: boolean;
  ssoProviderName?: string | null;
}

interface FormFields {
  emailInput: string;
}

export const VALIDATE_EMAIL_QUERY = gql`
  query validateWorkplaceEmail($input: ValidateWorkplaceEmailInput!) {
    validateWorkplaceEmail(input: $input) {
      ... on ValidateWorkplaceEmailSuccess {
        isValid
      }
      ... on ValidateWorkplaceEmailNotFound {
        isValid
        message
      }
    }
  }
`;

const EnterWorkEmailForm = ({
  initialValues = { emailInput: '' },
  isInitialValid = false,
  shouldShowSSOButton = false,
  ssoProviderName,
  ...props
}: WorkEmailFormProps) => {
  const formikRef = useRef<Formik<FormFields>>(null);
  const { t: translate } = useTranslation('logged_out');
  const [error, setError] = useState('');

  const [validateEmailDomain, { loading }] = useLazyQuery(
    VALIDATE_EMAIL_QUERY,
    {
      onCompleted: data => {
        const {
          validateWorkplaceEmail: { isValid, message },
        } = data;

        if (isValid) {
          handleValidateEmailSuccess();
        } else {
          handleValidateEmailFailure(message);
        }
      },
      onError: () => {
        setError(ResponseMessageTypes.fallback);
      },
    },
  );

  const handleValidateEmailFailure = (
    message: keyof typeof ResponseMessageTypes,
  ) => {
    const errorMessage = ResponseMessageTypes[message]
      ? ResponseMessageTypes[message]
      : translate('sign_up.forms.work_email.errors.unrecognised_email');

    setError(errorMessage);

    tracking.track('employee-authentication', {
      subdomain: props.subdomain,
      verificationType: 'work-email',
      verificationValid: false,
    });
  };

  const handleValidateEmailSuccess = () => {
    setError('');

    tracking.track('employee-authentication', {
      subdomain: props.subdomain,
      verificationType: 'work-email',
      verificationValid: true,
    });

    props.onSubmit({
      email: formikRef?.current?.state.values.emailInput,
    });
  };

  const onSubmit = (values: FormFields) => {
    validateEmailDomain({
      variables: {
        input: {
          email: values.emailInput,
          subdomain: props.subdomain,
        },
      },
    });
  };

  return (
    <LoggedOutScreenWrapper dataTestId="signup-email-form">
      <LoggedOutScreenTitle data-testid="form-title">
        {translate('sign_up.forms.work_email.heading')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="form-subtitle">
        {translate('sign_up.forms.work_email.subtitle')}
      </LoggedOutScreenSubtitle>
      <EmailInputForm
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={onSubmit}
        setError={setError}
        error={error}
        loading={loading}
        formikRef={formikRef}
      />
      {shouldShowSSOButton && (
        <SignUpWithSSOButton ssoProviderName={ssoProviderName} />
      )}
    </LoggedOutScreenWrapper>
  );
};

export default EnterWorkEmailForm;
