import React from 'react';
import { tracking } from 'App/Tracking';
import { Events } from 'App/Tracking/Events';
import { Namespace } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getPrivacyPolicyLink, getTermsOfUseLink } from 'utils';
import { ExternalLink } from '@unmind/design-system-components-web';
import { isMSTeams } from 'utils/MSTeams';
import { IconSize } from '@unmind/design-system-components-web/dist/icons/sizes';

const TsAndCsWrapper = styled.div`
  text-align: center;
`;

const trackTerms = (
  subdomain: string | undefined,
  trackingEvent: Events | undefined,
) => {
  const trackingEventName = trackingEvent
    ? trackingEvent
    : 'terms-conditions-clicked';

  tracking.track(trackingEventName, { subdomain });
};

const trackPrivacyPolicy = (subdomain: string | undefined) => {
  tracking.track('privacy-policy-clicked', { subdomain });
};

interface TsAndCsStatementProps {
  subdomain?: string;
  termsTrackingEvent?: Events;
  className?: string;
}

export const TsAndCsStatement = ({
  subdomain,
  termsTrackingEvent,
  className,
}: TsAndCsStatementProps) => {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  return (
    <TsAndCsWrapper className={className}>
      <Trans
        t={translate}
        i18nKey="sign_up.ts_and_cs_statement"
        defaults="By continuing, I agree to Unmind’s <bold_link_terms>Terms & Conditions</bold_link_terms> and <bold_link_policy>Privacy Policy</bold_link_policy>."
        components={{
          bold_link_terms: (
            <ExternalLink
              icon={isMSTeams() ? IconSize.Medium : false}
              onClick={() => trackTerms(subdomain, termsTrackingEvent)}
              href={getTermsOfUseLink(subdomain)}
              target="_blank"
              data-testid="terms-conditions-link"
            >
              Terms & Conditions
            </ExternalLink>
          ),
          bold_link_policy: (
            <ExternalLink
              icon={isMSTeams() ? IconSize.Medium : false}
              onClick={() => trackPrivacyPolicy(subdomain)}
              href={getPrivacyPolicyLink(subdomain)}
              target="_blank"
              data-testid="privacy-policy-link"
            >
              Privacy Policy
            </ExternalLink>
          ),
        }}
      />
    </TsAndCsWrapper>
  );
};
