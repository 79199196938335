import * as amplitude from '@amplitude/analytics-browser';

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

const initialiseAmplitude = () => {
  try {
    amplitude.init(AMPLITUDE_API_KEY, {
      autocapture: {
        attribution: false,
        pageViews: true,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
        elementInteractions: false,
      },
    });
  } catch (e) {
    console.error('Failed to initialise Amplitude SDK', e);
  }
};

export default initialiseAmplitude;
