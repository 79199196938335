import React from 'react';
import { Checkbox, Fieldset } from '@unmind/design-system-components-web';
import styled from 'styled-components';
import { Field } from 'formik';
import { rem } from 'polished';

const FieldsetChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > label {
    margin-top: ${rem(16)};
    font-size: ${rem(16)};
  }
`;

interface CheckboxProps {
  label: string;
  name: string;
}

export const MultipleCheckboxes = ({
  checkboxes,
  ariaLabelledby,
}: {
  checkboxes: CheckboxProps[];
  ariaLabelledby?: string;
}) => (
  <Fieldset aria-labelledby={ariaLabelledby}>
    <FieldsetChildrenWrapper>
      {checkboxes.map(({ label, name }) => (
        <Field key={name} name={name}>
          {({ field }: any) => (
            <Checkbox
              {...field}
              labelText={label}
              name={name}
              id={name}
              checked={field.value}
            />
          )}
        </Field>
      ))}
    </FieldsetChildrenWrapper>
  </Fieldset>
);
