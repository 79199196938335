import { BodyText, Button } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import { rem } from 'polished';
import { styled, css } from 'styles';
import { small } from 'utils';

export const CTAWrapper = styled.div`
  max-width: ${rem(412)};
  margin: ${rem(64)} auto ${rem(32)};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.card};
  padding: ${rem(24)};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => rem(theme.layout.borderRadii.radius16)};

  ${small(css`
    margin: ${rem(32)} auto;
  `)};
`;

export const CTAHeroImage = styled.img.attrs({
  alt: '',
})`
  width: 220px;
  height: auto;
  margin-bottom: 24px;
`;

export const CTAHeaderText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize20],
  color: theme.colors.text.primary,
  weight: theme.typography.fontWeights.semiBold,
  align: 'center',
}))`
  margin-bottom: 8px;
`;

export const CTABodyText = styled(BodyText).attrs(({ theme }) => ({
  sizes: [FontSizes.fontSize18],
  color: theme.colors.text.primary,
  align: 'center',
}))``;

export const CTAActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
  width: 100%;
`;

export const CTAPrimaryActionButton = styled(Button)`
  flex-grow: 2;
`;

export const CTASecondaryActionButton = styled(Button)`
  flex-grow: 1;
`;
