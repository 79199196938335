import { Breakpoints } from '@unmind/design-system-theme';
import { LocationDescriptorObject } from 'history';
import React, { useMemo } from 'react';
import { CustomTypeOptions, useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, mapProps } from 'recompose';
import { css, styled } from 'styles';
import { isMSTeams } from 'utils/MSTeams';
import { logException } from '../App/logging';
import {
  ServersideTrackingEvent,
  withLogEvent,
  WithLogEventProps,
} from '../App/Tracking/serverside';
import useFeatureFlag, { FEATURE_FLAGS } from '../flags/useFeatureFlag';
import { useOrganisationEntitlements } from '../Shared/Entitlement';
import Loader from '../Shared/Loader';
import { PageTitle } from '../Shared/PageTitle';
import Tabs from '../Shared/Tabs';
import { extraSmall, isEmployee } from '../utils';
import useViewportWidth from '../utils/useViewportWidth';
import { InviteColleague } from './InviteColleague/InviteColleague';
import { InviteALovedOne } from './InviteALovedOne/InviteALovedOne';
import Profile from './Profile/Profile';
import Security from './Security';
import SelectLanguagePage from './SelectLanguage/SelectLanguagePage';
import withUpdateUser, { WithUpdateUserProps } from './withUpdateUser';
import { withUserType, WithUserTypeProps } from './withUserType';
import { IntegrationsTab } from './Integrations';
import { NotificationsPreferenceCentreTab } from './NotificationsPreferenceCentre';
import { useOrganisationTeamsTenantIds } from './NotificationsPreferenceCentre/hooks/useOrganisationTeamsTenantIds';

const Container = styled.div`
  display: block;
`;

const TitleContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;

  ${extraSmall(css`
    margin-top: 40px;
    margin-bottom: 24px;
  `)}
`;

type AccountTabTranslationOptions =
  CustomTypeOptions['resources']['account']['tabs'];

type ValidTranslationKey = keyof AccountTabTranslationOptions;

interface TabsPropsPreTranslation {
  title: ValidTranslationKey;
  hash: string;
  component: React.ReactNode;
}

interface AccountProps
  extends WithUserTypeProps,
    WithLogEventProps,
    WithUpdateUserProps {
  activeTab: string;
  updateHistory(location: LocationDescriptorObject): void;
  confirmEmailUpdateSuccess: boolean;
  confirmEmailUpdateError: boolean;
}

export const Account: React.FC<AccountProps> = ({
  activeTab = '#profile',
  updateHistory,
  confirmEmailUpdateSuccess,
  confirmEmailUpdateError,
  userType,
  userTypeLoading,
  logEvent,
}) => {
  const { t: translate } = useTranslation('account');
  const trackServerside = async (
    eventName: ServersideTrackingEvent,
    eventProperties?: unknown,
  ) => {
    try {
      await logEvent({
        variables: {
          input: {
            eventName,
            eventProperties,
          },
        },
      });
    } catch (error) {
      logException(error);
    }
  };

  const defaultTabs: TabsPropsPreTranslation[] = [
    {
      title: 'profile',
      hash: '#profile',
      component: (
        <Container>
          <Profile
            confirmEmailUpdateSuccess={confirmEmailUpdateSuccess}
            confirmEmailUpdateError={confirmEmailUpdateError}
          />
        </Container>
      ),
    },
    {
      title: 'security',
      hash: '#security',
      component: (
        <Container>
          <Security trackServerside={trackServerside} />
        </Container>
      ),
    },
    {
      title: 'language',
      hash: '#select-language',
      component: <SelectLanguagePage />,
    },
  ];

  // Some subdomains are not permitted to access features hidden from invited users (this is mostly used for research purposes)
  const hasAccessToFeaturesRestrictedFromInvitedUsers =
    useFeatureFlag('plus-one');

  const emailPreferenceCentreIsEnabled = useFeatureFlag(
    FEATURE_FLAGS.SHOW_EMAIL_NOTIFICATIONS,
  );

  const showNotificationsPreferenceCentreFlag = useFeatureFlag(
    'workplace-engagement-show-preference-centre',
  );

  const { entitlements, loading: isOrganisationEntitlementsLoading } =
    useOrganisationEntitlements(
      [{ entitlement: 'standaloneIndex', default: true }],
      !hasAccessToFeaturesRestrictedFromInvitedUsers,
    );

  const { data, loading: isOrgTenantIdsLoading } =
    useOrganisationTeamsTenantIds();

  const viewportWidth = useViewportWidth();
  const centerTabs = viewportWidth < Breakpoints.M;
  const tabs = [];

  const defaultTabsWithNavigation = useMemo(
    () =>
      defaultTabs.map(tab => ({
        ...tab,
        title: translate(`tabs.${tab.title}`).toString(),
      })),
    [translate],
  );

  tabs.push(...defaultTabsWithNavigation);

  const inviteALovedOneTab = {
    title: translate('tabs.invite_a_loved_one'),
    hash: '#invite-a-loved-one',
    redirect: ['#plus-one', '#invite-friend'],
    testId: 'account-tabs-invite-a-loved-one',
    component: <InviteALovedOne />,
  };

  const inviteColleagueTab = {
    title: translate('tabs.invite_colleague'),
    hash: '#invite-colleague',
    component: <InviteColleague />,
  };

  const integrationsTab = {
    title: translate('tabs.integrations'),
    hash: '#integrations',
    component: <IntegrationsTab />,
  };

  const notificationPreferencesTab = {
    title: translate('tabs.notification_preferences'),
    hash: '#notifications-preferences',
    component: <NotificationsPreferenceCentreTab />,
  };

  const organisationHasTenantMappings =
    data?.organisation?.msTenantIds &&
    data?.organisation?.msTenantIds?.length > 0;

  // This variable is temporary eventually everyone will see the
  // preference center with email options and those with teams access will in addition to this see teams
  const showNotificationsPreferenceCentre =
    (showNotificationsPreferenceCentreFlag &&
      (organisationHasTenantMappings || isMSTeams())) ||
    emailPreferenceCentreIsEnabled;

  const showAdditionalTabs =
    !entitlements.standaloneIndex &&
    hasAccessToFeaturesRestrictedFromInvitedUsers &&
    isEmployee(userType);

  // We cannot show Invite a Loved One in MS Teams as it re-directs users to the App Store (as it is only available on mobile) - Microsoft don't allow this
  // We also can't show the integrations tab as it advertises upcoming features and MSTeams don't allow this either.
  if (showAdditionalTabs) {
    if (isMSTeams()) {
      tabs.push(inviteColleagueTab);
    }
    if (!isMSTeams()) {
      tabs.push(...[inviteALovedOneTab, inviteColleagueTab, integrationsTab]);
    }
  }

  if (showNotificationsPreferenceCentre) {
    tabs.push(notificationPreferencesTab);
  }

  const loading =
    isOrganisationEntitlementsLoading ||
    userTypeLoading ||
    isOrgTenantIdsLoading;

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <TitleContainer>
        <PageTitle>{translate('title')}</PageTitle>
      </TitleContainer>
      <Tabs
        activeTab={activeTab}
        tabs={tabs}
        updateHistory={updateHistory}
        data-testid="account-tabs"
        align={centerTabs ? 'center' : 'left'}
      >
        {tabs.map(tab => tab.component)}
      </Tabs>
    </>
  );
};

export default compose<AccountProps, AccountProps>(
  withUserType,
  withRouter,
  withUpdateUser,
  withLogEvent,
  mapProps((props: RouteComponentProps) => ({
    ...props,
    activeTab: props.location.hash,
    updateHistory: props.history.push,
    confirmEmailUpdateSuccess:
      props.location.state && props.location.state.confirmEmailUpdateSuccess,
    confirmEmailUpdateError:
      props.location.state && props.location.state.confirmEmailUpdateError,
  })),
)(Account);
