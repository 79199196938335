import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { useAssignFocus } from '../../Shared/Accessibility';
import {
  LoggedOutScreenWrapper,
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
} from '../LoggedOutScreenWrapper';

function VerifyAccountOwnershipEmailConfirmation() {
  const headerRef = useRef<HTMLHeadingElement>(null);
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  useAssignFocus(headerRef);

  return (
    <LoggedOutScreenWrapper>
      <LoggedOutScreenTitle ref={headerRef} tabIndex={-1}>
        {translate(
          'sign_up.verify_account_ownership_email_confirmation.heading',
        )}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="email-confirmation-subtitle">
        {Trans({
          t: translate,
          i18nKey:
            'sign_up.verify_account_ownership_email_confirmation.subtitle',
          defaults: 'We’ve sent a verification email to your email',
        })}
      </LoggedOutScreenSubtitle>
    </LoggedOutScreenWrapper>
  );
}

export default VerifyAccountOwnershipEmailConfirmation;
