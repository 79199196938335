import React from 'react';
import { IconProps } from '../IconProps';

const SvgNavCompanion = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <g
      clipPath="url(#NavCompanion_svg__clip0_354_2381)"
      stroke={primaryColor}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1.748 5.358c-.407-.071-.407-.655 0-.726a3.683 3.683 0 0 0 2.966-2.84l.025-.113c.088-.402.66-.404.752-.003l.03.13A3.701 3.701 0 0 0 8.495 4.63c.409.071.409.659 0 .73a3.701 3.701 0 0 0-2.974 2.823l-.03.13c-.092.402-.664.4-.752-.002l-.025-.113a3.683 3.683 0 0 0-2.966-2.84zM6.429 13.026c-.7-.122-.7-1.126 0-1.248a6.332 6.332 0 0 0 5.1-4.884l.042-.193c.152-.691 1.136-.696 1.293-.006l.052.225a6.365 6.365 0 0 0 5.114 4.855c.703.122.703 1.132 0 1.254a6.365 6.365 0 0 0-5.114 4.855l-.052.224c-.157.69-1.142.686-1.293-.005l-.042-.193a6.332 6.332 0 0 0-5.1-4.884z" />
    </g>
    <defs>
      <clipPath id="NavCompanion_svg__clip0_354_2381">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgNavCompanion;
