import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { StyledLink } from 'LoggedOut/LoggedOutScreenWrapper/LoggedOutScreenWrapper.styled';
import { EmailAddress } from '../EmailConfirmation/EmailConfirmation.styled';
import {
  LoggedOutScreenWrapperFooter,
  LoggedOutScreenSubtitle,
  LoggedOutScreenTitle,
  LoggedOutScreenWrapper,
} from '../LoggedOutScreenWrapper';
import {
  EmailConfirmationFooter,
  EmailConfirmationHelperText,
} from '../EmailConfirmation/EmailConfirmationShared';

interface Props {
  email?: string;
  redirectTo: string;
}

function SignUpEmailConfirmation({ email, redirectTo }: Props) {
  const { t: translate } =
    useTranslation<Namespace<'logged_out'>>('logged_out');

  return (
    <LoggedOutScreenWrapper dataTestId="v2-email-confirmation-screen">
      <LoggedOutScreenTitle>
        {translate('sign_up.sign_up_email_confirmation.heading')}
      </LoggedOutScreenTitle>
      <LoggedOutScreenSubtitle data-testid="email-confirmation-subtitle">
        {Trans({
          t: translate,
          i18nKey: 'sign_up.sign_up_email_confirmation.subtitle',
          values: { email },
          defaults:
            'We’ve sent a verification email to <email_address>{{email}}</email_address>',
          components: { email_address: <EmailAddress /> },
        })}
      </LoggedOutScreenSubtitle>
      <EmailConfirmationHelperText>
        {Trans({
          t: translate,
          i18nKey: 'sign_up.sign_up_email_confirmation.helper_text',
          values: { email },
          defaults:
            'Wrong email? <confirmation_link>Try again</confirmation_link>',
          components: {
            confirmation_link: (
              <StyledLink to={redirectTo}>Try again</StyledLink>
            ),
          },
        })}
      </EmailConfirmationHelperText>
      <LoggedOutScreenWrapperFooter>
        <EmailConfirmationFooter userEmailAddress={email} />
      </LoggedOutScreenWrapperFooter>
    </LoggedOutScreenWrapper>
  );
}

export default SignUpEmailConfirmation;
