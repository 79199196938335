import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import {
  ShowEEAConsentCheckbox,
  ShowEEAConsentCheckboxVariables,
} from './__generated__/ShowEEAConsentCheckbox';

export const USE_EEA_CONSENT_CHECKBOX_QUERY = gql`
  query ShowEEAConsentCheckbox($subDomain: String!) {
    showPrivacyConsentStepOnSignup(subDomain: $subDomain) {
      showEEAExplicitDataStorageConsent
    }
  }
`;

interface useEEAConsentCheckboxOnSignUpOutput {
  showEEAExplicitDataStorageConsent?: boolean;
  loading: boolean;
}

export const useEEAConsentCheckboxOnSignUp = (
  subDomain: string,
): useEEAConsentCheckboxOnSignUpOutput => {
  const { data, loading } = useQuery<
    ShowEEAConsentCheckbox,
    ShowEEAConsentCheckboxVariables
  >(USE_EEA_CONSENT_CHECKBOX_QUERY, { variables: { subDomain } });

  const showEEAExplicitDataStorageConsent =
    data?.showPrivacyConsentStepOnSignup?.showEEAExplicitDataStorageConsent ??
    true;

  return {
    showEEAExplicitDataStorageConsent: loading
      ? undefined
      : showEEAExplicitDataStorageConsent,
    loading,
  };
};
