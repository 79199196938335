import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from 'Shared/Container';
import StaticContentGallery from 'StaticContent/StaticContentGallery';
import { css, styled } from 'styles';
import { small } from 'utils';
import SectionError from 'Shared/SectionError';
import { FullPageGeneralError } from 'Shared/AlertView/FullPageGeneralError';
import SkeletonStaticContentGallery from 'StaticContent/SkeletonStaticContentGallery';
import { Chevron } from 'icons';
import { BodyText } from '@unmind/design-system-components-web';
import RoutePath from 'App/RoutePath';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { tracking } from 'App/Tracking';
import { ContentType } from 'Explore/types';
import { CHEAT_SHEET_ALBUM_QUERY } from 'Services/CheatSheets/cheatsheet.services';
import {
  cheatSheetAlbumBySlug,
  cheatSheetAlbumBySlugVariables,
} from 'Services/CheatSheets/__generated__/cheatSheetAlbumBySlug';
import { ResponsiveContainer } from 'Shared/Layout';
import useFeatureFlag, { FEATURE_FLAGS } from 'flags/useFeatureFlag';
import RecommendedCheatSheets from './Recommended/RecommendedCheatSheets';

const Wrapper = styled.div`
  padding-top: 26px;
  padding-bottom: 26px;

  ${small(css`
    padding-top: 40px;
    padding-bottom: 40px;
  `)}
`;

const LoadingContainer = styled(Container)`
  padding-top: 26px;
  padding-bottom: 26px;

  ${small(css`
    padding-top: 40px;
    padding-bottom: 40px;
  `)}
`;

const ErrorWrapper = styled.div`
  margin-top: 56px;
`;

const StyledLink = styled(BodyText).attrs(({ theme }) => ({
  sizes: [theme.typography.fontSizes.fontSize14],
  weight: theme.typography.fontWeights.regular,
  color: theme.colors.text.link,
  forwardedAs: 'a',
}))`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: max-content;
`;

const BackArrow = styled(Chevron).attrs(({ theme }) => ({
  primaryColor: theme.colors.text.link,
  width: 10,
  height: 10,
}))`
  margin-right: 6px;
  transform: rotate(180deg);
`;

function CheatSheetPage() {
  const history = useHistory();
  const { t: translate } = useTranslation('cheat_sheets');
  const { slug: cheatSheetAlbumSlug } = useParams<{
    slug: string;
  }>();
  const shouldDisplayManagerCheatSheets = useFeatureFlag(
    FEATURE_FLAGS.SHOW_MANAGER_CHEAT_SHEET_WEB,
  );

  const { data, loading, error, refetch } = useQuery<
    cheatSheetAlbumBySlug,
    cheatSheetAlbumBySlugVariables
  >(CHEAT_SHEET_ALBUM_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    returnPartialData: true,
    variables: {
      slug: cheatSheetAlbumSlug,
    },
  });

  const cheatSheetAlbum = data?.cheatSheetAlbum;

  const onItemChange = ({
    contentId,
    currentPosition,
    remainingItems,
  }: {
    contentId: string;
    currentPosition: number;
    remainingItems: number;
  }) => {
    tracking.track('content-viewed', {
      contentId: contentId,
      contentSlug: cheatSheetAlbumSlug,
      position: currentPosition,
      remaining: remainingItems,
      contentType: ContentType.cheatSheet,
      source: 'cheat-sheet-gallery',
    });
  };

  if (error || !shouldDisplayManagerCheatSheets) {
    return (
      <ErrorWrapper>
        <SectionError
          onRetry={async () => refetch({ slug: cheatSheetAlbumSlug })}
        />
      </ErrorWrapper>
    );
  }

  if (loading) {
    return (
      <LoadingContainer>
        <SkeletonStaticContentGallery />
      </LoadingContainer>
    );
  }

  if (!cheatSheetAlbum) {
    return <FullPageGeneralError />;
  }

  return (
    <Wrapper>
      <StaticContentGallery
        content={cheatSheetAlbum}
        assetToken={data?.getAssetToken?.assetToken}
        onItemChange={onItemChange}
        leftMenuOption={
          <StyledLink onClick={() => history.push(RoutePath.Home)} role="link">
            <BackArrow aria-hidden="true" />
            {translate('back_button.label')}
          </StyledLink>
        }
      />
      <ResponsiveContainer>
        <RecommendedCheatSheets
          assetToken={data?.getAssetToken?.assetToken}
          currentCheatSheetSlug={data?.cheatSheetAlbum?.slug}
        />
      </ResponsiveContainer>
    </Wrapper>
  );
}

export default CheatSheetPage;
