import React from 'react';
import { css, styled } from 'styles';
import { rem } from 'polished';
import { RegistrationMethod } from '__generated__/globalTypes';
import LoadingIndicator from 'Shared/LoadingIndicator';
import { medium, extraSmall } from '../../utils';
import {
  ManageJoiners,
  ManageLeavers,
  ManageLineManagers,
} from '../ManageUsers';
import { useLineManagerEntitlements } from './useLineManagerEntitlements';
import { useOrganisationRegistrationMethod } from './useOrganisationRegistrationMethod';

const Layout = styled.ul`
  display: flex;
  flex-flow: column wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  ${medium(css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  `)}
`;

const FormContainer = styled.li`
  background-color: ${({ theme }) => theme.colors.background.card};
  border-radius: 4px;
  padding: ${rem(24)};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  margin-top: ${rem(16)};
  ${extraSmall(css`
    margin-top: ${rem(24)};
  `)}
  ${medium(css`
    margin-top: 0;
    width: 100%;
    height: 100%;
  `)}
`;

const ManageUsers = () => {
  const {
    data: lineManagerEntitlementsData,
    loading: isLoadingLineManagerEntitlements,
  } = useLineManagerEntitlements();
  const {
    data: orgRegistrationMethodData,
    loading: isLoadingOrgRegistrationMethod,
  } = useOrganisationRegistrationMethod();

  if (isLoadingLineManagerEntitlements || isLoadingOrgRegistrationMethod) {
    return <LoadingIndicator />;
  }

  const showUserUpload =
    orgRegistrationMethodData?.organisation.registrationMethod ===
    RegistrationMethod.CLOSED;

  const showLineManagerUpload =
    lineManagerEntitlementsData?.organisationEntitlements?.lineManagerFeatures;

  return (
    <Layout>
      {showUserUpload ? (
        <FormContainer>
          <ManageJoiners />
        </FormContainer>
      ) : null}

      <FormContainer>
        <ManageLeavers />
      </FormContainer>

      {showLineManagerUpload ? (
        <FormContainer>
          <ManageLineManagers />
        </FormContainer>
      ) : null}
    </Layout>
  );
};

export default ManageUsers;
