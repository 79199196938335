import React from 'react';
import { IconProps } from '../IconProps';
const SvgNavHome = ({
  primaryColor,
  secondaryColor,
  tertiaryColor,
  ...props
}: IconProps) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.94 1.363a.09.09 0 01.12 0l.422-.461-.422.46 8.108 7.433c.314.288.492.694.492 1.12v7.228c0 .838-.68 1.518-1.518 1.518h-5.089v-4.375a2.054 2.054 0 00-4.107 0v4.375h-5.09c-.838 0-1.517-.68-1.517-1.518V9.914c0-.425.178-.831.492-1.119L9.94 1.363z"
      stroke={primaryColor}
      strokeWidth={1.25}
    />
  </svg>
);
export default SvgNavHome;
