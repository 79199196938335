import { TFunction } from 'i18next';
import {
  useSubDomainInfo_subDomain_locations,
  useSubDomainInfo_subDomain_departments,
} from './__generated__/useSubDomainInfo';

type FormValues = {
  location: string;
  department: string;
};

type NonNullLocations = useSubDomainInfo_subDomain_locations[];
type NonNullDepartments = useSubDomainInfo_subDomain_departments[];

export const workDetailsFormValidation = ({
  location,
  department,
  translate,
  filteredDepartments,
  filteredLocations,
}: FormValues & {
  translate: TFunction<'logged_out'>;
  filteredDepartments: NonNullDepartments;
  filteredLocations: NonNullLocations;
}) => {
  const errors: {
    department?: string;
    location?: string;
  } = {};
  if (filteredDepartments.length > 0 && !department) {
    errors.department = translate(
      'sign_up.forms.work_details.errors.department_required',
    );
  }

  if (filteredLocations.length > 0 && !location) {
    errors.location = translate(
      'sign_up.forms.work_details.errors.location_required',
    );
  }

  return errors;
};
