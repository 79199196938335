import { small } from '@unmind/design-system-components-web';
import { FontSizes } from '@unmind/design-system-theme';
import React from 'react';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import Modal from 'Shared/Modal';
import { ThemeInterface } from 'styles';

const StyledModal = styled(Modal)`
  max-width: unset;
  padding: ${rem(28)};

  p {
    margin: 0;
    font-size: ${rem(FontSizes.fontSize20)};
    font-weight: ${({ theme }: { theme: ThemeInterface }) =>
      theme.typography.fontWeights.semiBold};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin: ${rem(24)} 0 0 0;
    font-size: ${rem(FontSizes.fontSize18)};

    > span {
      display: block;
    }

    a {
      width: 100%;
      min-width: unset;
      margin: ${rem(16)} 0 0 0;
      justify-content: center;

      span {
        font-size: inherit;
      }
    }
  }

  @media (min-width: ${rem(371)}) {
    min-width: ${rem(342)};
  }

  ${small(css`
    li {
      display: flex;
      flex-flow: row;
      align-items: center;

      > span {
        margin-right: ${rem(24)};
      }

      a {
        width: fit-content;
        margin: auto 0 auto auto;
        justify-content: start;
      }
    }
  `)}
`;

interface ContactModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}

const ContactModal = ({
  showModal,
  setShowModal,
  children,
}: ContactModalProps) => (
  <StyledModal
    open={showModal}
    onCloseCallback={() => setShowModal(false)}
    closeHeader={true}
    size="medium"
    modalContent={() => children}
  />
);

export default ContactModal;
